import { Home } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import ChatIcon from "@mui/icons-material/Chat";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FeedbackIcon from "@mui/icons-material/Feedback";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { blue, green } from "@mui/material/colors";
import { createTheme, styled } from "@mui/material/styles";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMatters } from "../../../context/MattersProvider";
import authService from "../../../services/auth.service";
import AccountMenu from "./AccountMenu";
import Logo from "./Logo";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const menuItems = [
  { name: "Dashboard", route: "file", icon: <Home /> },
  // {
  //   name: "Create Matter",
  //   route: "company",
  //   icon: <PostAddIcon />,
  //   role: "admin",
  // },
  {
    name: "File cabinet",
    route: "folder",
    icon: <ArticleIcon />,
    role: "user",
  },
  {
    name: "AI Chat w/Matters",
    route: "aichat",
    icon: <ChatIcon />,
    role: "user",
  },
  {
    name: "Feedbacks",
    route: "feedbacks",
    icon: <FeedbackIcon />,
    role: "user",
  },
  {
    name: "Release Notes",
    route: "releasenotes",
    icon: <SettingsIcon />,
    role: "user",
  },
  // {
  //   name: "Bulk Upload",
  //   route: "bulk",
  //   icon: <PersonAdd />,
  // },
  // { name: "My Matters", route: "myprojects", icon: <PostAddIcon /> },
  // { name: "Manage Project", route: "projects", icon: <PostAddIcon /> },
  // {
  //   name: "Manage Prompts",
  //   route: "prompts",
  //   icon: <DesignServicesIcon />,
  // },
  // {
  //   name: "Add team member",
  //   route: "add",
  //   icon: <PersonAdd />,
  // },
  // {
  //   name: "Vision",
  //   route: "vision",
  //   icon: <FeedbackIcon />,
  // },
  // {
  //   name: "Chat with Images",
  //   route: "imagechat",
  //   icon: <ChatIcon />,
  // },
];

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function DrawerMenuLeft() {
  const { matter, setMatter } = useMatters();
  const [open, setOpen] = useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: blue[300],
      },
      secondary: {
        main: green[500],
      },
    },
  });

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleRoute = (route: string, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    setOpen(false);
    if (route === "folder") {
      if (matter) {
        navigate("/folder?matterid=" + matter?.id);
      } else {
        const _route = `/${route}`;
        navigate(_route);
      }
    } else if (route === "aichat") {
      if (matter) {
        navigate("/aichat?matterid=" + matter?.id);
      } else {
        const _route = `/${route}`;
        navigate(_route);
      }
    } else {
      const _route = `/${route}`;
      navigate(_route);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const prepareMenu = () => {
    {
      /* <ListItem key={0} disablePadding>
      <ListItemButton onClick={(e) => handleRoute("file", e)}>
        <ListItemIcon>
          <Home />
        </ListItemIcon>
        <ListItemText primary={"Dashboard"} />
      </ListItemButton>
    </ListItem> */
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Logo />
          <ul className="navbar-nav ms-auto ">
            <li className="nav-item">
              <AccountMenu />
            </li>
          </ul>
        </Toolbar>
      </AppBar>

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => open && handleDrawerClose()}
      >
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {menuItems.map((menu: any) => {
              if (menu.role === "admin" && !authService.isAdmin()) {
              } else {
                return (
                  <ListItem key={menu.route} disablePadding>
                    <ListItemButton onClick={(e) => handleRoute(menu.route, e)}>
                      <ListItemIcon>{menu.icon}</ListItemIcon>
                      <ListItemText primary={menu.name} />
                    </ListItemButton>
                  </ListItem>
                );
              }
            })}
          </List>
        </Drawer>
      </ClickAwayListener>
      <Main sx={{ padding: 0 }} open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );
}

import { Drawer, IconButton, IconButtonProps } from "@mui/material";
import Box from "@mui/material/Box/Box";
import FormControl from "@mui/material/FormControl/FormControl";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import { styled } from "@mui/material/styles";
import FileDisplayCard from "controls/FileDisplayCard";
import TagsInput from "controls/TagsInput";
import { Fragment, useEffect, useState } from "react";
import aiServices from "services/ai/ai.services";
import projectService from "services/project/project.service";
import tagsServices from "services/tags/tags.services";
import { ITags } from "services/types/types";
import Constants from "services/utils/Constants";
import TagsTable from "./TagsTable";
import "./tags.css";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TagsLanding = () => {
  const [tags, setTags] = useState<ITags[]>();
  const [uniqueTags, setUniqueTags] = useState<string[]>();
  const [selectedTags, setSelectedTags] = useState<ITags[]>();
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [state, setState] = useState(false);
  const [documentSummary, setDocumentSummary] = useState("");

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const toggleDrawer =
    (openState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setState(openState);
    };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedTag(event.target.value as string);
    const filteredFiles = tags?.filter(
      (tag) => tag.tag_name === event.target.value
    );

    setSelectedTags(filteredFiles);
    const filenames = filteredFiles?.map((item) => item.file_name) || [];
    setSelectedFiles(filenames);
  };
  const getTags = async () => {
    const tagsResponse = await tagsServices.getTags();

    if (tagsResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      const data = tagsResponse.data;
      setTags(data);

      const uniqueTagNames = Array.from(
        new Set(data.map((item) => item.tag_name))
      );

      setUniqueTags(uniqueTagNames);

      /* const tags = [];
      data.map((tag: ITags) => {
        tags.push()
      }) */
    }
  };

  const handleFileClick = async (filename: string) => {
    setSelectedFilename(filename);
    const fileResponse = await aiServices.getDocumentContent(
      -1,
      filename?.trim()
    );
    const fileSummaryResponse = await projectService.getDocumentByFilename(
      filename
    );
    if (fileResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      const fileData = fileResponse.data;

      const formattedSummary = fileData
        .split("\n")
        .map((line: any, index: any) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ));

      setFileContent(formattedSummary);
      toggleDrawer(true);
      setState(true);
    }

    if (fileSummaryResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      const fileSummary = fileSummaryResponse.data;

      const summary = fileSummary.file_content;

      const formattedSummary = summary
        .split("\n")
        .map((line: any, index: any) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ));

      setDocumentSummary(formattedSummary);
      toggleDrawer(true);
      setState(true);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <Fragment>
      <div className="vh">
        <div className="d-flex flex-row bd-highlight mb-3 p-3">
          <div className="p-2 bd-highlight">
            <Box sx={{ minWidth: 300 }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={selectedTag}
                  label="Tags"
                  onChange={handleChange}
                >
                  {uniqueTags?.map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="p-2 bd-highlight"></div>
          <div className="p-2 bd-highlight"></div>
        </div>
        <div className="d-flex flex-row bd-highlight mb-3 p-3">
          <TagsTable handleFileClick={handleFileClick} data={selectedTags} />
        </div>
      </div>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 600 }} role="presentation">
          <div className="sticky-top">
            <TagsInput tagSource={selectedFilename} />
          </div>

          <div className="p-4">
            <FileDisplayCard
              title="Summary"
              documentSummary={documentSummary}
              fileContent={fileContent}
            />
          </div>
        </Box>
      </Drawer>
    </Fragment>
  );
};

export default TagsLanding;

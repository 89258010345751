import { LinearProgress } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import aiServices from "../../services/ai/ai.services";

const BibleBook = ({ data, textToHighlight }) => {
  const [bibleData, setBibleData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const parts = data[0].metadata.file_path.split("/");
    const matterId = parts[1];
    const filename = parts[parts.length - 1];
    const fileName = data[0].metadata.file_name;

    const fetchData = async () => {
      try {
        const response = await aiServices.getFileResponse(matterId, fileName);
        const emailDataParsed = response?.data;
        const rawData = JSON.parse(emailDataParsed?.raw_data);
        setBibleData(rawData);
      } catch (error) {
      } finally {
        setLoading(false);  
      }
    };
    if (data) {
      fetchData();
    }
  }, [data]);
  function parseText(string) {
    var result = [];
    var currentText = "";
    for (var i = 0; i < string.length; i++) {
      if (string[i] === '"') {
        if (currentText) {
          result.push(currentText);
          currentText = "";
        } else {
          currentText = '"';
        }
      } else if (currentText) {
        currentText += string[i];
      }
    }
    var resultf = [];
    for (var i = 0; i < result.length; i += 1) {
      if (result[i].length > 10) resultf.push(result[i].substring(1));
    }

    return resultf;
  }
  // let textParts: string = "";
  // const jsonDataString = textToHighlight[0]?.text.substring(
  //   textToHighlight[0]?.indexOf("{")
  // );
  // textParts = parseText(jsonDataString);

  return (
    <Fragment>
      {loading && (
        <div>
          <LinearProgress />
        </div>
      )}
      <div className="p-4">
        <strong>{bibleData?.book}</strong>
        <hr />
        {bibleData?.chapters?.map((chapter, index) => (
          <div className="mb-5" key={index}>
            <h2 className="mb-3 fw-semibold">Chapter {chapter.chapter}</h2>
            {chapter.verses.map((verse, index) => (
              <p key={index} className="mb-2 d-flex">
                <strong>{verse.verse}: </strong>(<span>{verse.text}</span>)
              </p>
            ))}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default BibleBook;

import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";
import utils from "../utils/utils";

export const createReleaseNote = async (data: any) => {
  const requestData = {
    version: data.version,
    title: data.title,
    date: utils.getISODateString().slice(0, 10),
    features: data.features,
  };
  const url = endpoints.releaseNotes.createReleaseNote;

  return axiosInstance.post(url, requestData);
};

export const getReleaseNotes = async () => {
  const url = endpoints.releaseNotes.getReleaseNotes;
  return axiosInstance.get(url);
};

export const updateReleaseNote = async (data: any) => {
  const requestData = {
    ...data.formData,
    // features: [],
    date: utils.getISODateString().slice(0, 10),
  };
  const url = endpoints.releaseNotes.editReleaseNotes.replace(
    "{release_notes_id}",
    data.id.toString()
  );

  return axiosInstance.put(url, requestData);
};

export const getReleaseNote = async (id: Number) => {
  const url = endpoints.releaseNotes.getReleaseNote.replace(
    "{release_notes_id}",
    id.toString()
  );
  return axiosInstance.get(url);
};

export const deleteReleaseNote = async (noteId: number) => {
  const url = endpoints.releaseNotes.deleteReleaseNote.replace(
    "{release_notes_id}",
    noteId.toString()
  );
  return axiosInstance.delete(url);
};

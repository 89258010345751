import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Tooltip } from "@mui/material";
import React, {
  Fragment,
  MouseEventHandler,
  RefObject,
  useEffect,
  useState,
} from "react";
import Loading from "../controls/Loading";
import aiServices from "../services/ai/ai.services";

interface SelectionInfo {
  rect: DOMRect;
  selection: string;
}

interface Chunks {
  text: string[];
  page: string;
}

interface EmailDisplayProps {
  data: any[];
  selectedMatterId: string;
  passingRef: RefObject<HTMLDivElement>;
  mouseUp: MouseEventHandler<HTMLDivElement>;
  selectionInfo: SelectionInfo | null;
  handlePopupClose: () => void;
  handlePopoverEvents: () => void;
  setSelectedText: (text: string) => void;
  textToHighlight: Chunks[];
}

export const JsonRenderer = ({ data }: { data: any }) => {
  return (
    <div>
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          <strong>{key}:</strong>{" "}
          {typeof value === "object" ? (
            <JsonRenderer data={value} />
          ) : (
            <p>{value?.toString()}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export const TextFormatter = ({ text }: { text: string }) => {
  const lines = text.split("\r\n");
  return (
    <div style={{ fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </div>
  );
};

export const HtmlRenderer = ({ data }: { data: string }) => {
  return (
    <Fragment>
      <div className="d-flex">
        <b>From:</b>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.signature_data?.from_str || "",
          }}
        />
      </div>
      <div className="d-flex">
        <b>Sent:</b>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.signature_data?.date_str || "",
          }}
        />
      </div>
      <div className="d-flex">
        <b>To: </b>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.signature_data?.to_str || "",
          }}
        />
      </div>
      <div className="d-flex">
        <b>Subject: </b>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.signature_data?.to_subject || "",
          }}
        />
      </div>{" "}
      <br />
      <div dangerouslySetInnerHTML={{ __html: data?.email || "" }} />
    </Fragment>
  );
};

const EmailDisplay = ({
  data,
  selectedMatterId,
  passingRef,
  mouseUp,
  textToHighlight,
}: EmailDisplayProps) => {
  const [loading, setLoading] = useState(true);
  const [jsonDisplay, setJsonDisplay] = useState<any>();
  const [htmlDisplay, setHTMLDisplay] = useState<any>();
  const [refreshedEmailContent, setRefreshedEmailContent] = useState<any>();

  const beautifyJson = (data: any) => {
    const formattedBodyText = data
      .replaceAll("\n", "{break_hear}")
      .split("{break_hear}")
      .map((line: string, index: number) => {
        return (
          <Fragment>
            <p
              key={index}
              className="mt-1 mb-3"
              dangerouslySetInnerHTML={{ __html: line }}
            />
          </Fragment>
        );
      });
    return formattedBodyText;
  };

  const refreshEmailContent = async () => {
    try {
      setLoading(true);
      const responseData = await aiServices.refreshEmailContent(
        parseInt(selectedMatterId),
        data[0].metadata.file_name.replace(/#/g, "")
      );

      setRefreshedEmailContent(responseData?.clean_email);
      setJsonDisplay(undefined);
      setHTMLDisplay(undefined);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const filename = data[0].metadata.file_name.replace(/#/g, "");

      const response = await aiServices.getHtmlOrJson(
        parseInt(selectedMatterId),
        filename
      );
      try {
        const responseData = response;

        if (
          responseData?.status === 404 ||
          responseData?.status === 500 ||
          responseData?.status === 400 ||
          responseData?.error
        ) {
          setJsonDisplay("No data found");
          setLoading(false);
          return;
        }

        const fileType = responseData?.file_type;

        if (fileType === "html") {
          setJsonDisplay(undefined);
          setHTMLDisplay(responseData?.file_content);
          setLoading(false);
        } else {
          const content = responseData?.file_content;
          let parsedData;
          try {
            parsedData = JSON.parse(content);
            setJsonDisplay(parsedData);
          } catch (e) {
            setJsonDisplay(content);
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
        setJsonDisplay("Error in fetching data");
      }
    };
    if (data) {
      fetchData();
    }
  }, [data, selectedMatterId]);

  return (
    <Fragment>
      {loading && <Loading message="Loading email content..." />}

      {!loading && (
        <div className="container">
          <div className="col float-end">
            <Tooltip title="Click to format email if necessary">
              <Button
                variant="outlined"
                onClick={() => {
                  refreshEmailContent();
                }}
                startIcon={<RefreshIcon />}
              >
                Reformat
              </Button>
            </Tooltip>
          </div>
        </div>
      )}

      {jsonDisplay !== undefined && jsonDisplay !== null && (
        <div ref={passingRef} onMouseUp={mouseUp}>
          {typeof jsonDisplay === "object" ? (
            <JsonRenderer data={jsonDisplay} />
          ) : (
            <TextFormatter text={jsonDisplay} />
          )}
        </div>
      )}

      {htmlDisplay !== undefined && htmlDisplay !== null && (
        <div ref={passingRef} onMouseUp={mouseUp}>
          <HtmlRenderer data={htmlDisplay} />
        </div>
      )}

      {refreshedEmailContent !== undefined &&
        refreshedEmailContent !== null && (
          <div ref={passingRef} onMouseUp={mouseUp}>
            <div id="refreshed-content">
              {beautifyJson(refreshedEmailContent)}
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default EmailDisplay;

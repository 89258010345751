import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface MattersContextType {
  matter: null | {};
  setMatter: Dispatch<SetStateAction<null | {}>>;
}

// Create a context with a default value
const MattersContext = createContext<MattersContextType>({
  matter: null,
  setMatter: () => {},
});

// Create a provider component
export const MattersProvider = ({ children }) => {
  const [matter, setMatter] = useState<null | {}>(null);

  return (
    <MattersContext.Provider value={{ matter, setMatter }}>
      {children}
    </MattersContext.Provider>
  );
};

// Create a custom hook to use the context
export const useMatters = () => useContext(MattersContext);

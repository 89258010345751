import Alert from "@mui/material/Alert/Alert";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import React, { useEffect } from "react";

type IAlertsProps = {
  message: string;
  isError?: boolean;
  isDisplay?: boolean;
  setShowAlert: any;
};

const SnackAlert = (props: IAlertsProps) => {
  const [open, setOpen] = React.useState(props.isDisplay);

  useEffect(() => {
    setOpen(props.isDisplay);
  }, [props.isDisplay]);

  const handleClose = (event) => {
    if (event?.reason === "clickaway") {
      return;
    }

    props.setShowAlert(false);
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={props.isError ? "error" : "success"}
        sx={{ width: "100%" }}
        variant="filled"
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackAlert;

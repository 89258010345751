import { LinearProgress } from "@mui/material";
import React, { Fragment } from "react";

type LoadingProps = {
  message: string;
};

const Loading = (props: LoadingProps) => {
  return (
    <Fragment>
      <div className="py-4">
        <p> {props.message} </p>
        <LinearProgress />
      </div>
    </Fragment>
  );
};

export default Loading;

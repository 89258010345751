import { v4 as uuidv4 } from "uuid";
import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";
import Constants from "../utils/Constants";

export type ResponseSources = {
  text: string;
  file_name: string;
  score: number;
};

export type QueryResponse = {
  response: string;
  //sources: ResponseSources[];
};
class IndexService {
  download_for_index = async (projectId: string) => {
    const url = endpoints.index.download_for_index.replace(
      "{projectId}",
      projectId
    );
    const downloadResponse = await axiosInstance.get(url);
    if (downloadResponse.status == Constants.HTTP_STATUS.SUCCESS) {
    }
  };

  query_index = (
    projectId: string,
    query: string,
    aiModel: string = "gpt3",
    sliderValue: string,
    matter_context?: string,
    chatId?: string | null, 
    chatName?: string,
   
  ) => {
    try {
      console.log(sliderValue,'slidervalue');
      const queryURL = endpoints.ai.query_ai_index
        .replace("{project_id}", projectId)
        .replace("{query_text}", query)
        .replace("{similarity_top_k}",sliderValue)
        .replace("{aiModel}", aiModel)
        .replace("{matter_context}", matter_context ? matter_context : "")
        .replace("{name}", chatName ? chatName : "")
        .replace("{user_id}", authService.getUserId())
        .replace("{user_name}", authService.getUserInfo().full_name)
        .replace("{conversation_id}", chatId ? chatId : "")
        .replace("&conversation_id=", chatId ? "&conversation_id=" : "");

      const headers = {
        "x-request-id": uuidv4(),
        token: localStorage.getItem("token"),
      };

      const res = new EventSource(queryURL);

      return res;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  just_chat = async (
    query: string,
    queryModels: any = ["gpt", "mistral", "llama", "gemini"]
  ) => {
    try {
      const data = {
        query_text: query,
        models: queryModels,
      };

      const queryURL = endpoints.ai.just_chat;

      return axiosInstance.post(queryURL, data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  //just_chat

  // queryIndexx = async (
  //   projectId: string,
  //   query: string,
  //   aiModel: string,
  //   topK: string
  // ) => {
  //   try {
  //     const queryURL = endpoints.ai.queryAI
  //       .replace("{query_text}", query)
  //       .replace("{project_id}", projectId)
  //       .replace("{aiModel}", aiModel)
  //       .replace("{topK}", topK);

  //     return axiosInstance.get(queryURL);
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //   }
  // };

  // queryIndex = async (
  //   projectId: string,
  //   query: string,
  //   aiModel: string,
  //   topK: string
  // ): Promise<QueryResponse> => {
  //   // const queryURL = new URL("http://localhost:8000/query/" + query);
  //   const queryURL = new URL(
  //     endpoints.ai.queryAI
  //       .replace("{query_text}", query)
  //       .replace("{project_id}", projectId)
  //       .replace("{aiModel}", aiModel)
  //       .replace("{topK}", topK)
  //   );

  //   const config = {
  //     headers: {
  //       "X-Request-ID": uuidv4(),
  //     },
  //   };

  //   const response = await fetch(queryURL, {
  //     mode: "cors",
  //     headers: config.headers,
  //   });
  //   if (!response.ok) {
  //     return { response: "Error in query" };
  //   }

  //   const queryResponse = (await response.json()) as QueryResponse;
  //
  //   return queryResponse;
  // };
}

export default new IndexService();

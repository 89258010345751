import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React, { Fragment, useState } from "react";
import authService from "services/auth.service";
import projectService from "services/project/project.service";
import Confirmation from "./Confirmation";
import CreateIssues from "./CreateIssues";
import CreatePeoples from "./CreatePeoples";
import CreateProject from "./CreateProject";
import UploadDocuments from "./UploadDocuments";

export default function StartProject() {
  const steps = [
    "Create Matter",
    "Relevant Issues",
    "Relevant Witness",
    /* "Preview", */
    "Upload files",
    "Confirmation",
  ];

  const [step, setstep] = useState(1);

  interface FormData {
    projectName: string;
    description: string;
    issues: any[];
    peoples: string;
    files: any[];
    owner_id: string;
  }

  const initialFormData: FormData = {
    projectName: "",
    description: "",
    issues: [],
    peoples: "",
    files: [],
    owner_id: "",
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);

  const handleIssue = (value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      issues: value,
    }));
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input: any) => (e: any) => {
    // input value from the form
    let { value } = e.target;

    if (input == "files") {
      value = Array.from(e.target.files);
    }

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
    /* const issuesList = formData.issues.split("\n");
    // Create an object and add strings as properties
    const jsonObject: { [key: string]: string } = {};

    issuesList.forEach((str, index) => {
      jsonObject[`item_${index + 1}`] = str;
    });

    // Convert the object to JSON
    const jsonString = JSON.stringify(jsonObject);

     */
  };

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if (newActiveStep == steps.length) {
      submitProject();
    }

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const isAllStepsCompleted = allStepsCompleted();
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setFormData(initialFormData);
  };

  const submitProject = () => {
    let userData = authService.getUserInfo();
    formData.owner_id = userData.id;
    projectService.project(formData);
  };

  return (
    <Box className="mt-2 mb-1 p-5" sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }} className="p-4">
              <span className="fs-3">Matter created successfully.</span>
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <div className="mt-2 mb-1 p-3" style={{ minHeight: "300px" }}>
              {(() => {
                switch (activeStep + 1) {
                  case 1:
                    return (
                      <CreateProject
                        nextStep={handleNext}
                        handleFormData={handleInputData}
                        values={formData}
                      />
                    );
                  case 2:
                    return (
                      <CreateIssues
                        nextStep={handleNext}
                        prevStep={handleBack}
                        handleFormData={handleIssue}
                        values={formData}
                      />
                    );
                  case 3:
                    return (
                      <CreatePeoples
                        nextStep={handleNext}
                        prevStep={handleBack}
                        handleFormData={handleInputData}
                        values={formData}
                      />
                    );
                  /* case 4:
                    return (
                      <ProjectPreview
                        nextStep={handleNext}
                        prevStep={handleBack}
                        handleFormData={handleInputData}
                        values={formData}
                      />
                    ); */
                  case 4:
                    return (
                      <UploadDocuments
                        nextStep={handleNext}
                        prevStep={handleBack}
                        submitProject={submitProject}
                        handleFormData={handleInputData}
                        values={formData}
                      />
                    );
                  case 5:
                    return (
                      <Confirmation
                        nextStep={handleNext}
                        prevStep={handleBack}
                        submitProject={submitProject}
                        handleFormData={handleInputData}
                        values={formData}
                      />
                    );
                  default:
                    return (
                      <CreateProject
                        handleFormData={handleInputData}
                        values={formData}
                      />
                    );
                }
              })()}
            </div>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
                pb: 2,
                px: 1,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button> */}
              {activeStep !== steps.length && (
                <Button onClick={handleComplete}>
                  {completedSteps() === totalSteps() - 1 ? "Finish" : "Next"}
                </Button>
              )}
            </Box>
          </Fragment>
        )}
      </div>
    </Box>
  );
}

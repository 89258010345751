import PeopleGraph from "charts/PeopleGraph";
import ButtonDropdown from "controls/ButtonDropdown";
import ContextMenu from "controls/ContextMenu";
import React, { Fragment, useEffect, useState } from "react";
import projectService from "services/project/project.service";
import Constants from "services/utils/Constants";

const MyProjects = () => {
  const [projectResponse, setProjectResponse] = useState<any>(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState(null);
  const [dashboardData, setDashboardData] = useState<any>(null);
  const [peopleInvolved, setPeopleInvolved] = useState<any>();
  const [issuesWithPeople, setIssuesWithPeople] = useState<any>();
  const [peopleData, setPeopleData] = useState([
    {
      count: 0, // Initial count value
      name: [],
    },
  ]);

  const [issueData, setIssueData] = useState([
    {
      count: 0, // Initial count value
      name: "",
    },
  ]);

  const getProjects = async () => {
    const projectsResponse: any = await projectService.getMattersByUserId();

    if (projectsResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      setProjects(projectsResponse.data);
      //loadDashboard(projectsResponse.data[0].id);
    }

    // Extract people involved

    const issuePeopleInvolved = {
      issueName: "",
      count: "",
    };

    setIssuesWithPeople(issuesWithPeople);
  };

  const extractIssueData = (
    data: { documents_response: any[] } | undefined
  ) => {
    let issueName = "",
      relavanceName = "";
    let involvedCount = 0;
    let relevantCount = 0;

    data?.documents_response.forEach((document) => {
      // Check if any issue_answer is "Involved"

      document.issues_answers.map(
        (issue: { issue_name: string; issue_answer: string }) => {
          if (issue.issue_answer?.trim().toLowerCase().includes("involved")) {
            issueName = issue?.issue_name;
            involvedCount++;
          }

          if (issue.issue_answer?.trim().toLowerCase() === "maybe relevant") {
            relavanceName = issue?.issue_name;
            relevantCount++;
          }
        }
      );
    });

    const issueData = [];

    if (involvedCount > 0) {
      const data = {
        name: issueName,
        count: involvedCount,
      };
      issueData.push(data);
    }

    if (relevantCount > 0) {
      const data = {
        name: relavanceName,
        count: relevantCount,
      };
      issueData.push(data);
    }

    setIssueData(issueData);
  };

  const loadDashboard = async (projectId: string) => {
    setLoading(false);
    const project = projects.filter((p: any) => p.id == projectId)[0];
    setSelectedProject(project);

    const dashboardResponse = await projectService.getDashboard(projectId);

    if (dashboardResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      setDashboardData(dashboardResponse.data);

      /* const peopleInvolved = dashboardResponse.data.peoples_involved.map(
        (person: any) => person.name
      );
      setPeopleInvolved(peopleInvolved); */

      /* projectService.dashboardIns(
        projectId,
        JSON.stringify(dashboardResponse.data)
      ); */

      setPeopleData(dashboardResponse.data.peoples_involved);
      extractIssueData(dashboardResponse.data);

      setLoading(true);
    } else {
      setLoading(true);
    }

    /* const peopleInvolved = dashboardResponse.peoples_involved.map(
      (person: any) => person.name
    );
    setPeopleInvolved(peopleInvolved);
    setPeopleData(dashboardResponse.peoples_involved); */
  };

  const handleSelection = async (event: any) => {
    const projectId = event.currentTarget.dataset.output;

    if (!projectId) return;

    const project = projects.filter((p: any) => p.id == projectId)[0];
    setSelectedProject(project);

    //loadDashboard(projectId);
  };

  const handlePeopleChartClick = (params: any) => {
    /* 

    const issues: any = dashboardResponse.documents_response;
    issues.map((issue: any) => {
      
    });

    // Extract issues and associated people
    const issuesWithPeople = dashboardResponse.documents_response.map(
      (document) => {
        const issues = document.issues_answers.map((issue) => issue.issue_name);

        issues.map((issue: any) => {
          
        });

        const people = document.peoples_involved.map((person) => person.name);
        return {
          document_name: document.document_name,
          issues,
          people,
        };
      }
    ); */
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    // When the component mounts, add values from the response to the state
  }, []);

  useEffect(() => {}, [dashboardData]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="d-flex flex-row bd-highlight mb-3">
          <div className="p-2 bd-highlight">
            <ButtonDropdown
              className="w-100"
              data={projects}
              handleSelection={handleSelection}
            />
          </div>
          <div className="p-2 bd-highlight"></div>
        </div>
      </div>
      <div className="container-fluid">
        {/* <div className="row row-cols-2">
          <div className="col">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              {loading && (
                <BarChart
                  title="Relevant Documents vs. People"
                  count={dashboardData?.total_documents_count}
                  data={peopleData}
                  handleChartClick={handlePeopleChartClick}
                />
              )}

              {!loading && (
                <div>
                  <RowLoading />
                </div>
              )}
            </div>
          </div>
          <div className="col">
            <div className="shadow-sm p-3 mb-5 bg-body rounded">
              {loading && (
                <BarChart
                  title="Relevant Documents vs. Issue"
                  count={dashboardData?.total_documents_count}
                  data={issueData}
                  handleChartClick={handlePeopleChartClick}
                />
              )}

              {!loading && (
                <div>
                  <RowLoading />
                </div>
              )}
            </div>
          </div>
        </div> */}
        <div className="col">
          <div className="shadow-sm p-3 mb-5 bg-body rounded">
            <PeopleGraph />
          </div>
        </div>
        <ContextMenu />
      </div>
    </Fragment>
  );
};

export default MyProjects;

import AddToDriveRoundedIcon from "@mui/icons-material/AddToDriveRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  IconButtonProps,
  Input,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useState } from "react";
import EmailDisplay from "../components/EmailDisplay";
import Utils from "../services/utils/utils";
//import BasicViewer from "../components/PDFViewer/BasicViewer";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import DownloadFile from "../components/DownloadFile";
import BibleBook from "../components/chat/BibleBook";
import { SourceNode } from "../components/chat/types";
import aiServices from "../services/ai/ai.services";
import authService from "../services/auth.service";
import endpoints from "../services/configs/endpoints";
import Constants from "../services/utils/Constants";
import utils from "../services/utils/utils";
import Loading from "./Loading";
import ReferenceCard from "./ReferenceCard";
import "./file-display-card.css";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`; // pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.2.16.105.js";

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const FileDisplayCard = ({
  title,
  fileReferenceData,
  selectedMatterId,
  passingRef,
  mouseUp,
  selectionInfo,
  handlePopupClose,
  handlePopoverEvents,
  setSelectedText,
  handleFileEvents = () => {},
  qa,
  extractFilename,
}: any) => {
  const [expanded, setExpanded] = useState(true);

  const [summary, setSummary] = useState();
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [emailAttachments, setEmailAttachments] = useState([]);
  const [loadingAttachment, setLoadingAttachment] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const chunks = Object.keys(fileReferenceData[0]);
  const metadata = chunks?.map((chunk: any) => {
    return fileReferenceData[0][chunk];
  });
  const sourceNodes = metadata.map((data) => {
    return data?.source_nodes ? data?.source_nodes : null;
  });

  const references = sourceNodes?.map((sourceNode) => {
    return sourceNode?.filter((node) => {
      return (
        node?.node?.metadata?.file_name ===
        fileReferenceData[0].metadata.file_name
      );
    });
  });
  const referencesData = references
    ?.map((reference) => {
      return reference?.map((ref) => {
        const newRef = ref?.node?.text.replace(/Source \d+:/, "");

        return newRef.replace("\n", "");
      });
    })
    .filter((item) => item !== undefined);
  // For TXT FILE - START

  const source_nodes = qa.tree_summarize.source_nodes;
  //
  //
  interface Chunks {
    text: string;
    page: string;
  }
  const findChunkToHighlight = (): Chunks[] => {
    let extractedText: Chunks[] = [];
    // Iterate through the sourceNodes array
    for (let i = 0; i < source_nodes.length; i++) {
      // Check if the current node's metadata has the desired file_name
      if (
        source_nodes[i].node.metadata &&
        source_nodes[i].node.metadata.file_name === title
      ) {
        // Add the text of the current node to extractedText
        extractedText.push({
          text: source_nodes[i].node.text.toString().split("\\r\\n"),
          page: source_nodes[i].node.metadata.page_label,
        });
      }
    }

    //
    return extractedText;
  };

  const handlePrintSummary = () => {
    Utils.handlePrint("file-reference");
  };

  const findAllNodesByFileName = (
    sourceNodes: SourceNode[],
    fileName: string
  ): string[] => {
    const matchingIndices: string[] = [];

    // Iterate through the sourceNodes array
    for (let i = 0; i < sourceNodes.length; i++) {
      // Check if the current node's metadata has the desired file_name
      if (
        sourceNodes[i].node.metadata &&
        sourceNodes[i].node.metadata.file_name === fileName
      ) {
        // Add the index of the node to the matchingIndices array as a string
        matchingIndices.push((i + 1).toString());
      }
    }
    // Return the array of matching node indices
    return matchingIndices;
  };

  const onHandleReference = async (item: any) => {
    const filename = extractFilename(item?.file_name?.trim());
    if (!filename) return;
    if (filename.includes(".pdf")) {
      window.open(
        `${endpoints.ai.v2.getAttachmentFile
          .replace("{matter_id}", selectedMatterId.toString())
          .replace("{file_name}", item.file_name.replace(/#/g, ""))
          .replace("{owner_id}", authService.getUserId().toString())}`,

        "_blank"
      );
    }
  };

  const addLineBreaks = (str: string): string => {
    return str
      ?.replace(/\n/g, "<br />")
      .replace(/(Company names:)/g, "<strong>$1</strong><br />")
      .replace(/(People names:)/g, "<br /><strong>$1</strong><br />")
      .replace(/(Specific topics:)/g, "<br /><strong>$1</strong><br />")
      .replace(/(General themes:)/g, "<br /><strong>$1</strong><br />");
  };

  const retrievers = ["topk", "tree_summarize", "fusion"];
  const [pageNumber, setPageNumber] = useState(1);

  const loadAttachments = async () => {
    const filename = fileReferenceData[0]?.metadata?.file_name;
    aiServices
      .getEmailAttachments(filename, selectedMatterId)
      .then((attachmentResponse: any) => {
        if (attachmentResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
          //

          try {
            const attachments = attachmentResponse.data;

            const filteredAttachments = attachments.filter(
              (attachment: any) => {
                const fileName = attachment.file_name.toLowerCase();
                return (
                  fileName.endsWith(".pdf") ||
                  fileName.endsWith(".doc") ||
                  fileName.endsWith(".docx")
                );
              }
            );

            setEmailAttachments(filteredAttachments);
            setLoadingAttachment(false);
          } catch (error) {
            console.error("An error occurred:", error);
            setLoadingAttachment(false);
          } finally {
          }
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const filename = fileReferenceData[0]?.metadata?.file_name;
    const summary = aiServices.getSummary(selectedMatterId, filename);
    summary.then((response) => {
      let summary = response?.data.summary;
      summary = summary.replace(/^(Summary:|summary:)\s*/, "");
      setSummaryLoading(false);
      setLoadingAttachment(false);
      setSummary(summary);
    });

    if (filename.includes(".json")) {
      loadAttachments();
    }
  }, []);

  return (
    <Fragment>
      <Card id="file-reference" className="card-drawer">
        <CardContent>
          <div className="mb-3 d-flex justify-content-between border-top border-bottom">
            <div className="p-2 my-3 fw-semibold">
              {fileReferenceData[0]?.metadata?.file_name}
            </div>

            <div className="mt-3">
              <Paper
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  id="addToFile"
                  color="primary"
                  sx={{ p: "5px" }}
                  aria-label="directions"
                  onClick={(e) => handleFileEvents(e)}
                >
                  <AddToDriveRoundedIcon />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  color="primary"
                  sx={{ p: "5px" }}
                  aria-label="directions"
                  onClick={handlePrintSummary}
                >
                  <PrintIcon />
                </IconButton>
              </Paper>
            </div>
          </div>

          <Typography variant="body2">
            <p>
              <strong>Summary:</strong>
            </p>

            {summaryLoading && (
              <Loading message="We are summarizing your document" />
            )}

            <div
              dangerouslySetInnerHTML={{ __html: addLineBreaks(summary) || "" }}
            />
          </Typography>
        </CardContent>
        {/* TODO: UNCOMMENT IT */}
        {fileReferenceData[0]?.metadata?.file_name?.includes(".pdf") ? (
          <ReferenceCard
            chunks={findChunkToHighlight()}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        ) : null}
        <CardActions style={{ borderTop: "1px solid gray" }} disableSpacing>
          <h1>Native Doc:</h1>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            title="Expand more"
          >
            <ExpandMoreIcon style={{ color: "gray" }} />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout="auto">
          <CardContent
            className="text-black"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            {loadingAttachment && (
              <Loading message="Please wait while we load the attachment, if any." />
            )}

            {emailAttachments.length > 0 && (
              <Accordion sx={{ minWidth: "80%" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="ai-reference-content"
                  id="ai-references"
                >
                  <b> {`File Attachments [${emailAttachments.length}]`}</b>
                </AccordionSummary>
                <AccordionDetails>
                  {emailAttachments.map((m, inx) => {
                    //

                    return (
                      <Fragment key={inx}>
                        <ButtonGroup
                          className="m-1"
                          variant="contained"
                          aria-label="split button"
                        >
                          <Tooltip
                            title={
                              m.file_name.includes(".docx") ||
                              m.file_name.includes(".doc")
                                ? "Please download this file to view"
                                : "View this file"
                            }
                          >
                            <Button
                              // disabled={
                              //   m.file_name.includes(".docx") ||
                              //   m.file_name.includes(".doc")
                              // }
                              style={
                                m.file_name.includes(".docx") ||
                                m.file_name.includes(".doc")
                                  ? { color: "black", backgroundColor: "gray" }
                                  : {}
                              }
                              sx={{
                                textTransform: "uppercase",
                                fontSize: "11px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onHandleReference(m);
                              }}
                            >
                              {utils.truncateFilename(m.file_name)}
                            </Button>
                          </Tooltip>

                          <Tooltip title="Add this file to a folder">
                            <Button
                              id="addToFile"
                              onClick={(e) => {
                                handleFileEvents(e, m);
                              }}
                            >
                              <CreateNewFolderRoundedIcon />
                            </Button>
                          </Tooltip>

                          {
                            <DownloadFile
                              matter_id={selectedMatterId}
                              file={m}
                              filename={m.file_name}
                            />
                          }
                        </ButtonGroup>
                      </Fragment>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            )}
            <Typography paragraph>
              {fileReferenceData[0]?.metadata?.file_name?.includes(".json") &&
              selectedMatterId.toString() == 133 ? (
                <BibleBook
                  data={fileReferenceData}
                  textToHighlight={findChunkToHighlight()}
                />
              ) : fileReferenceData[0]?.metadata?.file_name?.includes(
                  ".pdf"
                ) ? (
                <PdfComp
                  selectedMatterId={selectedMatterId}
                  fileReferenceData={fileReferenceData}
                  referencesData={referencesData}
                  textToHighlight={findChunkToHighlight()}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              ) : fileReferenceData[0]?.metadata?.file_name?.includes(
                  ".doc"
                ) ? (
                <Doc
                  selectedMatterId={selectedMatterId}
                  fileReferenceData={fileReferenceData}
                />
              ) : fileReferenceData[0]?.metadata?.file_name?.includes(
                  ".docx"
                ) ? (
                <Docx
                  selectedMatterId={selectedMatterId}
                  fileReferenceData={fileReferenceData}
                />
              ) : (
                <EmailDisplay
                  selectedMatterId={selectedMatterId}
                  data={fileReferenceData}
                  passingRef={passingRef}
                  mouseUp={mouseUp}
                  selectionInfo={selectionInfo}
                  handlePopupClose={handlePopupClose}
                  handlePopoverEvents={handlePopoverEvents}
                  setSelectedText={setSelectedText}
                  textToHighlight={findChunkToHighlight()}
                />
              )}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Fragment>
  );
};

export default FileDisplayCard;

function PdfComp({ pageNumber, setPageNumber, ...props }) {
  const [numPages, setNumPages] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);

  function highlightPattern(text, pattern) {
    return text.replace(pattern, (value) => `<mark>${value}</mark>`);
  }
  const textRenderer = ({ str }) => {
    return highlightPattern(str, searchText);
  };
  function onChange(event) {
    setSearchText(event.target.value);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setLoading(false);
  }

  function changePage(offset) {
    setPageNumber(
      (prevPageNumber) => parseInt(prevPageNumber, 10) + parseInt(offset, 10)
    );
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const [open, setOpen] = useState(false);
  const [jumpToPage, setJumpToPage] = useState(null);
  const highlightext = props.textToHighlight;
  // useEffect(() => {
  //   try {
  //     const url = `${endpoints.ai.v2.getAttachmentFile
  //       .replace("{matter_id}", props.selectedMatterId.toString())
  //       .replace(
  //         "{file_name}",
  //         props.fileReferenceData[0].metadata.file_name.replace(/#/g, "")
  //       )
  //       .replace("{owner_id}", authService.getUserId().toString())}`;
  //     fetch(url);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, []);
  return (
    <>
      {loading && <LinearProgress />}
      <br></br>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>PDF Viewer</DialogTitle>
        <DialogContent>
          <Document // TODO; use pdf comp with some more props
            file={
              // "https://shark-app-3m9ls.ondigitalocean.app/get_file/126/2018_11_09_07_17_47_148363_Morgan_Hendrix_attachment_1.pdf"
              `${endpoints.ai.v2.getAttachmentFile
                .replace("{matter_id}", props.selectedMatterId.toString())
                .replace(
                  "{file_name}",
                  props.fileReferenceData[0].metadata.file_name.replace(
                    /#/g,
                    ""
                  )
                )
                .replace("{owner_id}", authService.getUserId().toString())}`
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer
                // customTextRenderer={({ str, itemIndex }) =>
                //   utils.highlightTextpdf(str, highlightext)
                // }
              />
            ))}
          </Document>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Open Full Viewer
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            window.open(
              `${endpoints.ai.v2.getAttachmentFile
                .replace("{matter_id}", props.selectedMatterId.toString())
                .replace(
                  "{file_name}",
                  props.fileReferenceData[0].metadata.file_name.replace(
                    /#/g,
                    ""
                  )
                )
                .replace("{owner_id}", authService.getUserId().toString())}`,
              "_blank"
            )
          }
        >
          Open in New Tab
        </Button>
      </div>
      {/* <input type="text" value={searchText} onChange={onChange} /> */}
      <Document
        file={`${endpoints.ai.v2.getAttachmentFile
          .replace("{matter_id}", props.selectedMatterId.toString())
          .replace(
            "{file_name}",
            props.fileReferenceData[0].metadata.file_name.replace(/#/g, "")
          )
          .replace("{owner_id}", authService.getUserId().toString())}`}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={parseInt(pageNumber, 10)}
          // renderTextLayer={false}
          renderAnnotationLayer
          // renderMode="custom"
          // customTextRenderer={({ str, itemIndex }) =>
          //   utils.highlightTextpdf(str, highlightext)
          // }
        />
      </Document>
      <Typography>
        Jump to page: {jumpToPage || (numPages ? 1 : "--")} of {numPages}
      </Typography>
      <Input
        onChange={(e) => setJumpToPage(parseInt(e.target.value, 10))}
      ></Input>
      <Button onClick={() => setPageNumber(jumpToPage)}>Jump</Button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={previousPage}
            disabled={pageNumber <= 1}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={nextPage}
            disabled={pageNumber >= numPages}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

function Doc({ fileReferenceData, selectedMatterId }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      const url = `${endpoints.ai.v2.getAttachmentFile
        .replace("{matter_id}", selectedMatterId.toString())
        .replace(
          "{file_name}",
          fileReferenceData[0].metadata.file_name.replace(/#/g, "")
        )
        .replace("{owner_id}", authService.getUserId().toString())}`;
      fetch(url);
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div>
      {loading && <LinearProgress />}
      <iframe
        title="doc"
        src={`https://view.officeapps.live.com/op/view.aspx?src=${endpoints.ai.v2.getAttachmentFile
          .replace("{matter_id}", selectedMatterId.toString())
          .replace(
            "{file_name}",
            fileReferenceData[0].metadata.file_name.replace(/#/g, "")
          )
          .replace("{owner_id}", authService.getUserId().toString())}`}
        width="100%"
        height={`${window.innerHeight - 250}px`}
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
}

function Docx({ fileReferenceData, selectedMatterId }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      const url = `${endpoints.ai.v2.getAttachmentFile
        .replace("{matter_id}", selectedMatterId.toString())
        .replace(
          "{file_name}",
          fileReferenceData[0].metadata.file_name.replace(/#/g, "")
        )
        .replace("{owner_id}", authService.getUserId().toString())}`;
      fetch(url);
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div>
      {loading && <LinearProgress />}
      <div>
        <iframe
          title="docx"
          src={`https://docs.google.com/viewer?url=${endpoints.ai.v2.getAttachmentFile
            .replace("{matter_id}", selectedMatterId.toString())
            .replace(
              "{file_name}",
              fileReferenceData[0].metadata.file_name.replace(/#/g, "")
            )
            .replace("{owner_id}", authService.getUserId().toString())}`}
          width="100%"
          height={`${window.innerHeight - 250}px`}
          onLoad={() => setLoading(false)}
        ></iframe>
      </div>
    </div>
  );
}

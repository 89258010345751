import { Typography } from "@mui/material";
import ModuleLayout from "components/ModuleLayout";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import projectService from "services/project/project.service";
import Constants from "services/utils/Constants";
import { v4 as uuidv4 } from "uuid";
import ToastMessage from "../../components/ToastMessage";
import { useMatters } from "../../context/MattersProvider";
import { triggerJenkins } from "../../services/MatterCreation/matterCreation.services";
import DashboardCard from "./card";
import "./dashboard.css";

const Matter = () => {
  const navigate = useNavigate();
  const { matter, setMatter } = useMatters();
  const [projects, setProjects] = useState<any>([]);
  const [inactiveProjects, setInactiveProjects] = useState<any>([]);
  const trigger = async (data) => {
    const res = await triggerJenkins(data.id, data.owner_id);
    if (res.status == Constants.HTTP_STATUS.SUCCESS) {
      <ToastMessage
        title="Success"
        onCloseToast={null}
        openState={true}
        message="Started Successfully"
      />;
      navigate(`/matter-creation/${data.id}`);
    }
  };
  const handleTrigger = (data) => {
    trigger(data);
  };
  const [showModal, setShowModal] = useState(true); // Set initial state to true to open modal directly

  const handleClick = (event: string, project: any) => {
    setMatter(project);
    switch (event) {
      case "chat":
        navigate("/aichat", {
          state: {
            project,
          },
        });
        break;
      case "file":
        navigate("/folder", {
          state: {
            project,
          },
        });
        break;
      case "create":
        navigate("/company", {
          state: {
            project,
          },
        });
        break;
      case "modal":
        setShowModal(true);
        break;
      case "progress":
        navigate(`/matter-creation/${project.id}`);
        break;
      default:
        break;
    }
  };

  const getMatters = async () => {
    const projectsResponse: any = await projectService.getMattersByUserId();
    const inactiveProjects = await projectService.inactiveProjects();

    if (projectsResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      setProjects(projectsResponse.data);
    }
    if (inactiveProjects.status == Constants.HTTP_STATUS.SUCCESS) {
      setInactiveProjects(inactiveProjects.data);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      return; // Stop execution
    }
    getMatters();
  }, [showModal]);

  return (
    <Fragment>
      <ModuleLayout title="">
        <div className="row gap-3 d-flex flex-row bd-highlight">
          {/* TODO: UNCOMMENT IT */}
          {/* <StartProject onClose={() => setShowModal(false)} /> */}

          {projects.map((project: any) => {
            return (
              <div key={uuidv4()} className="p-2 bd-highlight project-card">
                <DashboardCard
                  data={project}
                  handleClick={handleClick}
                  trigger={handleTrigger}
                  getMatters={getMatters}
                  inactive={false}
                />
              </div>
            );
          })}
          {inactiveProjects.length === 0 ? null : (
            <Typography variant="h5" style={{ marginTop: "16px" }} gutterBottom>
              Inactive Projects
            </Typography>
          )}

          {inactiveProjects.map((project: any) => {
            return (
              <div
                key={uuidv4()}
                className="p-2 bd-highlight project-card mb-4"
              >
                <DashboardCard
                  data={project}
                  handleClick={handleClick}
                  trigger={handleTrigger}
                  getMatters={getMatters}
                  inactive={true}
                />
              </div>
            );
          })}
        </div>
      </ModuleLayout>
    </Fragment>
  );
};

export default Matter;

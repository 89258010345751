// components/ChatDrawer.js
import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import Drawer from "@mui/material/Drawer/Drawer";
import TextField from "@mui/material/TextField/TextField";
import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import FoldersTree from "../folderstree/FoldersTree";

const ChatDrawer = ({
  matter_id,
  chatResponse,
  openState,
  setOpenState,
  onSelection,
  onAddToFolderClick,
  onChatFileNameChange,
}) => {
  //const refReferenceInput = useRef<HTMLInputElement | null>(null);

  const [isValid, setIsValid] = useState(false);
  const handleReference = async (event: any, item: any) => {
    event.preventDefault();
  };

  useEffect(() => {
    // if (refReferenceInput.current) {
    //   refReferenceInput.current.focus();
    // }
  }, [openState]);

  return (
    <Drawer anchor="right" open={openState} onClose={() => setOpenState(false)}>
      <Box className="vh-100" sx={{ width: 600 }} role="presentation">
        <Row className="p-3">
          <div className="fw-semibold">
            To enable the Button "Add to Folders" {">"} Enter reference name and
            select folder to save
          </div>
          <div className="form-floating mb-2">
            <TextField
              className="w-100"
              required
              label="Reference name"
              variant="filled"
              placeholder="Enter reference name to identify (Must be at least 5 characters)."
              fullWidth
              autoFocus
              onChange={(event) => {
                const value = event.target.value;
                setIsValid(value.length >= 5);

                if (value.length >= 5) {
                  onChatFileNameChange(event.target.value);
                }
              }}
            />
          </div>
        </Row>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <strong>{chatResponse.question}</strong>
            </Accordion.Header>
            <Accordion.Body>
              <Box className="p-5" role="presentation">
                <div
                  className="mb-3"
                  dangerouslySetInnerHTML={{
                    __html: chatResponse.answer,
                  }}
                />

                {chatResponse?.filenames?.map((file: any, index) => {
                  return (
                    <Button
                      className="mb-3"
                      key={index}
                      color="primary"
                      sx={{ fontWeight: "normal", textAlign: "left" }}
                      onClick={(e) => handleReference(e, file)}
                    >
                      {file.file_name}
                    </Button>
                  );
                })}
              </Box>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Box className="p-3" role="presentation">
          <FoldersTree matter_id={matter_id} onSelection={onSelection} />
        </Box>
        <Box className="p-3">
          <Button
            onClick={onAddToFolderClick}
            variant="contained"
            className="mt-5"
            size="large"
            disabled={!isValid}
          >
            Add to Folder(s)
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ChatDrawer;

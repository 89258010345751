import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import Button from "@mui/material/Button/Button";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import fileDownload from "js-file-download";
import React, { Fragment, useState } from "react";
import authService from "../services/auth.service";
import endpoints from "../services/configs/endpoints";
import SnackAlert from "./SnackAlert";

type DownloadFileProps = {
  url?: string;
  matter_id: number;
  file: any;
  filename: string;
  color?: string;
};

const DownloadFile = (props: {
  url?: string;
  matter_id: number;
  file: any;
  filename: string;
  color?: string;
}) => {
  const [showAlert, setShowAlert] = useState(false);
  async function downloadFile() {
    // const parts = props.filename.split("<|>");
    // const file_name = parts[1].trim();
    var file_name = "";
    if (props.filename.includes("<|>")) {
      var parts = props.filename.split("<|>");
      file_name = parts[1].trim();
    } else {
      file_name = props.filename;
    }
    try {
      //utils.downloadFile(props.matter_id, props.filename);
      const url = `${endpoints.ai.v2.getAttachmentFile
        .replace("{matter_id}", props.matter_id.toString())
        .replace("{file_name}", file_name.replace("#", ""))
        .replace("{owner_id}", authService.getUserId().toString())}`;
      const response = await fetch(url);

      if (!response.ok) {
        setShowAlert(true);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.blob();
      fileDownload(data, file_name);
    } catch (error) {
      console.error("An error occurred:", error);
      setShowAlert(true);
    }
  }
  return (
    <Fragment>
      <Tooltip title="Download this file to your computer">
        <Button
          sx={{ p: "5px" }}
          aria-label="directions"
          onClick={() => downloadFile()}
          color={props.color}
        >
          <DownloadForOfflineRoundedIcon />
        </Button>
      </Tooltip>
      {showAlert && (
        <SnackAlert
          message="Currently, it is not possible to download the file. Please try again later."
          isDisplay={true}
          isError={true}
          setShowAlert={setShowAlert}
        />
      )}
    </Fragment>
  );
};

export default DownloadFile;

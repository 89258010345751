import Box from "@mui/material/Box/Box";
import Drawer from "@mui/material/Drawer/Drawer";
import React, { Fragment, useEffect, useState } from "react";
import FileDisplayCard from "../../../controls/FileDisplayCard";
import aiServices from "../../../services/ai/ai.services";
import Constants from "../../../services/utils/Constants";
import NotesForm from "../../notes/NotesForm";
const DocumentViewer = ({
  document,
  openState,
  setOpenState,
  matter_id,
  matter,
}) => {
  const [fileContent, setFileContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [documentSummary, setDocumentSummary] = useState("");
  const [selectedFilename, setSelectedFilename] = useState("");

  const onLoad = async () => {
    if (!document) return;

    const fileResponse = await aiServices.getDocumentContent(
      document.metadata.document_id
    );

    if (fileResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      let fileData = fileResponse.data;

      let fileContentResponse = fileData;

      const summary = fileContentResponse.file_content;
      const formattedSummary = summary
        .split("\n")
        .map((line: any, index: any) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ));

      setLoading(false);
      setSelectedFilename(document.metadata.file_name);
      setDocumentSummary(formattedSummary);
      setFileContent(fileContentResponse.raw_data);
    }
  };

  useEffect(() => {
    if (!openState) return;
    setLoading(true);

    onLoad();
  }, [document, openState]);

  return (
    <Fragment>
      <Drawer
        anchor="right"
        open={openState}
        onClose={() => setOpenState(false)}
      >
        <NotesForm
          matter_id={matter_id}
          matter={matter}
          context_type={"file"}
        />
        <div className="vh-100">
          <Box className="vh-100" sx={{ width: 600 }} role="presentation">
            <div className="p-4">
              {loading && <span>Loading...</span>}
              {!loading && (
                <FileDisplayCard
                  title={selectedFilename}
                  documentSummary={documentSummary}
                  fileContent={fileContent}
                />
              )}
            </div>
          </Box>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default DocumentViewer;

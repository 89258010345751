import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import AWS from "aws-sdk";
import axios from "axios";
import React from "react";
import { Fragment, useEffect, useState } from "react";
import authService from "../../services/auth.service";
import indexService from "../../services/chat/index.service";
import endpoints from "../../services/configs/endpoints";
import projectService from "../../services/project/project.service";
import utils from "../../services/utils/utils";

const BulkUpload = () => {
  const STATUS = {
    UPLOADED: "Uploaded",
    PENDING: "Pending",
  };

  const s3 = new AWS.S3({
    accessKeyId: "DO0068DAXJX6EBER8L2E",
    secretAccessKey: "GeDxmblXOvHaVWkRp4e85eSKUtDG3+YE1wYCrS5Y8iI",
    region: "nyc3",
    endpoint: "https://nyc3.digitaloceanspaces.com",
  });

  const [files, setFiles] = useState([]);
  const [count, setCount] = useState(0);
  const [displayLoading, setDisplayLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [matters, setMatters] = useState<any>();
  const [inputValue, setInputValue] = useState<any>();

  const [fileInputControl, setFileInputControl] = useState("");

  const [sourceFiles, setSourceFiles] = useState<
    { id: number; name: string; status: string; date: string }[]
  >([]);

  const loadProjects = async () => {
    const response = await projectService.getMattersByUserId();
    const responseData = response.data;

    const extractedData = responseData.map(({ project_name, id }: any) => ({
      label: project_name,
      value: id,
    }));

    setMatters(extractedData);
  };

  const addNewFile = (id: number, name: string, size: any) => {
    size = convertToKBOrMB(size);
    const now: Date = new Date();
    const converted = utils.getDisplayDate(now.toString());
    const newFile = {
      id,
      name: name,
      size: size,
      status: STATUS.PENDING,
      date: converted,
    };
    //sourceFiles.push(newFile);

    setSourceFiles((prevFiles) => [...prevFiles, newFile]);
  };

  const updateFileStatus = (
    index: number,
    newStatus: string = STATUS.PENDING
  ) => {
    const now: Date = new Date();
    setSourceFiles((prevFiles) =>
      prevFiles.map((file: any) =>
        file.id === index ? { ...file, status: newStatus } : file
      )
    );
  };

  const fileEventHandler = (event: any) => {
    event.preventDefault();
    setFileInputControl(event.target.value);
    setFiles(Array.from(event.target.files));

    const files = Array.from(event.target.files);

    files.map((file: any, index) => {
      addNewFile(index, file.name, file.size);
    });

    /* 
    files.forEach((file) => {
      
    }); */
  };

  //file_location
  //file_name

  const uploadFile = (event: any) => {
    if (files.length <= 0) return;
    setDisplayLoading(true);
    const userId = authService.getUserId();
    let fileCount = 0;
    setTimeout(() => {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      files.forEach((file) => {
        let data = new FormData();
        data.append("files", file);
        axios
          .post(
            endpoints.project.uploadDocument
              .replace("{userid}", userId)
              .replace("{project_id}", inputValue),
            data,
            config
          )
          .then((res) => {
            updateFileStatus(fileCount, STATUS.UPLOADED);
            setCount(fileCount++);
          })
          .catch((error) => {})
          .finally(() => {
            setFileInputControl("");
            files.splice(file);

            if (fileCount >= sourceFiles.length) {
              setDisplayLoading(false);
            }
          });
      });
    }, 5000);
  };

  useEffect(() => {
    loadProjects();
  }, []);

  const convertToKBOrMB = (value: number, unit: "KB" | "MB" = "KB"): string => {
    if (unit === "KB") {
      const kilobytes = Math.round(value / 1024);
      return `${kilobytes} KB`;
    } else if (unit === "MB") {
      const megabytes = Math.round(value / 1024 / 1024);
      return `${megabytes} MB`;
    } else {
      return "Invalid unit";
    }
  };

  const triggerIndex = (project_id: string) => {
    indexService.download_for_index(project_id);
  };

  const handleUpload = () => {
    setDisplayLoading(true);
    const projectId = inputValue;
    const Bucket = `evident-storage/${projectId}`;
    const uploadPromises: any[] = [];
    setDisplayLoading(true);
    sourceFiles.forEach(
      (file, inx) => {
        const params = {
          Bucket,
          Key: file["name"],
          Body: files[inx],
        };

        const uploadPromise = new Promise((resolve, reject) => {
          s3.upload(params, (err: any, data: any) => {
            if (err) {
              console.error("Error uploading file:", err);
              reject(err);
            } else {
              updateFileStatus(inx, STATUS.UPLOADED);
              resolve(data);
            }
          });
        });

        uploadPromises.push(uploadPromise);
      },

      Promise.all(uploadPromises)
        .then(() => {
          setDisplayLoading(false);
          //triggerIndex(projectId);
        })
        .catch(() => {
          setDisplayLoading(false);
        })
    );
  };

  return (
    <Fragment>
      <Card className="container card p-3">
        <CardContent>
          <Typography gutterBottom variant="h5" color={"white"} component="div">
            Upload to Matters
          </Typography>
          <Fragment>
            {displayLoading && <LinearProgress className="mt-4 mb-4" />}
          </Fragment>
          <div className="row">
            <div className="col-6 p-2 bd-highlight">
              <FormControl fullWidth className="mb-2">
                <InputLabel id="select-matter">Select Matter</InputLabel>
                <Select
                  labelId="select-matter"
                  className="bg-white"
                  label="Matters"
                  value={inputValue}
                  onChange={(event, value: any) => {
                    setInputValue(value.props.value);
                  }}
                >
                  {matters?.map((project: any, index: any) => (
                    <MenuItem key={project.value} value={project.value}>
                      {project.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="d-flex flex-row mb-3">
                <input
                  type="file"
                  id="choosefile"
                  className="form-control"
                  onChange={fileEventHandler}
                  value={fileInputControl}
                  multiple
                />

                <Button
                  onClick={handleUpload}
                  className="mx-3"
                  variant="contained"
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </CardContent>
      </Card>
      <div className="container mt-5">
        <div className="row">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>File name</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Modified Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sourceFiles?.map((row: any, inx) => (
                  <TableRow
                    key={inx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <a
                        href="#"
                        className="text-decoration-underline fw-bolder"
                      >
                        {" "}
                        {row.name}{" "}
                      </a>
                    </TableCell>

                    <TableCell>{row.size}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </Fragment>
  );
};

export default BulkUpload;

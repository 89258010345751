import AddIcon from "@mui/icons-material/Add";
import Edit from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import { Button, Drawer, IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  getMessages,
  getNames,
  renameChat,
} from "../../services/chat/chat.history.service";
import Constants from "../../services/utils/Constants";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  cursor: "pointer",
}));
export default function History({ historyOpen, setHistoryOpen, matterId }) {
  const [history, setHistory] = React.useState([]);
  const fetchData = async () => {
    if (!matterId) return;
    try {
      const response = await getNames(matterId);
      if (response?.status == Constants.HTTP_STATUS.SUCCESS) {
        setHistory(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };
  React.useEffect(() => {
    fetchData();
  }, [matterId]);
  const navigate = useNavigate();
  const handleHistoryClick = async (e) => {
    // const restart = await restartChat(e);
    // if (restart?.status == Constants.HTTP_STATUS.SUCCESS) {
    try {
      const messages = await getMessages(e);
      if (messages?.status == Constants.HTTP_STATUS.SUCCESS) {
        //
        navigate(`/aichat?id=${e}`, {
          state: { messages: messages.data },
          replace: true,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // }
  };

  const handleNewChatClick = async () => {
    navigate("/aichat", { replace: true });
  };
  return (
    <>
      <Drawer
        anchor="left"
        open={historyOpen}
        onClose={() => setHistoryOpen(false)}
        sx={{
          width: "200px",
          flexShrink: 0,
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Button
            variant="contained"
            onClick={handleNewChatClick}
            startIcon={<AddIcon />}
          >
            New Chat
          </Button>
          {history.map((item, index) => {
            return (
              <HistoryCard
                key={index}
                history={item}
                handleHistoryClick={handleHistoryClick}
                fetchData={fetchData}
              />
            );
          })}
        </Stack>
      </Drawer>
      <IconButton
        onClick={() => setHistoryOpen((prev) => !prev)}
        color="primary"
        sx={{ p: "10px" }}
        aria-label="directions"
      >
        <HistoryIcon />
      </IconButton>

      {/* <IconButton
        onClick={() => setHistoryOpen((prev) => !prev)}
        className="px-2"
        style={{
          position: "fixed",
          right: "90px",
          bottom: "100px",
          zIndex: 1,
        }}
        color="primary"
        aria-label="Click to add Notes"
      >
        <div
          style={{
            padding: "16px",
            borderRadius: "100%",
            background: "white",
            boxShadow: "0px 0px 5px 0px #0000001a",
            width: "52px",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: blue[500],
          }}
        >
          <HistoryIcon style={{ color: "white" }} fontSize="small" />
        </div>
      </IconButton> */}
    </>
  );
}

const HistoryCard = ({ history, handleHistoryClick, fetchData }) => {
  const [edit, setEdit] = React.useState(false);
  const [name, setName] = React.useState(history.name);
  //
  return edit ? (
    <Item>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Button
        onClick={async () => {
          await renameChat(history.id, name);
          setEdit(false);
          fetchData();
        }}
      >
        Save
      </Button>
      <Button onClick={() => setEdit(false)}>Cancel</Button>
    </Item>
  ) : (
    <Item onClick={() => handleHistoryClick(history.id)}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="pt-2">{history.name}</div>
        <IconButton
          // onClick={() => handleHistoryClick(item.id)}
          color="primary"
          aria-label="Click to Rename Chat"
        >
          <Edit
            fontSize="small"
            onClick={(e) => {
              e.stopPropagation();
              setEdit(true);
            }}
          />
        </IconButton>
      </div>
    </Item>
  );
};

import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Input,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button/Button";
import IconButton from "@mui/material/IconButton";
import { classNames } from "@progress/kendo-react-common";
import { useInternationalization } from "@progress/kendo-react-intl";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Editor } from "react-draft-wysiwyg";
import { Document, Page } from "react-pdf";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ToastMessage, { IToast } from "../../../components/ToastMessage";
import AdvanceTextarea from "../../../controls/AdvanceTextarea";
import Loading from "../../../controls/Loading";
import aiServices from "../../../services/ai/ai.services";
import authService from "../../../services/auth.service";
import endpoints from "../../../services/configs/endpoints";
import {
  FilingCabinetData,
  addChat,
  addChatToFilingCabinet,
} from "../../../services/filingcabinet/filingcabinet.service";
import { updateNote } from "../../../services/notes/notes.service";
import Constants from "../../../services/utils/Constants";
import utils from "../../../services/utils/utils";
import DownloadFile from "../../DownloadFile";
import { HtmlRenderer, JsonRenderer, TextFormatter } from "../../EmailDisplay";
import FileReference from "../../chat/FileReference";
import FoldersTree from "../folderstree/FoldersTree";
import {
  convertDateFormat,
  formatBytes,
  getName,
} from "../helpers/helperMethods";
import { DataModel } from "../interfaces/FileManagerModels";
import DocumentViewer from "./DocumentViewer";

const cardStyle = {
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  transition: "0.3s",
  borderRadius: "8px",
  marginBottom: "20px",
  border: "1px solid #e0e0e0",
  backgroundColor: "#fafafa",
  width: "80%",
  marginLeft: "auto",
  marginRight: "auto",
};
export const FileInformation = (props: any) => {
  if (!props.data) return NoDataRendering();

  console.log("props.data", props.data);

  const fileReferenceRetriever = ["topk", "topk_file", "topk_folder"];

  if (typeof props.data === "number") {
    return MultipleSelectionRendering(props.data);
  } else if (props.data.type == "chat") {
    return ChatDataRendering(props.data, props.matter_id, props.matter);
  } else if (props.data.type == "note") {
    return NotesData(
      props.data,
      props.matter_id,
      props.matter,
      props.setRefresh
    );
  } else if (props.data.type == "File") {
    return FileDataRendering(props.data);
  } else if (props.data.type == "TODO") {
    return NoDataRendering();
  } else if (props.data.type === "pdf") {
    return <PdfComp data={props.data} />;
  } else if (props.data.type === "doc") {
    return <Doc data={props.data} />;
  } else if (props.data.type === "docx") {
    return <Docx data={props.data} />;
  } else if (props.data.type === "json") {
    return <EmailViewer data={props.data} />;
  } else {
    FileSelectionRendering(props.data);
  }
};

const NoDataRendering = () => {
  return (
    <div className="p-5">
      <p className="lead">Will implement soon.</p>
    </div>
  );
};

const handlePrint = (divId: string) => {
  utils.handlePrint(divId);
};

const handlePrinttxt = () => {
  utils.handlePrint("file-reference");
};
const handlePrintchat = () => {
  utils.handlePrint("chatprint");
};
const parseTextContent = (textContent: any) => {
  try {
    textContent = JSON.parse(textContent.raw_data);
    textContent = textContent?.body.replace(/\*/g, "").replace(/\\r\\n+/g, "");
    // textContent = textContent?.body.replace(/b'/g, "");
    // textContent = textContent?.body.replace(/b"/g, "");
    // textContent = textContent?.body.replace(/\\r\\n+/g, "\n");
  } catch (error) {
    console.log(error);

    try {
      textContent = textContent?.raw_data;
    } catch (error) {
      console.log(error);
    }
  }

  const formattedBodyText = textContent
    .split("\n")
    .map((line: any, index: any) => (
      <Fragment key={index}>
        <p>{line}</p>
      </Fragment>
    ));

  return formattedBodyText;
};

const FileSelectionRendering = (item: DataModel) => {
  const intl = useInternationalization();
  const dateCreated: String = convertDateFormat(
    item.dateCreated ? item.dateCreated : null,
    intl
  );
  const dateModified: String = convertDateFormat(
    item.dateModified ? item.dateModified : null,
    intl
  );

  return (
    <div className="k-filemanager-preview" style={{ width: "100%", border: 0 }}>
      <div className="k-file-info">
        <span className="k-file-preview">
          <span
            className={classNames(
              "k-file-icon k-icon",
              item.icon ? item.icon.iconClass : ""
            )}
          ></span>
        </span>
        <span className="k-file-name k-single-file-selected">
          {item.path ? getName(item.path) : ""}
        </span>
        <dl className="k-file-meta">
          <dt className="k-file-meta-label">Type: </dt>
          <dd className={"k-file-meta-value k-file-type"}>
            {" "}
            {item.icon ? item.icon.type : ""}
          </dd>
          <dd className="k-line-break"></dd>
          <dt className="k-file-meta-label">Size: </dt>
          <dd className="k-file-meta-value k-file-size">
            {" "}
            {item.size ? formatBytes(item.size) : ""}
          </dd>
          <dd className="k-line-break"></dd>
          <dt className="k-file-meta-label">Date Created: </dt>
          <dd className="k-file-meta-value k-file-created">
            {" "}
            {dateCreated ? dateCreated : ""}
          </dd>
          <dd className="k-line-break"></dd>
          <dt className="k-file-meta-label">Date Modified: </dt>
          <dd className="k-file-meta-value k-file-modified">
            {" "}
            {dateModified ? dateModified : ""}
          </dd>
          <dd className="k-line-break"></dd>
        </dl>
      </div>
    </div>
  );
};

const MultipleSelectionRendering = (length) => {
  return (
    <div className="k-filemanager-preview" style={{ width: "100%", border: 0 }}>
      <div className="k-file-info">
        <span className="k-file-preview" style={{ width: "100%", border: 0 }}>
          <span className="k-file-icon k-icon k-i-file"></span>
        </span>
        <span className="k-file-name k-multiple-files-selected">
          {length} items
        </span>
      </div>
    </div>
  );
};

const Editable = ({ data, setData }) => {
  var objectConstructor = {}.constructor;
  const textdata =
    data.constructor === objectConstructor ? data : JSON.parse(data);
  return <AdvanceTextarea value={textdata} setValue={setData} />;
};

const Readable = ({ data }) => {
  var objectConstructor = {}.constructor;
  const textdata =
    data.constructor === objectConstructor ? data : JSON.parse(data);
  return (
    <Editor
      wrapperClassName="wrapper"
      editorClassName="editor"
      toolbarHidden
      readOnly
      defaultContentState={textdata}
    />
  );
};

const NotesData = (note, matter_id, matter, setRefresh) => {
  const [folderViewState, setFolderViewState] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>(null);
  const toggleFolderView =
    (openState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setFolderViewState(openState);
    };
  const handleDisplayFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    note: any
  ): void => {
    e.preventDefault();
    setFolderViewState(true);
    setSelectedNote(note);
  };
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [toast, setToast] = useState<IToast>({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });
  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };
  const handleSelectedFolder = async (selectedFolders) => {
    setSelectedFolders(selectedFolders);
  };

  const handleAddToFolder = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    const chatStr = JSON.stringify(selectedNote);
    const addChatResponse = await addChat(chatStr, matter_id);

    if (addChatResponse?.status === Constants.HTTP_STATUS.SUCCESS) {
      let chatData = addChatResponse.data;

      const notesReference = `${utils.getDateForNotes(
        matter?.modified_date
      )}.note`;
      const data: FilingCabinetData = {
        filename: notesReference,
        originalPath: notesReference,
        file_type: "note",
        parent_node_ids: selectedFolders,
        size: 0,
        matter_id: matter_id,
        chat_id: chatData.id,
      };

      const addChatToFile = await addChatToFilingCabinet(data);

      if (addChatToFile?.status == Constants.HTTP_STATUS.SUCCESS) {
        setRefresh((prev) => !prev);
        setToast({
          ...toast,
          openState: true,
          title: "Success",
          message: "Document/Chat has been added to the filing cabinet.",
          onCloseToast: onCloseToast,
          messageType: "bg-primary text-white",
        });
      } else {
        setToast({
          ...toast,
          openState: true,
          title: "Error",
          message: "Unable to create folder.",
          onCloseToast: onCloseToast,
          messageType: "bg-danger text-white",
        });
      }
    }
  };
  const [editMode, setEditMode] = useState(false);
  const handleSave = async () => {
    const notesResponse: any = await updateNote({
      ...note.data,
      note_desc: JSON.stringify(noteData),
    });

    if (notesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      setToast({
        ...toast,
        openState: true,
        title: "Success",
        message: "Note has been updated.",
        onCloseToast: onCloseToast,
        messageType: "bg-primary text-white",
      });
      setEditMode(false);
      setRefresh((prev) => !prev);
    } else {
      setToast({
        ...toast,
        openState: true,
        title: "Error",
        message: "Unable to update note.",
        onCloseToast: onCloseToast,
        messageType: "bg-danger text-white",
      });
    }
  };
  const [noteData, setNoteData] = useState<any>(note.data.note_desc);
  return (
    <Fragment>
      <div className="row mx-auto">
        <Typography variant="body2" color="textSecondary">
          {utils.getDisplayDate(note.data.modified_date)}
        </Typography>
        {
          <Typography
            className="text-muted"
            variant="body2"
            color="textSecondary"
          >
            {note.data.context_type === "chat"
              ? "Capture while chatting"
              : "Capture on filing cabinet"}
          </Typography>
        }
      </div>
      <Card
        className="mt-3 "
        style={{ background: editMode ? "white" : "#f4f498" }}
        sx={cardStyle}
      >
        {editMode ? (
          <CardContent>
            <Editable data={noteData} setData={setNoteData} />
          </CardContent>
        ) : (
          <CardContent>
            {/* <Editor
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarHidden
              readOnly
              defaultContentState={JSON.parse(note.data.note_desc)}
            /> */}
            <Readable data={noteData} />
          </CardContent>
        )}
        <hr className="m-0" />
        <CardActions>
          <div className="d-flex">
            <IconButton
              onClick={(e) => handleDisplayFolder(e, note.data)}
              className="px-2"
              color="primary"
              aria-label="Click to add to File cabinate"
            >
              <AddToDriveIcon fontSize="small" />
            </IconButton>

            {!editMode && (
              <IconButton
                onClick={(e) => setEditMode(true)}
                className="px-2"
                color="primary"
                aria-label="Click to Edit"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>

          {/* <Button
            onClick={(e) => handleDelete(note.data.id)}
            variant="outlined"
            color="error"
            size="small"
            sx={{ marginLeft: "auto", color: "#f44336" }} // Styling for the delete button
          >
            Delete
          </Button> */}
          {editMode && (
            <Button
              onClick={(e) => handleSave()}
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: "auto" }}
            >
              Save
            </Button>
          )}
        </CardActions>
      </Card>
      <Drawer
        anchor="right"
        open={folderViewState}
        onClose={toggleFolderView(false)}
      >
        <Box className="vh-100" sx={{ width: 600 }} role="presentation">
          <ToastMessage {...toast} />;
          <Box className="p-5" role="presentation">
            <div className="mb-3">
              {selectedNote && (
                <Fragment>
                  Reference:{" "}
                  <mark>
                    <u>{`${utils.getDateForNotes(
                      matter?.modified_date
                    )}.note`}</u>
                  </mark>
                  <Card
                    className="mt-3 "
                    style={{ background: "#f4f498" }}
                    sx={cardStyle}
                  >
                    <CardContent>
                      <Editor
                        wrapperClassName="wrapper"
                        editorClassName="editor"
                        toolbarHidden
                        readOnly
                        defaultContentState={JSON.parse(note.data.note_desc)}
                      />
                    </CardContent>
                  </Card>
                </Fragment>
              )}
            </div>

            <FoldersTree
              matter_id={matter_id}
              onSelection={handleSelectedFolder}
            />
            <Button
              variant="contained"
              className="mt-5"
              onClick={handleAddToFolder}
            >
              Add to Folder(s)
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};

const ChatDataRendering = (item: DataModel, matter_id, matter) => {
  const [document, setDocument] = useState("");
  const [openDocumentViewer, setOpenDocumentViewer] = useState(false);

  const handleReference = async (event: any, item: any) => {
    event.preventDefault();

    setDocument(item);
    setOpenDocumentViewer(true);
  };

  const documentViewerState = async (isDisplay: boolean = false) => {
    setOpenDocumentViewer(isDisplay);
  };

  const displayDocument = async (item: any) => {};

  useEffect(() => {}, [openDocumentViewer, document]);

  return (
    <Fragment>
      <div id="chatprint">
        <Container>
          <DocumentViewer
            document={document}
            openState={openDocumentViewer}
            setOpenState={documentViewerState}
            matter_id={matter_id}
            matter={matter}
          />
          <Row className="p-3">
            <Col className="d-flex justify-content-end">
              <IconButton
                color="primary"
                sx={{ p: "5px" }}
                aria-label="Print Summary"
                onClick={handlePrintchat}
                style={{ marginRight: "10px" }}
              >
                <PrintIcon />
              </IconButton>
            </Col>
          </Row>
          <Row>
            <Col className="p-5">
              <div className="fw-semibold mb-3">{item.data.question}</div>
              <Fragment>
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: item.data?.topk?.response
                      ? `<strong>Agent 1:</strong> ${item.data?.topk?.response}`
                      : "<strong>Agent 1:</strong> Loading...",
                  }}
                ></div>
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: item.data?.tree_summarize?.response
                      ? `<strong>Agent 2:</strong> ${item.data?.tree_summarize?.response}`
                      : " <strong>Agent 2:</strong> Loading...",
                  }}
                ></div>
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: item.data?.fusion?.response
                      ? `<strong>Agent 3:</strong> ${item.data?.fusion?.response}`
                      : "<strong>Agent 3:</strong> Loading...",
                  }}
                ></div>
              </Fragment>

              <FileReference
                label="AI Agent 1 References"
                nodes={item.data?.topk}
                response={item.data.response}
                qa={item.data}
                selectedMatterId={matter_id}
              />
              {/* <FileReference
                label="AI Agent 2 References"
                nodes={item.data?.tree_summarize}
                response={item.data.response}
              />

              <FileReference
                label="AI Agent 3 References"
                nodes={item.data?.fusion}
                response={item.data.response}
              />
              <FileReference
                label="AI Agent 4 References"
                nodes={item.data.response?.gemini}
                response={item.data.response}
              /> */}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

const FileDataRendering = (item) => {
  return (
    <Fragment>
      <div id="file-reference">
        <Container>
          <Row className="p-3 bg-dark text-white">
            <strong>File name</strong>
            <div className="text-normal">{item.path}</div>
          </Row>
          <Row className="p-3">
            <Col className="d-flex justify-content-end">
              <IconButton
                color="primary"
                sx={{ p: "5px" }}
                aria-label="Print Summary"
                onClick={handlePrinttxt}
                style={{ marginRight: "10px" }}
              >
                <PrintIcon />
              </IconButton>
            </Col>
          </Row>
          <Row className="p-5">
            <Col>
              <div>{parseTextContent(item.data)}</div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

function PdfComp(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  function highlightPattern(text, pattern) {
    return text.replace(pattern, (value) => `<mark>${value}</mark>`);
  }
  const textRenderer = ({ str }) => {
    return highlightPattern(str, searchText);
  };
  function onChange(event) {
    setSearchText(event.target.value);
  }
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
    setLoading(false);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const [open, setOpen] = useState(false);
  const [jumpToPage, setJumpToPage] = useState(null);
  const iframeRef = useRef(null);
  const [fileUrl, setFileUrl] = useState(null);
  useEffect(() => {
    const handlePrint = async () => {
      const res = await fetch(
        `${endpoints.ai.v2.getAttachmentFile
          .replace("{matter_id}", props.data.matter_id.toString())
          .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
          .replace("{owner_id}", authService.getUserId().toString())}`
      );
      const data = await res.blob();
      const fileUrl = URL.createObjectURL(data);
      setFileUrl(fileUrl);
    };
    handlePrint();
  }, []);
  useEffect(() => {
    try {
      const url = `${endpoints.ai.v2.getAttachmentFile
        .replace("{matter_id}", props.data.matter_id.toString())
        .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
        .replace("{owner_id}", authService.getUserId().toString())}`;
      fetch(url);
    } catch (e) {
      console.log(e);
    }
  }, []);
  const printFile = () => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow.print();
    }
  };
  useEffect(() => {
    if (fileUrl) {
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = fileUrl;
      iframe.onload = () => {
        iframeRef.current = iframe;
      };
      document.body.appendChild(iframe);

      return () => {
        document.body.removeChild(iframe);
      };
    }
  }, [fileUrl]);
  return (
    <>
      {loading && <LinearProgress />}
      <br></br>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>PDF Viewer</DialogTitle>
        <DialogContent>
          <Document
            file={`${endpoints.ai.v2.getAttachmentFile
              .replace("{matter_id}", props.data.matter_id.toString())
              .replace(
                "{file_name}",
                props.data.original_path.replace(/#/g, "")
              )
              .replace("{owner_id}", authService.getUserId().toString())}`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Open Full Viewer
        </Button>
        <Button onClick={printFile}>
          <PrintIcon />
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            window.open(
              `${endpoints.ai.v2.getAttachmentFile
                .replace("{matter_id}", props.data.matter_id.toString())
                .replace(
                  "{file_name}",
                  props.data.original_path.replace(/#/g, "")
                )
                .replace("{owner_id}", authService.getUserId().toString())}`,
              "_blank"
            )
          }
        >
          Open in New Tab
        </Button>
      </div>
      <Document
        file={
          // "https://shark-app-3m9ls.ondigitalocean.app/get_file/126/2018_11_09_07_17_47_148363_Morgan_Hendrix_attachment_1.pdf"
          `${endpoints.ai.v2.getAttachmentFile
            .replace("{matter_id}", props.data.matter_id.toString())
            .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
            .replace("{owner_id}", authService.getUserId().toString())}`
        }
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          // renderTextLayer={false}
          renderAnnotationLayer
          // renderMode="custom"
        />
      </Document>
      <Typography>
        Jump to page: {jumpToPage || (numPages ? 1 : "--")} of {numPages}
      </Typography>
      <Input
        onChange={(e) => setJumpToPage(parseInt(e.target.value, 10))}
      ></Input>
      <Button onClick={() => setPageNumber(jumpToPage)}>Jump</Button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={previousPage}
            disabled={pageNumber <= 1}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={nextPage}
            disabled={pageNumber >= numPages}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}
function Doc(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      const url = `${endpoints.ai.v2.getAttachmentFile
        .replace("{matter_id}", props.data.matter_id.toString())
        .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
        .replace("{owner_id}", authService.getUserId().toString())}`;
      fetch(url);
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div>
      {loading && <LinearProgress />}
      <iframe
        title="doc"
        src={`https://view.officeapps.live.com/op/view.aspx?src=${endpoints.ai.v2.getAttachmentFile
          .replace("{matter_id}", props.data.matter_id.toString())
          .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
          .replace("{owner_id}", authService.getUserId().toString())}`}
        width="100%"
        height={`${window.innerHeight - 250}px`}
        onLoad={() => setLoading(false)}
      ></iframe>
    </div>
  );
}

function Docx(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    try {
      const url = `${endpoints.ai.v2.getAttachmentFile
        .replace("{matter_id}", props.data.matter_id.toString())
        .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
        .replace("{owner_id}", authService.getUserId().toString())}`;
      fetch(url);
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div>
      {loading && <LinearProgress />}
      <div>
        <iframe
          title="docx"
          src={`https://docs.google.com/viewer?url=${endpoints.ai.v2.getAttachmentFile
            .replace("{matter_id}", props.data.matter_id.toString())
            .replace("{file_name}", props.data.original_path.replace(/#/g, ""))
            .replace("{owner_id}", authService.getUserId().toString())}`}
          width="100%"
          height={`${window.innerHeight - 250}px`}
          onLoad={() => setLoading(false)}
        ></iframe>
      </div>
    </div>
  );
}

function EmailViewer(props) {
  const [loading, setLoading] = useState(true);
  const [jsonDisplay, setJsonDisplay] = useState<any>();
  const [htmlDisplay, setHTMLDisplay] = useState<any>();
  const [emailAttachments, setEmailAttachments] = useState([]);
  const [loadingAttachment, setLoadingAttachment] = useState(true);

  const styles = {
    xsicon: {
      width: 10,
      height: 10,
    },
    verticalAling: {
      textAlign: "left",
      marginTop: 10,
    },
  };

  const onHandleReference = async (item: any) => {
    const filename = item?.file_name?.trim();
    if (!filename) return;
    if (filename.includes(".pdf")) {
      window.open(
        `${endpoints.ai.v2.getAttachmentFile
          .replace("{matter_id}", props.data.matter_id.toString())
          .replace("{file_name}", item.file_name.replace(/#/g, ""))
          .replace("{owner_id}", authService.getUserId().toString())}`,

        "_blank"
      );
    }
  };

  useEffect(() => {
    setLoading(true);

    const loadAttachments = async (filename, matter_id) => {
      aiServices
        .getEmailAttachments(filename, matter_id)
        .then((attachmentResponse: any) => {
          if (attachmentResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
            //

            try {
              const attachments = attachmentResponse.data;

              const filteredAttachments = attachments.filter(
                (attachment: any) => {
                  const fileName = attachment.file_name.toLowerCase();
                  return (
                    fileName.endsWith(".pdf") ||
                    fileName.endsWith(".doc") ||
                    fileName.endsWith(".docx")
                  );
                }
              );

              setEmailAttachments(filteredAttachments);
              setLoadingAttachment(false);
            } catch (error) {
              console.error("An error occurred:", error);
              setLoadingAttachment(false);
            } finally {
            }
          }
        })
        .catch((err) => {});
    };

    const fetchData = async () => {
      const filename = props.data.original_path.replace(/#/g, "");
      const matter_id = props.data.matter_id.toString();

      const response = await aiServices.getHtmlOrJson(
        parseInt(matter_id),
        filename
      );
      try {
        const responseData = response;
        console.log("props.data Email viewer", responseData);

        if (
          responseData?.status === 404 ||
          responseData?.status === 500 ||
          responseData?.status === 400 ||
          responseData?.error
        ) {
          setJsonDisplay("No data found");
          setLoading(false);
          return;
        }

        const fileType = responseData?.file_type;

        if (fileType === "html") {
          setJsonDisplay(undefined);
          setHTMLDisplay(responseData?.file_content);
          setLoading(false);
        } else {
          const content = responseData?.file_content;
          let parsedData;
          try {
            parsedData = JSON.parse(content);
            setJsonDisplay(parsedData);
          } catch (e) {
            setJsonDisplay(content);
          } finally {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
        setJsonDisplay("Error in fetching data");
      }
    };

    fetchData();
    loadAttachments(props.data.original_path, props.data.matter_id);
  }, []);

  return (
    <Fragment>
      <div id="email-viewer">
        <Container>
          <Row className="p-2 bg-dark text-white">
            <div className="d-flex p-2 justify-content-between">
              <div className="fw-bold" style={styles.verticalAling}>
                Email
              </div>
              <div className="text-secondary">
                <IconButton
                  color="primary"
                  aria-label="Print Summary"
                  onClick={() => handlePrint("email-viewer")}
                  size="small"
                >
                  <PrintIcon />
                </IconButton>
              </div>
            </div>
          </Row>
          <Row id="email-content" className="p-3">
            <Col>
              {loadingAttachment && (
                <Loading message="Please wait while we load the attachment, if any." />
              )}
              {emailAttachments.length > 0 && (
                <Accordion className="mb-3" sx={{ minWidth: "80%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="ai-reference-content"
                    id="ai-references"
                  >
                    <b> {`File Attachments [${emailAttachments.length}]`}</b>
                  </AccordionSummary>
                  <AccordionDetails>
                    {emailAttachments.map((m, inx) => {
                      return (
                        <Fragment key={inx}>
                          <ButtonGroup
                            className="m-1"
                            variant="contained"
                            aria-label="split button"
                          >
                            <Tooltip
                              title={
                                m.file_name.includes(".docx") ||
                                m.file_name.includes(".doc")
                                  ? "Please download this file to view"
                                  : "View this file"
                              }
                            >
                              <Button
                                // disabled={
                                //   m.file_name.includes(".docx") ||
                                //   m.file_name.includes(".doc")
                                // }
                                style={
                                  m.file_name.includes(".docx") ||
                                  m.file_name.includes(".doc")
                                    ? {
                                        color: "black",
                                        backgroundColor: "gray",
                                      }
                                    : {}
                                }
                                sx={{
                                  textTransform: "uppercase",
                                  fontSize: "11px",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onHandleReference(m);
                                }}
                              >
                                {utils.truncateFilename(m.file_name)}
                              </Button>
                            </Tooltip>

                            {/* <Tooltip title="Add this file to a folder">
                              <Button
                                id="addToFile"
                                onClick={(e) => {
                                  handleFileEvents(e, m);
                                }}
                              >
                                <CreateNewFolderRoundedIcon />
                              </Button>
                            </Tooltip> */}

                            {
                              <DownloadFile
                                matter_id={props.data.matter_id}
                                file={m}
                                filename={m.file_name}
                              />
                            }
                          </ButtonGroup>
                        </Fragment>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}

              {loading && <Loading message="Loading email content..." />}
              {htmlDisplay !== undefined && htmlDisplay !== null && (
                <HtmlRenderer data={htmlDisplay} />
              )}

              {jsonDisplay !== undefined && jsonDisplay !== null && (
                <div>
                  {typeof jsonDisplay === "object" ? (
                    <JsonRenderer data={jsonDisplay} />
                  ) : (
                    <TextFormatter text={jsonDisplay} />
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

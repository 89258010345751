import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";
import utils from "../utils/utils";

export const getNotesByContext = async (contextId: string) => {
  const url = endpoints.notes.getNotesByContext.replace(
    "{context_id}",
    contextId
  );
  return axiosInstance.get(url);
};

export const createNote = async (data: any) => {
  const requestData = {
    context_id: data.context_id,
    context_type: data.context_type,
    note_desc: data.note,
    owner_id: authService.getUserId(),
    modified_date: utils.getISODateString(),
  };
  const url = endpoints.notes.createNote;
  return axiosInstance.post(url, requestData);
};

export const deleteNote = async (noteId: number) => {
  const url = endpoints.notes.deleteNote.replace(
    "{note_id}",
    noteId.toString()
  );
  return axiosInstance.delete(url);
};

export const updateNote = async (data: any) => {
  const requestData = {
    context_id: data.context_id,
    context_type: data.context_type,
    note_desc: data.note_desc,
    owner_id: authService.getUserId(),
    modified_date: utils.getISODateString(),
    id: data.id,
  };
  const url = endpoints.notes.createNote;
  return axiosInstance.put(url, requestData);
};

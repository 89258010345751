class Constants {
  HTTP_STATUS = {
    SUCCESS: 200,
  };
  PROMPT_ROLES = {
    USER: "user",
    ASSISTANCE: "assistant",
    SYSTEM: "system",
    CREATE_PROMPT: "Create Prompt",
    UPDATE_PROMPT: "Update Prompt",
  };

  FileCabinet = {
    ADD: "add",
    ROOT: "root",
    RENAME: "rename",
    FOLDER: "Folder",
  };
}

export default new Constants();

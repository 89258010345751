import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertFromRaw,
  ContentState,
  convertToRaw,
} from "draft-js";

const AdvanceTextarea = ({ value, setValue }) => {
  const [key, setKey] = useState(0);
  const [editorState, setEditorState] = useState(
    value
      ? EditorState.createWithContent(convertFromRaw(value))
      : EditorState.createEmpty()
  );

  useEffect(() => {
    // Whenever the value changes, update the key to force remount
    if (value === null) setEditorState(EditorState.createEmpty());
    value
      ? EditorState.createWithContent(convertFromRaw(value))
      : EditorState.createEmpty();
  }, [value]);


  return (
    <div
      className="m-2 p-2"
      style={{ minHeight: "400px", border: "1px solid #dcdcdc" }}
    >
      <Editor
        key={key}
        wrapperClassName="wrapper"
        editorClassName="editor"
        toolbarClassName="toolbar"
        editorState={editorState}
        onEditorStateChange={(editorState) => {
          setEditorState(editorState);
          setValue(convertToRaw(editorState.getCurrentContent()));
        }}
        toolbar={{
          options: ["inline", "list", "textAlign", "history"],
          list: {
            options: ["unordered", "ordered"],
          },
        }}
      />
    </div>
  );
};

export default AdvanceTextarea;

import { AxiosResponse } from "axios";
import axiosInstance from "../axiosInstance";
import { endpoints } from "../configs/endpoints";
import { ITags } from "../types/types";

class Tags {
  createTag = (tags: ITags) => {
    const requestData = {
      tag_name: tags.tag_name,
      tag_type: "",
      file_name: tags.file_name,
      modified_date: tags.modified_date,
      owner_id: tags.owner_id,
    };
    const headers = {
      Accept: "application/json",
    };
    const url = endpoints.tags.createTag;

    return axiosInstance.post(url, requestData);
  };

  getTags = async () => {
    const url = endpoints.tags.getTags;
    const response: AxiosResponse<ITags[]> = await axiosInstance.get(url);
    return response;
  };

  getDistinctTags = async () => {
    const url = endpoints.tags.getDistinctTags;
    const response: AxiosResponse<ITags[]> = await axiosInstance.get(url);
    return response;
  };

  getTagsByUser = async () => {};

  getTagsByFilename = async (filename: string) => {
    const url = endpoints.tags.getTagsByFile.replace("{filename}", filename);
    const response: AxiosResponse<ITags[]> = await axiosInstance.get(url);
    return response;
  };
}
export default new Tags();

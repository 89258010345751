type IAlertProps = {
  message: string;
  isError?: boolean;
  isDisplay?: boolean;
};
const Alert = (props: IAlertProps) => {
  let cssx =
    "p-3 bg-opacity-10 border border-start-0 border-end-0 mb-3 rounded-0";
  let css = props.isError
    ? `${cssx} bg-danger border-danger`
    : `${cssx} bg-success border-success`;

  const layout = <div className={css}>{props.message}</div>;

  return layout;
};
export default Alert;

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import authService from "../../services/auth.service";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Constants from "../../services/utils/Constants";
import Typography from "@mui/material/Typography";
import {
  getReleaseNotes,
  deleteReleaseNote,
} from "../../services/releasenotes/releaseNotes.service";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Box } from "@mui/material";
import "./releaseNotes.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ReleaseNotesList = () => {
  const navigate = useNavigate();

  const [releaseNotes, setReleaseNotes] = useState([]);
  const user_id = authService.getUserId();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  useEffect(() => {
    getReleaseNote();
  }, []);
  const getReleaseNote = async () => {
    setLoading(true);
    const ReleaseNotesResponse = await getReleaseNotes();
    if (ReleaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      const temp = [...ReleaseNotesResponse.data];
      temp.sort((a, b) => {
        return new Date(b.modified_date) - new Date(a.modified_date);
      });
      setReleaseNotes([...temp]);
    }

    setLoading(false);
  };

  const transformedData = releaseNotes.map((item, index) => ({
    id: index + 1,
    version: item?.version,
    date: item?.date,
    title: item.title,
  }));

  const columns = [
    { field: "id", headerName: "No.", width: 120, sortable: false },
    { field: "version", headerName: "Version", width: 150, sortable: false },
    { field: "date", headerName: "Date", width: 150, sortable: false },
    {
      field: "title",
      headerName: "Title",
      width: 400,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>{params.value}</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <IconButton
              onClick={() => handleEdit(params)}
              // color="primary"
              aria-label="edit"
              sx={{ justifySelf: "flex-end" }}
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={() => handleDelete(params)}
              // color="primary"
              // aria-label="edit"
              // sx={{ justifySelf: "flex-end" }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ),
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);
  const handleCloseDialog = () => {
    setSelectedRow(null);
  };

  const handleRowClick = (params) => {
    //
    //     setOpen(true);
    // setSelectedRow(params.row);
    // const _route = "/releasenotes/add";
  };

  const handleDelete = async (params) => {
    setDeleteId(releaseNotes[params.row.id - 1].id);
    setDialogOpen(true);
  };
  const handleDialogClose = async (confirmed) => {
    if (confirmed) {
      //
      const releaseNotesResponse = await deleteReleaseNote(deleteId);
      if (releaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        getReleaseNote();
        //
      }
    }
    setDialogOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEdit = (params) => {
    const _route = "/releasenotes/add";
    navigate(_route, {
      state: { releaseNoteId: releaseNotes[params.row.id - 1].id },
    });
  };

  const handleCreate = () => {
    const _route = "/releasenotes/create";
    navigate(_route);
  };
  return (
    <>
      {loading && (
        <Box display="flex" justifyContent="center" marginTop="20px">
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <div style={{ height: 400, width: "100%" }} className="container">
          {!loading && (
            <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
              <DialogTitle>Release will be deleted</DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => handleDialogClose(false)}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button onClick={() => handleDialogClose(true)} color="primary">
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <DataGrid
            rows={transformedData}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            onRowClick={handleRowClick}
            disableSelectionOnClick
            disableMultipleSelection
            className="custom-datagrid"
          />

          <Button
            variant="contained"
            sx={{ display: "flex", marginTop: "10px" }}
            onClick={handleCreate}
          >
            Create Release
          </Button>

          {selectedRow !== null && (
            <React.Fragment>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{
                  style: {
                    minHeight: "50%", // Adjust the value as needed
                    minWidth: "50%", // Adjust the value as needed
                  },
                }}
              >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                  {selectedRow &&
                    Object.keys(selectedRow).map(
                      (key) =>
                        key === "version" && (
                          <div key={key} style={{ color: "black" }}>
                            {`Version ${selectedRow[key]}`}
                          </div>
                        )
                    )}
                </DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <Typography gutterBottom variant="h6">
                    New Features:
                  </Typography>

                  <div key={releaseNotes[selectedRow.id - 1]}>
                    {/* <h3>{note.version}</h3> */}
                    <div className="px-1 mb-2 border-top border-bottom">
                      {releaseNotes[selectedRow.id - 1]?.date} (
                      {releaseNotes[selectedRow.id - 1]?.version})
                    </div>
                    <ul>
                      {releaseNotes[selectedRow.id - 1].features.map(
                        (feature, i) => (
                          <li key={i}>
                            <Typography
                              gutterBottom
                              mb={2}
                              sx={{ display: "flex" }}
                            >
                              <TaskAltIcon
                                fontSize="small"
                                color="success"
                                className="mx-2"
                              />
                              {`${feature?.feature_type}: ${feature?.feature}`}
                            </Typography>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClose}>
                    Close
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            </React.Fragment>
          )}
        </div>
      )}
    </>
  );
};
const ReleaseNotes = () => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {/* <h2 style={{ marginBottom: '20px' }}>Feedbacks</h2> */}
      <h2
        style={{
          marginBottom: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          color: "black",
        }}
      >
        Release Notes
      </h2>

      <ReleaseNotesList />
    </div>
  );
};

export default ReleaseNotes;

import { Home } from "@mui/icons-material";
import Logout from "@mui/icons-material/Logout";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/auth.service";

type Profile = {
  initial?: string;
  name?: string;
  email?: string;
};

export default function AccountMenu(props?: Profile) {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const user = authService.getUserInfo();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    document.addEventListener("mousedown", handleClickOutside);
  };

  const handleRoute = (route: string, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    const _route = `/${route}`;
    navigate(_route);
  };

  const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
    localStorage.clear();
    navigate("/");
  };

  const handleClickOutside = (event) => {
    setAnchorEl(null);
    document.removeEventListener("mousedown", handleClickOutside);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        <Tooltip title="">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <div className="fw text-white fw-bold mx-3">
              Hi {user?.full_name}
            </div>
            <Avatar className="bg-info small" sx={{ width: 32, height: 32 }}>
              {props?.initial}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        ref={menuRef}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Divider />
        <MenuItem onClick={(e) => handleRoute("file", e)}>
          <ListItemIcon>
            <Home fontSize="small" />
          </ListItemIcon>
          Goto Home
        </MenuItem>
        {authService.isAdmin() && (
          <MenuItem onClick={(e) => handleRoute("add", e)}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add team member
          </MenuItem>
        )}
        {/* <MenuItem onClick={(e) => handleRoute("projects", e)}>
          <ListItemIcon>
            <PostAddIcon fontSize="small" />
          </ListItemIcon>
          Manage projects
        </MenuItem>
        <MenuItem onClick={(e) => handleRoute("prompts", e)}>
          <ListItemIcon>
            <DesignServicesIcon fontSize="small" />
          </ListItemIcon>
          Manage prompts
        </MenuItem>
        <MenuItem onClick={(e) => handleRoute("file", e)}>
          <ListItemIcon>
            <ChatIcon fontSize="small" />
          </ListItemIcon>
          Chat with Documents
        </MenuItem> */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import projectService from "services/project/project.service";
import Constants from "services/utils/Constants";
import "./signupForm.css";
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            borderRadius: 10,
            border: "2px solid white",
            "&:hover": {
              border: "2px solid #38bdf8",
            },
            "&.Mui-focused": {
              border: "2px solid #0284c7",
            },
            "& fieldset": {
              border: "none",
            },
          },
        },
      },
    },
  },
});

export default function AutoCompleteField({ setSignUpData }) {
  const [projects, setProjects] = useState([]);
  const [matters, setMatters] = useState([]);
  const [mattersOption, setMattersOption] = useState([]);
  const getMatters = async () => {
    const projectsResponse = await projectService.getMattersByUserId();

    if (projectsResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      setProjects(projectsResponse.data);

      setMattersOption(
        projectsResponse.data.map((project) => ({
          title: project.project_name,
          id: project.id,
        }))
      );
    }
  };

  useEffect(() => {
    getMatters();
  }, []);

  const [projectIds, setProjectIds] = useState([]);

  return (
    <>
      {(projects || projects.length !== 0) && (
        <ThemeProvider theme={theme}>
          <Autocomplete
            multiple
            id="tags-outlined"
            value={matters}
            options={mattersOption}
            getOptionLabel={(option) => option.title}
            filterSelectedOptions
            onChange={(event, newValue) => {
              setProjectIds(newValue.map((value) => value.id));

              setMatters(newValue);
              setSignUpData((existingValues) => ({
                ...existingValues,
                project_ids: newValue.map((value) => value.id),
              }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Matters"
                variant="standard"
                classes={{ root: "custom-textfield" }}
              />
            )}
          />
        </ThemeProvider>
      )}
    </>
  );
}

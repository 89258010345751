import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";

class AIServices {
  previewPrompt = async (
    prompt_str: string,
    input_text: string,
    model_type: string
  ) => {
    const requestData = {
      prompt_str: prompt_str,
      input_text: input_text,
      model_type: model_type,
    };
    const headers = {
      Accept: "application/json",
    };
    const url = endpoints.ai.preview;

    return axiosInstance.post(url, requestData);
  };

  applyPromptBatchx = async (
    owner_id: number,
    item_ids: any,
    view_id: number,
    aiModelType: string = "llama"
  ) => {
    const requestData = {
      owner_id: owner_id,
      item_ids: item_ids,
      view_id: view_id,
      aiModelType: aiModelType,
    };

    const url = endpoints.ai.applyPromptBatchx;
    return axiosInstance.post(url, requestData);
  };

  applyPromptBatch = async (
    owner_id: number,
    item_ids: any,
    view_id: number
  ) => {
    const requestData = {
      owner_id: owner_id,
      item_ids: item_ids,
      view_id: view_id,
    };

    const url = endpoints.ai.applyPromptBatch;
    return axiosInstance.post(url, requestData);
  };

  applyPrompt = async (
    prompt_id: number,
    viewTableNameId: number,
    view_id: number,
    input_text: string
  ) => {
    const requestData = {
      prompt_id: prompt_id.toString(),
      table: viewTableNameId.toString(),
      view_id: view_id.toString(),
      input_text: input_text,
    };

    const url = endpoints.ai.applyPromptById;
    return axiosInstance.post(url, requestData);
  };

  //getFileResponse
  getFileResponse = async (matter_id: number, file_name: string) => {
    try {
      let url = endpoints.ai.getFileResponse
        .replace("{matter_id}", matter_id.toString())
        .replace("{file_name}", file_name);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      return axiosInstance.get(url, config);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  getEmailAttachments = async (fileName: string, matterId: string) => {
    try {
      let url = endpoints.ai.getEmailAttachments
        .replace("{file_name}", fileName.toString())
        .replace("{matter_id}", matterId);

      return axiosInstance.post(url);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  refreshEmailContent = async (matter_id: number, file_name: string) => {
    try {
      let url = endpoints.ai.getRefreshedEmailContent
        .replace("{matter_id}", matter_id.toString())
        .replace("{file_name}", file_name);

      const response = await axiosInstance.get(url, {
        headers: {
          accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  getHtmlOrJson = async (matter_id: number, file_name: string) => {
    try {
      let url = endpoints.ai.getHtmlOrJson
        .replace("{matter_id}", matter_id.toString())
        .replace("{file_name}", file_name);

      const response = await axiosInstance.get(url, {
        headers: {
          accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  getDocumentContent = async (documentId: number) => {
    try {
      let url = endpoints.ai.getFileContent.replace(
        "{document_id}",
        documentId.toString()
      );

      return axiosInstance.get(url);
    } catch (error) {
      console.log(documentId);
      console.error("An error occurred:", error);
    }
  };

  getReferenceFile = async (
    fileName: string,
    owner_id: string,
    matterId: string
  ) => {
    try {
      let url = endpoints.ai.v2.getReferenceFile
        .replace("{file_name}", fileName.toString())
        .replace("{owner_id}", owner_id)
        .replace("{matter_id}", matterId);

      return axiosInstance.get(url);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  getSummary = async (matter_id, file_name) => {
    try {
      let url = endpoints.ai.getSummary
        .replace("{file_name}", file_name.toString())
        .replace("{matter_id}", matter_id);

      return axiosInstance.post(url);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
}
export default new AIServices();

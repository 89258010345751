import SignupForm from "./SignupForm";

const Signup = () => {
  const layout = (
    <>
      <div className="container-fluid bg d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <SignupForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return layout;
};

export default Signup;

import TextField from "@mui/material/TextField/TextField";
import React, { useState } from "react";
import validator from "validator";

const CreateProject = ({ nextStep, handleFormData, values }: any) => {
  const [error, setError] = useState(false);

  const submitProject = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (
      validator.isEmpty(values.firstName) ||
      validator.isEmpty(values.lastName)
    ) {
      //setError(true);
    } else {
      nextStep();
    }
  };

  return (
    <>
      <div className="form-floating mb-2">
        <TextField
          required
          sx={{ m: 1, width: "50ch" }}
          id="txtProjectName"
          label="Matter Name"
          variant="filled"
          placeholder="Enter matter name"
          fullWidth
          onChange={handleFormData("projectName")}
          defaultValue={values.projectName}
        />
      </div>
      <div className="form-floating mb-5">
        <TextField
          required
          sx={{ m: 1, width: "50ch" }}
          id="txtProjectDescription"
          label="Description"
          variant="filled"
          placeholder="Enter description of the project"
          fullWidth
          multiline
          rows={4}
          onChange={handleFormData("description")}
          defaultValue={values.description}
        />
      </div>
      {/* <div className="form-floating mb-5">
        <div className="d-flex flex-row mb-3">
          <input
            type="file"
            id="choosefile"
            className="form-control"
            onChange={handleFormData("files")}
            multiple
          />

          <Button onClick={submitProject} className="mx-3" variant="contained">
            Upload
          </Button>
        </div>
      </div> */}
    </>
  );
};

export default CreateProject;

import React, { useEffect, useState } from "react";
import FeedbackComponent from "../../components/Feedback/FeedbackComponent";
import Constants from "../../services/utils/Constants";
import { getFeedbacksByUserId } from "../../services/feedback/feedback.service";
import authService from "../../services/auth.service";
import { CircularProgress, Box } from "@mui/material";

import ModuleLayout from "../../components/ModuleLayout";

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const user_id = authService.getUserId();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getFeedbacks = async () => {
      setLoading(true);
      const FeedbacksResponse = await getFeedbacksByUserId(user_id);
      if (FeedbacksResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        const temp = [...FeedbacksResponse.data];
        temp.sort((a, b) => {
          return new Date(b.modified_date) - new Date(a.modified_date);
        });
        setFeedbacks([...temp]);
      }

      setLoading(false);
    };

    getFeedbacks();
  }, []);

  return (
    <>
      <ModuleLayout>
        {!loading && <FeedbackComponent feedbacks={feedbacks} />}
        {loading && (
          <Box display="flex" justifyContent="center" marginTop="20px">
            <CircularProgress />
          </Box>
        )}
      </ModuleLayout>
    </>
  );
};

export default Feedback;

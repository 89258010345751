import MicOffRoundedIcon from "@mui/icons-material/MicOffRounded";
import MicRoundedIcon from "@mui/icons-material/MicRounded";
import IconButton from "@mui/material/IconButton";
import { Fragment, useEffect, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useDebounce from "../hooks/useDebounce";

const VoiceToText = ({ onQueryChange, voiceInputState, onExecute,setVoiceInputState }) => {
  const [textToCopy, setTextToCopy] = useState();
  const [isVoiceStarted, setIsVoiceStarted] = useState(false);

  useEffect(() => {
    if (isVoiceStarted && voiceInputState) {
      resetTranscript();
      startListening();
      setIsVoiceStarted(true);
    } else if (isVoiceStarted && !voiceInputState) {
      SpeechRecognition.stopListening();
    }
  }, [voiceInputState]);

  const startListening = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-IN",
    });
  };

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const [debouncedTranscript] = useDebounce(transcript, 3000);

  useEffect(() => {
    SpeechRecognition.stopListening();
  }, []);

  useEffect(() => {
    if (!transcript) return;

    //onExecute(transcript);
    //resetTranscript();
  }, [debouncedTranscript]);

  useEffect(() => {
    // if (!transcript) return;
    onQueryChange(transcript);
  }, [transcript, isVoiceStarted]);

  if (!browserSupportsSpeechRecognition) {
    return null;
  }

  return (
    <Fragment>
      <div>
        {/* <div className="main-content" onClick={() => setTextToCopy(transcript)}>
          {transcript}
        </div> */}

        <div className="btn-style">
          {(!isVoiceStarted || !voiceInputState) && (
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="Enalble voice input"
              onClick={() => {
                startListening();
                setIsVoiceStarted(true);
                setVoiceInputState(true);
              }}
            >
              <MicOffRoundedIcon />
            </IconButton>
          )}

          {isVoiceStarted && voiceInputState && (
            <IconButton
              onClick={() => {
                SpeechRecognition.stopListening();
                setIsVoiceStarted(false);
                setVoiceInputState(false);
              }}
            >
              <MicRoundedIcon />
            </IconButton>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default VoiceToText;

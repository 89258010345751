import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import * as React from "react";

type NewFolderMenuProps = {
  onMenuItemClick: (event: any, option: string) => void;
  parentFolder: string;
};

const NewFolderMenu = ({
  onMenuItemClick,
  parentFolder,
}: NewFolderMenuProps) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="contained" {...bindTrigger(popupState)}>
            New Folder
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={(event) => {
                popupState.close();
                onMenuItemClick(event, "root");
              }}
            >
              New Root Folder
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                popupState.close();
                onMenuItemClick(event, "add");
              }}
            >
              {/* New Folder ({parentFolder}) */}
              New Folder
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default NewFolderMenu;

import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import React, { Fragment, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { IFileCabinetReference, SourceNode } from "./types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box/Box";
import Drawer from "@mui/material/Drawer/Drawer";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import TextField from "@mui/material/TextField/TextField";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import FileDisplayCard from "../../controls/FileDisplayCard";
import authService from "../../services/auth.service";
import { addToFolders } from "../../services/filingcabinet/filingcabinet.service";
import Constants from "../../services/utils/Constants";
import utils from "../../services/utils/utils";
import DownloadFile from "../DownloadFile";
import ToastMessage, { IToast } from "../ToastMessage";
import FoldersTree from "../filecabinet/folderstree/FoldersTree";
type Props = {
  nodes?: any;
  label?: string;
  response?: any;
  setFileReferenceData?: any;
  selectedMatterId?: any;
  dynamicDivRef?: any;
  mouseUp: any;
  qa?: any;
};

const retrievers = ["topk", "tree_summarize", "fusion"];

const FileReference = ({
  response,
  selectedMatterId,
  dynamicDivRef,
  mouseUp,
  qa,
}) => {
  const agentNames = ["Top Docs", "Expansive", "Multi-Q"];
  const [drawerState, setDrawerState] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState("");
  const [selectedFileItem, setSelectedFileItem] = useState();
  const [fileReferenceData, setFileReferenceData] = useState<any>();
  const [filenameToCabinet, setFilenameToCabinet] = useState("");
  const [filenameToCabinetfirst, setFilenameToCabinetfirst] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [fileReference, setFileReference] = useState<IFileCabinetReference>();
  const [folderViewState, setFolderViewState] = useState(false);
  const [selectedFolderIds, setSelectedFolderIds] = useState([]);
  const [toast, setToast] = useState<IToast>({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });

  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };

  const [open, setOpen] = React.useState(false);

  const showToast = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (event?.reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const extractFilename = (
    inputString: string,
    delimiter: string = "<|>"
  ): string | null => {
    const parts = inputString.split(delimiter);
    return parts.length > 1 ? parts[1].trim() : parts[0].trim();
  };

  const toggleDrawer =
    (openState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setDrawerState(openState);
    };

  const toggleFolderView =
    (openState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setFolderViewState(openState);
    };

  const handleAddFileReference = (item: any) => {
    //

    const fname = extractFilename(item.file_name.trim());

    if (!fname) return;
    var ext = splitFilename(fname);
    setFilenameToCabinetfirst(ext.mainName);
    setFileExtension(ext.extension);
    setFilenameToCabinet(fname);
    setFileReference({
      referenceType: "File",
      displayName: utils.truncateFilename(fname),
      filename: utils.truncateFilename(fname),
      originalPath: fname,
    });

    setFolderViewState(true);
  };

  const handleOtherDocuments = async (filename: string, item: any) => {
    if (
      filename?.includes(".pdf") ||
      filename?.includes(".json") ||
      filename?.includes(".txt")
    ) {
      const owner_id = authService.getUserId();
      toggleDrawer(true);
      setDrawerState(true);
    } else if (filename?.includes(".docx") || filename?.includes(".doc")) {
      //
      toggleDrawer(true);
      setDrawerState(true);
    }
  };

  const splitFilename = (filename) => {
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex !== -1) {
      const mainName = filename.substring(0, lastDotIndex);
      const extension = filename.substring(lastDotIndex + 1);
      return { mainName, extension };
    } else {
      return { mainName: filename, extension: "" };
    }
  };
  const onHandleReference = async (item: any) => {
    if (open) return;

    const filename = extractFilename(item?.file_name?.trim());

    if (!filename) return;

    const data = {};

    setSelectedFilename(filename);
    setSelectedFileItem(item);
    setFileReferenceData([item, response]);

    if (filename?.includes(".txt")) {
      // handleTxtFile(filename, item);
      handleOtherDocuments(filename, item);
    } else {
      handleOtherDocuments(filename, item);
    }
  };
  const handleAddToFolder = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    try {
      const response = await addToFolders(
        selectedMatterId,
        fileReference,
        selectedFolderIds
      );

      if (response?.status == Constants.HTTP_STATUS.SUCCESS) {
        showToast();
      } else {
        setToast({
          ...toast,
          openState: true,
          title: "Error",
          message: "Unable to create folder.",
          onCloseToast: onCloseToast,
          messageType: "bg-danger text-white",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };

  const findAllNodesByFileName = (
    sourceNodes: SourceNode[],
    fileName: string
  ): string[] => {
    const matchingIndices: string[] = [];

    // Iterate through the sourceNodes array
    for (let i = 0; i < sourceNodes.length; i++) {
      // Check if the current node's metadata has the desired file_name
      if (
        sourceNodes[i].node.metadata &&
        sourceNodes[i].node.metadata.file_name === fileName
      ) {
        // Add the index of the node to the matchingIndices array as a string
        matchingIndices.push((i + 1).toString());
      }
    }
    // Return the array of matching node indices
    return matchingIndices;
  };

  const prepareFilenames = (response: any) => {
    const delimiter = "<|>";
    const fileNames: any = [];
    //
    if (response?.metadata) {
      //
      Object.values(response?.metadata).forEach((item: any) => {
        const modifiedFileName = item.file_name?.replace("load_data/", "");

        const foundNodeIndices = findAllNodesByFileName(
          response.source_nodes,
          modifiedFileName
        );

        // Concatenate foundNodeIndices with modifiedFileName
        // const concatenatedResults = foundNodeIndices.map(index => {
        //   return `[${index}], ${modifiedFileName}`;
        // }).join(',\n ');
        //
        const concatenatedIndexes = foundNodeIndices
          .map((index) => `[${index}]`)
          .join(", ");

        let concatenatedResults = modifiedFileName;
        if (concatenatedIndexes.length > 0) {
          concatenatedResults = `${concatenatedIndexes} ${delimiter} ${modifiedFileName}`;
        }

        // Check if the modifiedFileName is not already in the array
        // if (!fileNames.includes(modifiedFileName)) {
        //   fileNames.push(modifiedFileName);
        // }
        const fileExists = fileNames.some(
          (item) => item.file_name === concatenatedResults
        );

        if (!fileExists) {
          fileNames.push({
            file_name: concatenatedResults,
            metadata: item,
          });
        }
      });
    }
    return fileNames;
  };

  const handleSelectedFolder = async (selectedFolderIds, selectedFolders) => {
    setSelectedFolderIds(selectedFolderIds);
  };

  const handleFileEvents = async (event: any, file) => {
    if (!event) return;
    if (event.currentTarget.id === "addToFile") {
      handleAddFileReference(file ? file : selectedFileItem);
    }
  };
  //
  //

  // const checkFileStatus = (filename: string) => {
  //   let color: null | string = "info";
  //   fileStatus.map((file) => {
  //     if (filename.includes(file.file_name) && file.file_name !== "") {
  //       console.log(file);
  //       if (file.status === "read") {
  //         color = "success";
  //       }
  //       if (file.status === "filed") {
  //         color = "warning";
  //       }
  //     }
  //   });
  //   return color;
  // };
  const handleChange = (event) => {
    const value = event.target.value;
    const newFilename = value + "." + fileExtension;
    setFilenameToCabinetfirst(value);
    setFilenameToCabinet(newFilename);
    setFileReference((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          filename: newFilename,
        };
      }
      return undefined;
    });

    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      appendUUID(uuid);
    }
    return undefined;
  };

  const appendUUID = (u: string = "") => {
    const url = window.location.href;
    const uuid = u ? u : uuidv4();

    let newUrl;
    if (url.includes("?uuid=")) {
      newUrl = url.replace(/(?<=\?uuid=)[^&]*/, uuid);
    } else {
      newUrl = url.concat(`?uuid=${uuid}`);
    }

    window.history.pushState(null, "", newUrl);
    localStorage.setItem("uuid", uuid);
  };

  //append uuid to url to maintain state
  const handleAccordion = (event: any) => {
    appendUUID();
  };

  return (
    <Fragment>
      {retrievers.map((retriever: any, inx) => {
        return (
          qa[retriever]?.source_nodes?.length > 0 && (
            <Accordion key={inx} className="m-1">
              <AccordionSummary
                onClick={(e) => handleAccordion(e)}
                expandIcon={<ExpandMoreIcon className="print-hide" />}
                aria-controls="ai-reference-content"
                id="ai-references"
              >
                {agentNames[inx]} References
              </AccordionSummary>
              <AccordionDetails>
                {prepareFilenames(qa[retriever]).map((m, inx) => {
                  //console.log(checkFileStatus(m.file_name));
                  return (
                    <Fragment key={inx}>
                      <ButtonGroup
                        className="m-1"
                        variant="contained"
                        aria-label="split button"
                      >
                        <Tooltip title="Click to view this file">
                          <Button
                            sx={{
                              textTransform: "uppercase",
                              fontSize: "11px",
                              // backgroundColor: checkFileStatus(m.file_name),
                            }}
                            // color={checkFileStatus(m.file_name)}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onHandleReference(m);
                            }}
                          >
                            {utils.truncateFilename(m.file_name)}
                          </Button>
                        </Tooltip>
                        {
                          <DownloadFile
                            matter_id={selectedMatterId}
                            file={m}
                            filename={m.file_name}
                            // color={checkFileStatus(m.file_name)}
                          />
                        }
                        <Tooltip title="Add this file to a folder">
                          <Button
                            //  color={checkFileStatus(m.file_name)}
                            onClick={() => {
                              handleAddFileReference(m);
                            }}
                          >
                            <CreateNewFolderRoundedIcon />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </Fragment>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          )
        );
      })}
      <ToastMessage {...toast} />
      <Drawer
        anchor="right"
        open={folderViewState}
        onClose={toggleFolderView(false)}
      >
        <Box className="vh-100" sx={{ width: 800 }} role="presentation">
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Successfully added to folder.
            </Alert>
          </Snackbar>
          <Box className="p-5" role="presentation">
            <div className="mb-3">
              <div className="form-floating mb-2 d-flex">
                <TextField
                  className="w-75 me-2"
                  required
                  label="Reference name"
                  variant="filled"
                  placeholder="Enter reference name to identify (Must be at least 5 characters)."
                  fullWidth
                  autoFocus
                  value={filenameToCabinetfirst}
                  onChange={handleChange}
                  error={
                    filenameToCabinetfirst.length >= 0 &&
                    filenameToCabinetfirst.length < 5
                  }
                  helperText={
                    filenameToCabinetfirst.length >= 0 &&
                    filenameToCabinetfirst.length < 5
                      ? "Minimum characters required are 5"
                      : ""
                  }
                />
                <TextField
                  className="w-25"
                  required
                  label="File extension"
                  variant="filled"
                  placeholder="Enter file extension."
                  fullWidth
                  value={"." + fileExtension}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>

              {/* <span className="fw-semibold">
                {filenameToCabinet}
                <br />
              </span> */}
              <small className="text-muted">
                To enable the Button "Add to Folders" {">"} Enter reference name
                and select folder to save
              </small>
            </div>

            {/* <NotesForm /> */}

            <FoldersTree
              matter_id={selectedMatterId}
              onSelection={handleSelectedFolder}
            />
            <Button
              onClick={handleAddToFolder}
              variant="contained"
              className="mt-5"
              disabled={filenameToCabinet.length == 0}
            >
              Add to Folder(s)
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Drawer anchor="right" open={drawerState} onClose={toggleDrawer(false)}>
        {/* {selectedMatterId ? (
            <NotesForm matter_id={selectedMatterId} context_type="chat" />
          ) : null} */}
        {/* Add a button to open folder view drawer    */}

        <div className="vh-100">
          <Box className="vh-100" sx={{ width: 800 }} role="presentation">
            {/* <div className="sticky-top">
                <TagsInput tagSource={selectedFilename} />
              </div> */}

            <div className="p-4">
              <FileDisplayCard
                title={selectedFilename}
                fileReferenceData={fileReferenceData}
                selectedMatterId={selectedMatterId}
                handleFileEvents={handleFileEvents}
                passingRef={dynamicDivRef}
                mouseUp={mouseUp}
                qa={qa}
                extractFilename={extractFilename}
                handleOtherDocuments={handleOtherDocuments}
              />
            </div>
          </Box>
        </div>
      </Drawer>
    </Fragment>
  );
};
export default FileReference;

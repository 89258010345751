import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import { endpoints } from "../configs/endpoints";

export const initChat = async (data) => {
  const requestData = {
    matter_id: data.matter_id,
    name: data.name,
    user_id: data.user_id,
  };
  const url = endpoints.chat.init;
  return axiosInstance.post(url, requestData);
};

export const getMessages = async (conversation_id) => {
  const url = endpoints.chat.get_messages.replace(
    "{conversation_id}",
    conversation_id.toString()
  );
  return axiosInstance.get(url);
};

export const renameChat = async (conversation_id, name) => {
  const url = endpoints.chat.rename.replace(
    "{conversation_id}",
    conversation_id.toString()
  );
  const nameUrl = url.replace("{name}", name);

  return axiosInstance.put(nameUrl);
};

export const restartChat = async (conversation_id) => {
  const data = {
    id: conversation_id,
  };
  const url = endpoints.chat.restart;
  return axiosInstance.post(url, data);
};

export const getNames = async (matter_id) => {
  const user_id = authService.getUserId();
  const url = endpoints.chat.get_names;
  return axiosInstance.get(url, { params: { user_id, matter_id } });
};

import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import React, { Fragment, useState } from "react";
import projectService from "../../services/project/project.service";
import Constants from "../../services/utils/Constants";
interface IProjectCard {
  data: any;
  handleClick: any;
  trigger: any;
  getMatters: any;
  iancative?: boolean;
}

const DashboardCard = ({
  data,
  handleClick,
  trigger,
  getMatters,
  inactive,
}) => {
  const statusEnum = {
    0: (
      <Button size="small" color="primary" disabled>
        File Not uplaoded
      </Button>
    ),
    1:
      // <Button size="small" color="primary" onClick={() => trigger(data)}>
      //   Start Process
      // </Button>
      null,
    2: null,
    // <Button
    //   onClick={() => handleClick("progress", data)}
    //   size="small"
    //   color="primary"
    // >
    //   Matter Creation
    // </Button>
    3: null,
    // <Button
    //   onClick={() => handleClick("progress", data)}
    //   size="small"
    //   color="primary"
    // >
    //   Matter Creation
    // </Button>,
    4: null,
    // <Button
    //   onClick={() => handleClick("progress", data)}
    //   size="small"
    //   color="primary"
    // >
    //   Matter Creation
    // </Button>,
    5: null,
    // <Button
    //   onClick={() => handleClick("progress", data)}
    //   size="small"
    //   color="primary"
    // >
    //   Matter Creation
    // </Button>,
    6: (
      <>
        <Button
          size="small"
          color="primary"
          onClick={() => handleClick("chat", data)}
        >
          Chat
        </Button>
        <Button
          onClick={() => handleClick("file", data)}
          size="small"
          color="secondary"
        >
          File Cabinet
        </Button>
        {/* <Button
          onClick={() => handleClick("progress", data)}
          size="small"
          color="primary"
        >
          Matter Creation
        </Button> */}
      </>
    ),
    8: (
      <Fragment>
        <Button
          size="small"
          color="primary"
          onClick={() => handleClick("chat", data)}
        >
          Chat
        </Button>
        <Button
          onClick={() => handleClick("file", data)}
          size="small"
          color="secondary"
        >
          File Cabinet
        </Button>
      </Fragment>
    ),
    7: (
      <>
        <Button
          size="small"
          color="primary"
          onClick={() => handleClick("chat", data)}
        >
          Chat
        </Button>
        <Button
          onClick={() => handleClick("file", data)}
          size="small"
          color="secondary"
        >
          File Cabinet
        </Button>
        {/* <Button
          onClick={() => handleClick("progress", data)}
          size="small"
          color="primary"
        >
          Matter Creation
        </Button> */}
      </>
    ),
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [toggleLoading, setToggleLoading] = useState<boolean>(false);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleToggle = async () => {
    setToggleLoading(true);
    const res = await projectService.toggleActive(data.id);
    if (res.status == Constants.HTTP_STATUS.SUCCESS) {
      setToggleLoading(false);
      getMatters();
    }
  };
  return (
    <Fragment>
      <Card sx={{ minHeight: "230px" }}>
        <CardHeader
          action={
            <IconButton
              onClick={handleMenu}
              style={{ marginLeft: "auto" }}
              aria-label="settings"
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={<p style={{ fontSize: "18px" }}>{data?.project_name}</p>}
        />
        <CardContent>
          <p className="card-text">{data?.project_desc}</p>
        </CardContent>
        {inactive ? null : (
          <CardActions className="mt-auto" disableSpacing>
            {statusEnum[data?.status_id] ? statusEnum[data?.status_id] : null}
          </CardActions>
        )}
      </Card>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disabled={toggleLoading} onClick={handleToggle}>
          Make it {inactive ? "active" : "inactive"}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default DashboardCard;

import React, { Fragment } from "react";
import FileCabinet from "./FileCabinet";
import { useLocation } from "react-router-dom";
const FileCabinetLayout = () => {
  const location = useLocation();
  return (
    <Fragment>
      <FileCabinet data={location.state} />
    </Fragment>
  );
};

export default FileCabinetLayout;

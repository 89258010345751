import React, { useState } from "react";
import "../css/footer.css";
import Feedback from "../../Feedback/Feedback"
const Footer = () => {
  const [open, setOnClose]= useState(true);
  return (
    <div></div>
    // <footer className="footer">
    //   <div className="press">
    //     Copyright © 2023 RelevAInce, Inc. All rights reserved.{" "}
    //   </div>
    // </footer>
  );
};

export default Footer;

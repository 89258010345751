import axiosInstance from "../axiosInstance";
import authService from "../auth.service";
import endpoints from "../configs/endpoints";
import constants from "../utils/Constants";
import utils from "../utils/utils";

class Project {
  getDashboard = async (projectId: string) => {
    const userid = authService.getUserId();
    const url = endpoints.dashboard.dashboard.replace(
      "{project_id}",
      projectId
    );
    return axiosInstance.get(url);
  };

  dashboardIns = async (projectId: string, data: string) => {
    try {
      const requestData = {
        project_id: projectId,
        response: data,
      };

      const url = endpoints.dashboard.ins;
      return axiosInstance.post(url, requestData);
    } catch {}
  };

  project = async (project: any) => {
    project.dataTime = utils.getCurrentDateTime();
    const createProjectResponse = await this.createProject(project);
    if (createProjectResponse.status == constants.HTTP_STATUS.SUCCESS) {
      const projectData = createProjectResponse.data;
      project.id = projectData.id;
      this.createIssue(project);
      this.createPeople(project);
      this.uploadDocuments(project);
    }
  };

  createProject = (project: any) => {
    const requestData = {
      project_name: project.projectName,
      project_desc: project.description,
      owner_id: project.owner_id,
      modified_date: project.dataTime,
    };
    const headers = {
      Accept: "application/json",
    };
    const url = endpoints.project.create;

    return axiosInstance.post(url, requestData);
  };

  getMattersByUserId = async () => {
    const userid = authService.getUserId();
    const url = endpoints.project.getMattersUserId.replace("{userid}", userid);
    return axiosInstance.get(url);
  };

  createIssue = (project: any) => {
    const issues = project.issues;
    const url = endpoints.project.issue;
    issues.map((issue: any) => {
      const requestData = {
        issue_name: issue.name,
        issue_desc: issue.description,
        issue_prompt: issue.pattern,
        modified_date: project.dataTime,
        project_id: project.id,
        owner_id: project.owner_id,
      };

      axiosInstance.post(url, requestData);
    });
  };

  createPeople = (project: any) => {
    const requestData = {
      people_name: project.peoples,
      modified_date: project.dataTime,
      project_id: project.id,
      owner_id: project.owner_id,
    };
    const headers = {
      Accept: "application/json",
    };
    const url = endpoints.project.people;

    return axiosInstance.post(url, requestData);
  };

  createDocuments = (project: any) => {
    const documents: any[] | undefined = [];
    const files = project.files;

    project.files.map((file: any) => {
      const requestData = {
        file_name: file.name,
        file_desc: "",
        file_location: "",
        file_content: "",
        modified_date: project.dataTime,
        project_id: 2,
        owner_id: 1,
      };

      documents.push(requestData);
    });

    const headers = {
      Accept: "application/json",
    };
    const url = endpoints.project.documents;

    return axiosInstance.post(url, documents);
  };

  uploadDocuments = (project: any) => {
    project.files.map((file: any) => {
      setTimeout(() => {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        let data = new FormData();
        data.append("files", file);
        axios
          .post(
            endpoints.project.uploadDocument
              .replace("{userid}", project.owner_id)
              .replace("{project_id}", project.id),
            data,
            config
          )
          .then((res) => {})
          .catch((error) => {})
          .finally(() => {});
      }, 5000);
    });
  };

  getDocumentByFilename = (filename: string) => {
    const url = endpoints.project.getDocumentByFilename.replace(
      "{file_name}",
      filename
    );
    return axiosInstance.get(url);
  };

  toggleActive = (projectId: string) => {
    const url = endpoints.project.toggle.replace("{project_id}", projectId);
    return axiosInstance.put(url);
  };

  inactiveProjects = () => {
    const url = endpoints.project.getInactiveProjects.replace(
      "{user_id}",
      authService.getUserId()
    );
    return axiosInstance.get(url);
  };
}
export default new Project();

import Textarea from "@mui/joy/Textarea";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import * as React from "react";

type SubqueryProps = {
  query: string;
  handleQueryEvents: any;
  setSelectedText: React.Dispatch<React.SetStateAction<string>>;
};

const SubqueryPopover = ({
  query,
  handleQueryEvents,
  setSelectedText,
}: SubqueryProps) => {
  const [popoverText, setPopoverText] = React.useState("");
  const [changed, setChanged] = React.useState(false);
  const handleClick = (e) => {
    // if (changed) {
    //   setSelectedText(popoverText);
    // } else {
    //   setSelectedText(query);
    // }
    const finalQuery = changed ? popoverText : query;
    handleQueryEvents(e, finalQuery);
  };
  // React.useEffect(() => {
  //   if (changed) {
  //     setSelectedText(popoverText);
  //   } else {
  //     setSelectedText(query);
  //   }
  // }, [changed, popoverText, query, setSelectedText]);
  const card = (
    <React.Fragment>
      <CardContent className="p-2">
        <Textarea
          sx={{ width: "400px", minHeight: "200px" }}
          className="rounded-1"
          defaultValue={`Summarize: ${query}`}
          onChange={(e) => {
            setChanged(true);
            setPopoverText(e.target.value);
          }}
        />
      </CardContent>
      <hr className="p-0 m-0" />
      <CardActions className="px-3 d-flex justify-content-between">
        <Button
          id="btnSummarize"
          size="small"
          className="ml-auto"
          onClick={handleClick}
        >
          Send to Chat
        </Button>
      </CardActions>
    </React.Fragment>
  );

  return (
    <Box>
      <Card variant="outlined" sx={{ minWidth: 275 }}>
        {card}
      </Card>
    </Box>
  );
};

export default SubqueryPopover;

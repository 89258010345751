import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/auth.service";

import AutoCompleteField from "./autoCompleteField";
import "./signupForm.css";

let signUpFormData = {
  fname: "",
  lname: "",
  full_name: "",
  email: "",
  password: "",
  project_ids: [],
};

type route = {
  path?: string;
};

const SignupForm = (props: route) => {
  const navigate = useNavigate();

  const [isAddFlow, setIsAddFlow] = useState(false);
  const [signUpData, setSignUpData] = useState(signUpFormData);
  const [displayAlert, setDisplayAlert] = useState({
    message: "",
    isError: false,
  });

  useEffect(() => {
    setIsAddFlow(props?.path === "add");
  }, [displayAlert, isAddFlow]);

  const registrationSubmitHandler = async (event: {
    preventDefault: () => void;
  }) => {
    event.preventDefault();

    if (
      signUpData.email == "" ||
      signUpData.fname == "" ||
      signUpData.lname == "" ||
      signUpData.password == ""
    ) {
      setDisplayAlert((existingValues: any) => ({
        ...existingValues,
        message: "Please fill all the information.",
        isError: true,
      }));
      return;
    }

    authService.signUp(signUpData).then(
      (res: any) => {
        let message = `Thank you ${res.full_name}.`;

        setDisplayAlert((existingValues: any) => ({
          ...existingValues,
          message: message,
          isError: false,
        }));

        setSignUpData({
          fname: "",
          lname: "",
          full_name: "",
          email: "",
          password: "",
          project_ids: [],
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setDisplayAlert((existingValues: any) => ({
          ...existingValues,
          message: `Hello ${signUpData.full_name}, currently we are unable to register, will contact you soon.`,
          isError: true,
        }));

        console.error("Error on login", resMessage);
      }
    );
  };

  const routeToSignIn = (event: any) => {
    navigate("/");
  };

  const handleFnameHandler = (event: any) => {
    event.preventDefault();
    setSignUpData((existingValues: any) => ({
      ...existingValues,
      fname: event.target.value,
    }));
  };

  const handleLnameHandler = (event: any) => {
    event.preventDefault();
    setSignUpData((existingValues) => ({
      ...existingValues,
      lname: event.target.value,
      full_name: `${existingValues.fname} ${event.target.value}`,
    }));
  };

  const handleEmailHandler = (event: any) => {
    event.preventDefault();
    setSignUpData((existingValues) => ({
      ...existingValues,
      email: event.target.value,
    }));
  };

  const handlePasswordHandler = (event: any) => {
    event.preventDefault();
    setSignUpData((existingValues: any) => ({
      ...existingValues,
      password: event.target.value,
    }));
  };

  return (
    <Fragment>
      <div className="m-5">
        <Card className="mb-3 col-md-6">
          <CardContent className="p-0">
            <form onSubmit={registrationSubmitHandler}>
              <div className="p-5">
                <Typography
                  variant="h6"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    letterSpacing: 1,
                  }}
                  component="div"
                >
                  Create User
                </Typography>
                <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                  <TextField
                    required
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    helperText="Please enter your first name."
                    placeholder="Enter your first name"
                    //errorText="First name is required."
                    variant="standard"
                    fullWidth
                    value={signUpData.fname}
                    onChange={handleFnameHandler}
                  />

                  <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    helperText="Please enter your last name."
                    placeholder="Enter your last name"
                    //errorText="Last name is required."
                    variant="standard"
                    fullWidth
                    value={signUpData.lname}
                    onChange={handleLnameHandler}
                  />
                </Stack>

                <TextField
                  required
                  id="useremail"
                  name="useremail"
                  label="Email"
                  helperText="Please enter your email address."
                  placeholder="Enter your email address"
                  //errorText="Email is required."
                  variant="standard"
                  value={signUpData.email}
                  onChange={handleEmailHandler}
                  sx={{ mb: 4 }}
                  fullWidth
                />
                <TextField
                  required
                  name="userpassword"
                  label="Password"
                  type="password"
                  helperText="Please enter your password."
                  placeholder="Enter your password"
                  //errorText="Password is required."
                  variant="standard"
                  sx={{ mb: 4 }}
                  value={signUpData.password}
                  onChange={handlePasswordHandler}
                  fullWidth
                />
                <AutoCompleteField setSignUpData={setSignUpData} />
              </div>
              <hr className="m-0" />
              <CardActions className="p-0">
                <Button
                  className="mx-5 my-3"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Create User
                </Button>
              </CardActions>
            </form>
          </CardContent>
        </Card>
      </div>
    </Fragment>
  );
};

export default SignupForm;

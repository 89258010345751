import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import authService from "services/auth.service";

type Issue = {
  owner_id: string;
  name: string;
  description: string;
  pattern: string;
  uploaded_date: string;
  model_type: string;
};

type IssuePrompt = {
  role: string;
  content: string;
};

const CreateIssues = ({ nextStep, prevStep, handleFormData, values }: any) => {
  const moduleConstants = {
    USER: "user",
    ASSISTANCE: "assistant",
    SYSTEM: "system",
    CREATE_ISSUE: "Create Issue",
    UPDATE_ISSUE: "Update Issue",
  };
  const pattern = /^[A-Za-z0-9]+$/;
  const [aiModel, setAIModel] = useState("llama");
  const [issueData, setIssueData] = useState<Issue[]>([]);

  const systemPrompt = {
    role: moduleConstants.SYSTEM,
    content:
      "You are an expert legal assistant. Follow the directions to the best of your ability.",
  };

  const userPrompt = {
    role: moduleConstants.USER,
    content: "",
  };
  const inputPrompt = [systemPrompt, userPrompt];
  const [inputList, setInputList] = useState(inputPrompt);

  const initialIssue = {
    name: "",
    description: "",
    system: "",
    user: "",
    model_type: aiModel,
  };

  const [formData, setFormData] = useState(initialIssue);

  const handleInput = (e: any) => {
    const input = e.target.value;
    let { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const preparePrompt = (formData: any) => {
    const systemPrompt: IssuePrompt = {
      role: moduleConstants.SYSTEM,
      content: formData.system,
    };

    const userPrompt: IssuePrompt = {
      role: moduleConstants.USER,
      content: formData.user,
    };

    return [systemPrompt, userPrompt];
  };

  const handleAddIssue = async (event: any) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const inputList = preparePrompt(formData);

    const issue: Issue = {
      owner_id: authService.getUserInfo().id,
      name: formData.name,
      description: formData.description,
      pattern: JSON.stringify(inputList),
      uploaded_date: `${year}-${month}-${day}`,
      model_type: formData.model_type,
    };

    issueData.push(issue);

    setIssueData(issueData);
    handleFormData(issueData);

    setFormData(initialIssue);
  };

  useEffect(() => {}, []);

  useEffect(() => {}, [issueData]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-5">
            <div className="form-floating mb-1">
              <TextField
                required
                name="name"
                sx={{ m: 1, width: "50ch" }}
                label="Issue Name"
                variant="filled"
                placeholder="Enter name of the issue"
                fullWidth
                multiline
                minRows={1}
                defaultValue={formData.name}
                value={formData.name}
                onChange={handleInput}
              />
            </div>
            <div className="form-floating mb-1">
              <TextField
                sx={{ m: 1, width: "50ch" }}
                label="Description"
                name="description"
                variant="filled"
                placeholder="Enter description of the project"
                fullWidth
                multiline
                minRows={1}
                defaultValue={formData.description}
                value={formData.description}
                onChange={handleInput}
              />
            </div>
            <div className="form-floating mb-1">
              <TextField
                sx={{ m: 1, width: "50ch" }}
                label="System"
                name="system"
                variant="filled"
                placeholder="Tell me how should I act"
                fullWidth
                multiline
                minRows={1}
                defaultValue={formData.system}
                value={formData.system}
                onChange={handleInput}
              />
            </div>
            <div className="form-floating mb-1">
              <TextField
                sx={{ m: 1, width: "50ch" }}
                label="User"
                name="user"
                variant="filled"
                placeholder="Tell about your input"
                fullWidth
                multiline
                minRows={1}
                defaultValue={formData.user}
                value={formData.user}
                onChange={handleInput}
              />
            </div>

            {/* {inputList.length > 0
              ? inputList.map((input, inx) => (
                  <div key={inx}>
                    <div className="form-floating mb-1">
                      <TextField
                        sx={{ m: 1, width: "50ch" }}
                        label={input.role}
                        variant="filled"
                        placeholder="Enter issue details"
                        fullWidth
                        multiline
                        minRows={1}
                        onChange={(event) => handleInputChange(event, inx)}
                        value={input.content}
                      />
                    </div>
                  </div>
                ))
              : "No item in the list "} */}
          </div>
          <div className="col-6">
            <strong>Issues</strong>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Issue name</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {issueData.map((issue) => (
                    <TableRow
                      key={issue.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {issue.name}
                      </TableCell>
                      <TableCell>{issue.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="col-10">
            <Button
              onClick={handleAddIssue}
              className="add-new-row p-2"
              variant="text"
            >
              Add Issue
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateIssues;

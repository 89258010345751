import {
  Button,
  ButtonGroup,
  SplitButton,
  SplitButtonItemClickEvent,
  Toolbar,
} from "@progress/kendo-react-buttons";
// import {
//   CircularProgress,
//   Box,
//  } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { BaseEvent } from "@progress/kendo-react-common";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  InputChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MattersDropdown from "../../../controls/MattersDropdown";
import {
  toggleSortBtnGroup,
  toggleViewBtnGroup,
} from "../helpers/helperMethods";
import {
  GridViewBtnGroup,
  UploadAddEvent,
} from "../interfaces/FileManagerModels";
import NewFolderMenu from "./NewFolderMenu";

const ProgressBarComponent = ({ progress }) => (
  <Box sx={{ width: "300px", mt: 2 }}>
    {" "}
    {/* Set the desired width here */}
    <LinearProgress variant="determinate" value={progress} />
    <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
      <Typography
        variant="body2"
        color="textSecondary"
      >{`${progress}%`}</Typography>
    </Box>
  </Box>
);
export const FileManagerToolbar = (props: any) => {
  const [dialogVisible, setDialogVisible] = React.useState<boolean>(false);
  const [viewBtnGroup, setViewBtnGroup] = React.useState<GridViewBtnGroup>({
    gridView: true,
    listView: false,
  });
  const { selectedMatter } = props;
  const handleSearchChange = (event: InputChangeEvent) => {
    props.onSearchChange(event);
  };

  const handleSwitchChange = (event: SwitchChangeEvent) => {
    props.onSwitchChange(event);
  };

  const handleGridViewChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      const newBtnGroupState = toggleViewBtnGroup(viewBtnGroup, "grid");
      setViewBtnGroup(newBtnGroupState);

      props.onViewChange({
        viewValue: newBtnGroupState,
        event: event,
      });
    }
  };

  const handleAscBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      const newBtnGroupState = toggleSortBtnGroup(props.sort[0].dir, "asc");
      props.onSortChange({
        direction: "asc",
        sortValue: newBtnGroupState,
        event: event,
      });
    }
  };

  const handleDescSortBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newBtnGroupState = toggleSortBtnGroup(props.sort[0].dir, "desc");
    props.onSortChange({
      direction: "desc",
      sortValue: newBtnGroupState,
      event: event,
    });
  };

  const handleListViewChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      const newBtnGroupState = toggleViewBtnGroup(viewBtnGroup, "list");
      setViewBtnGroup(newBtnGroupState);

      props.onViewChange({
        viewValue: newBtnGroupState,
        event: event,
      });
    }
  };

  const handleItemClick = (event: SplitButtonItemClickEvent) => {
    props.onSortChange(event);
  };

  const handleNewFolderClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    option: string
  ) => {
    props.onNewFolderClick(event, option);
  };

  const handleDialogVisibility = (
    event: BaseEvent<Dialog> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogVisible(!dialogVisible);
  };

  const handleFileChange = (event: UploadAddEvent) => {
    props.onFileChange({
      files: event.newState,
      event: event,
    });
  };

  const handleUploadClearList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onClearFileList(event);
  };

  const handleUploadDone = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setDialogVisible(!dialogVisible);
    props.onUploadComplete(event);
  };

  const handleMatterChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onMatterChange(event);
  };
  const uploadFile = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    props.onUploadComplete(event);
  };

  const theme = useTheme(); 
  const selectedStyle = {
    backgroundColor: theme.palette.primary.main, // primary color
    color: theme.palette.primary.contrastText,   // contrast text color
    borderColor: theme.palette.primary.main,     // primary color for outline
  };
  const history = useNavigate();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  
  const handleSelection = async (event: any) => {
    // console.log(event);
    const projectId = event.id;
    if (!projectId) {
      props.setIsMatterSelected(false);
      return;
    }
    const chatId = params.get("id");
    const mi = params.get("matterid");
    if (props.selectedMatterId != event.id) {
      history(`/folder?matterid=${projectId}`);
    } else if (chatId != null)
      history(`/folder?id=${chatId}&matterid=${projectId}`);
    else history(`/folder?matterid=${projectId}`);
    props.setIsMatterSelected(true);
    props.setSelectedMatter(event);
    props.setSelectedMatterId(event.id);
  };

  return (
    <Toolbar className="k-filemanager-toolbar">
      <MattersDropdown
        selectedMatter={selectedMatter}
        // onSelectMatter={handleMatterChange}
        onSelectMatter= {handleSelection}
        isChatPage={false}
      />
      {/* <Button onClick={handleNewFolderClick}>New Root Folder</Button> */}
      <NewFolderMenu
        onMenuItemClick={handleNewFolderClick}
        parentFolder={props.selectedTreeItem?.path}
      />
      <Button onClick={uploadFile}>Upload</Button>

      {props.isLoading && (
        <Box
          position={"absolute"}
          top={"15%"}
          left={"50%"}
          transform={"translate(-20%, -15%)"}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              Uploading
            </Typography>
            <ProgressBarComponent progress={props.uploadProgress} />
          </Box>
        </Box>
      )}
      {dialogVisible && (
        <Dialog
          title={"Upload Files"}
          className={"k-filemanager-upload-dialog"}
          onClose={handleDialogVisibility}
          contentStyle={{ width: "530px" }}
        >
          <Upload
            batch={false}
            multiple={true}
            files={props.files}
            withCredentials={false}
            onAdd={handleFileChange}
            onRemove={handleFileChange}
            onProgress={handleFileChange}
            onStatusChange={handleFileChange}
            saveUrl={
              "https://demos.telerik.com/kendo-ui/service-v4/upload/save"
            }
            removeUrl={
              "https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
            }
          />
          <DialogActionsBar layout={"end"}>
            <Button onClick={handleUploadClearList}> Clear List</Button>
            <Button themeColor={"primary"} onClick={handleUploadDone}>
              {" "}
              Done{" "}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      <ButtonGroup>
        <Button
          togglable={true}
          icon="k-icon k-i-sort-asc-sm"
          selected={props.sort[0].dir === "asc"}
          onClick={handleAscBtnClick}
          style={props.sort[0].dir === "asc" ? selectedStyle : {}}
        />
        <Button
          togglable={true}
          icon="k-icon k-i-sort-desc-sm"
          selected={props.sort[0].dir === "desc"}
          onClick={handleDescSortBtnClick}
          style={props.sort[0].dir === "desc" ? selectedStyle : {}}
        />
      </ButtonGroup>
      <SplitButton
        text={"Sort By"}
        items={props.splitItems}
        onItemClick={handleItemClick}
      ></SplitButton>
      <ButtonGroup>
        <Button
          togglable={true}
          icon="k-icon k-i-grid-layout"
          selected={viewBtnGroup.gridView}
          onClick={handleGridViewChange}
          style={viewBtnGroup.gridView ? selectedStyle : {}}
        />
        <Button
          togglable={true}
          icon="k-icon k-i-grid"
          selected={viewBtnGroup.listView}
          onClick={handleListViewChange}
          style={viewBtnGroup.listView ? selectedStyle : {}}
        />
      </ButtonGroup>
      <div className="k-spacer">&nbsp;</div>
      {/* <div className="k-filemanager-details-toggle">
        <Label>View Details </Label>
        <Switch defaultChecked={true} onChange={handleSwitchChange} />
      </div> */}
      {/* <div className="k-filemanager-search-tool k-textbox k-toolbar-last-visible">
        <Input
          className="k-input"
          placeholder="Search"
          onChange={handleSearchChange}
        />
        <span className="k-input-icon">
          <span className="k-icon k-i-search"></span>
        </span>
      </div> */}
    </Toolbar>
  );
};

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { Editor } from "react-draft-wysiwyg";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import "./feedbackComponent.css";
const formatDateTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const formattedDate = dateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const formattedTime = dateTime.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return `${formattedDate} ${formattedTime}`;
};

const FeedbackList = ({ feedbacks }) => {
  useEffect(() => {
    //
  });
  const navigate = useNavigate();
  const rows = feedbacks.map((feedback, index) => ({
    id: index+1,
    feedbackStatus: feedback.status,
    feedbackText: `[#${feedback.id}] ${feedback.title}`,
    dateCreated: formatDateTime("2024-03-05T07:30:59.572Z"),
    feedbackType: feedback.type,
    sortid: feedback.id,
  }));

  const columns = [
    { field: "id", headerName: "No.", width: 75, sortable: false },
    // {field: 'feedbackText', headerName: 'Your Feedback', flex: 1, sortable: false},
    {
      field: "feedbackText",
      headerName: "Your Feedback",
      flex: 1,
      sortable: false,
      cellClassName: "feedback-text-cell", // Adding custom class for styling
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 200,
      sortable: false,
    },
    {
      field: "feedbackType",
      headerName: "Feedback Type",
      width: 200,
      sortable: false,
    },
    {
      field: "feedbackStatus",
      headerName: "Status",
      width: 200,
      sortable: false,
    },
    {
      field: "sortid",
      headerName: "Feedback_id",
      width: 200,
      sortable: false, 
    },
  ];

  const [sortModel, setSortModel] = useState([
    {
      field: "sortid",
      sort: "desc",
    },
  ]);
  const columnVisibilityModel = {
    sortid: false, // Hide the sortid column
  };
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCloseDialog = () => {
    setSelectedRow(null);
  };

  const handleRowClick = (params) => {
    const x = feedbacks[params.row.id - 1].id;
    const _route = `/feedbacks/${x}`;
    navigate(_route);
  };

  return (
    <div style={{ height: 400, width: "100%" }} className="container">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        rowNumber
        disableColumnMenu
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        onRowClick={handleRowClick}
         columnVisibilityModel={columnVisibilityModel}
        getRowClassName={(params) =>
          `feedback-row ${
            params.row.id === selectedRow?.id ? "selected-row" : ""
          }`
        }
      />
      {selectedRow !== null && (
        <Dialog open={selectedRow !== null} onClose={handleCloseDialog}>
          <DialogTitle
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={4}
          >
            <span>Feedback</span>
            <IconButton aria-label="close" onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {selectedRow &&
                Object.keys(selectedRow).map(
                  (key) =>
                    key === "feedbackText" && (
                      <div key={key} style={{ color: "black" }}>
                        {selectedRow[key]}
                      </div>
                    )
                )}
            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const FeedbackComponent = ({ feedbacks }) => {
  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {/* <h2 style={{ marginBottom: '20px' }}>Feedbacks</h2> */}
      <h2
        style={{
          marginBottom: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          color: "black",
        }}
      >
        Feedbacks
      </h2>

      <FeedbackList feedbacks={feedbacks} />
    </div>
  );
};

export default FeedbackComponent;

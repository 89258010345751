import React,{useState} from "react";
import DrawerMenuLeft from "./DrawerMenuLeft";
import Feedback from "../../Feedback/Feedback"
const NavigationHeader = () => {
  const [open, setOnClose]= useState(true);
  const htmlLayout = (
    <>
      <DrawerMenuLeft />
       
      <Feedback open={open} onClose={setOnClose} /> 
    </>
  );

  return htmlLayout;
};

export default NavigationHeader;

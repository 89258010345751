import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import Button from "@mui/material/Button/Button";
import AIModel from "controls/AIModel";
import Loading from "controls/Loading";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import aiServices from "services/ai/ai.services";
import authService from "services/auth.service";
import promptService from "services/item/prompt.service";
import { Prompt } from "services/types/types";
import constants from "services/utils/Constants";
import "./prompt.css";

const PromptForm = () => {
  const params = useLocation();

  const moduleConstants = {
    USER: "user",
    ASSISTANCE: "assistant",
    SYSTEM: "system",
    CREATE_PROMPT: "Create Prompt",
    UPDATE_PROMPT: "Update Prompt",
  };
  const pattern = /^[A-Za-z0-9]+$/;

  let userIds: any[] = [];
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  /* const [shareUserIds, setShareUserIds] = useState(userIds); */
  const [moduleName, setModuleName] = useState(moduleConstants.CREATE_PROMPT);
  const [promptName, setPromptName] = useState("");
  const [promptDescription, setPromptDescription] = useState("");
  const [testPromptInput, setTestPromptInput] = useState("");
  const [promptId, setPromptId] = useState(-1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [promptPreview, setPromptPreview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [aiModel, setAIModel] = useState("llama");

  const [open, setOpen] = useState(false);

  const systemPrompt = {
    role: moduleConstants.SYSTEM,
    content:
      "You are an expert legal assistant. Follow the directions to the best of your ability.",
  };

  const userPrompt = {
    role: moduleConstants.USER,
    content: "",
  };
  const inputPrompt = [systemPrompt, userPrompt];

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    input: inputPrompt,
    model_type: aiModel,
  });

  const handleClickOpen = () => {
    setPromptPreview("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [inputList, setInputList] = useState(inputPrompt);

  const onLoad = async () => {
    const id = params.state?.id;
    if (id) {
      setIsEditMode(true);
      setModuleName(moduleConstants.UPDATE_PROMPT);

      await promptService.getPrompts().then((response: any) => {
        const prompts: Prompt[] = response.data;

        const data = prompts.filter((prompt: Prompt) => {
          return prompt.id == id;
        });

        const prompt = data[0];

        setFormData({
          ...formData,
          name: prompt.name,
          description: prompt.description,
          input: JSON.parse(prompt.pattern),
          model_type: prompt.model_type,
        });

        setAIModel(prompt.model_type);

        /* setPromptName(prompt.name);
        setPromptDescription(prompt.description); */
        setPromptId(prompt.id);
        setInputList(JSON.parse(prompt.pattern));
      });
    } else {
      setIsEditMode(false);
      setModuleName(moduleConstants.CREATE_PROMPT);
    }
  };

  useEffect(() => {}, [promptPreview]);

  useEffect(() => {
    onLoad();
  }, []);

  const handleListAdd = () => {
    setInputList([
      ...inputList,
      {
        role:
          inputList[inputList.length - 1].role == moduleConstants.USER
            ? moduleConstants.ASSISTANCE
            : moduleConstants.USER,
        content: "",
      },
    ]);
  };

  const handleRemoveItem = (index: number) => {
    const newList = [...inputList];
    newList.splice(index, 1);
    setInputList(newList);
  };

  const handleInputChange = (event: any, index: number) => {
    const { name, value } = event.target;
    const newInputList = [...inputList];
    newInputList[index].content = value;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInput = (e: any) => {
    const input = e.target.value;
    const { name, value } = e.target;

    if (name == "name" && !pattern.test(input)) return;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (pattern.test(input)) {
    }
  };

  const handlePromptSubmit = async (event: any) => {
    setIsLoading(false);
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const promptData = {
      owner_id: authService.getUserInfo().id,
      name: formData.name,
      description: formData.description,
      pattern: JSON.stringify(inputList),
      uploaded_date: `${year}-${month}-${day}`,
      model_type: formData.model_type,
      example: "",
    };

    try {
      let response: any = {};
      if (isEditMode) {
        response = await promptService.updatePrompt(promptId, promptData);
      } else {
        response = await promptService.createPrompt(promptData);
      }

      if (response.status == constants.HTTP_STATUS.SUCCESS) {
        setIsSuccess(true);
        setFormData({
          ...formData,
          name: "",
          description: "",
          input: inputPrompt,
        });
        setInputList(inputPrompt);
      } else {
        console.error("Error creating/updating prompt:", response);
        setIsError(true);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsError(true);
    }
  };

  const applyPreview = async () => {
    if (testPromptInput.trim().length == 0) return;

    //const inputText = JSON.parse()

    try {
      setIsLoading(true);
      //const promptId = currentPrompt.id;
      const previewResponse = await aiServices.previewPrompt(
        JSON.stringify(inputList),
        testPromptInput,
        aiModel
      );

      if (previewResponse.status == constants.HTTP_STATUS.SUCCESS) {
        setIsLoading(false);
        setPromptPreview(previewResponse.data);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsError(true);
      setIsLoading(false);
    }
  };

  /* const handleSharePrompt = (event: any, value: any) => {
    userIds = value.map((v: any) => v.id);
    setShareUserIds(userIds);
    
  }; */

  const handleRoleClick = (e: any, index: number) => {
    const newList = [...inputList];
    const role = newList[index].role;

    if (role === moduleConstants.SYSTEM) return;

    newList[index].role =
      role == moduleConstants.USER
        ? moduleConstants.ASSISTANCE
        : moduleConstants.USER;
    setInputList(newList);
  };

  const handleAIModelChange = (
    event: React.MouseEvent<HTMLElement>,
    newAIModel: string
  ) => {
    setAIModel(newAIModel);

    setFormData({
      ...formData,
      ["model_type"]: newAIModel,
    });
  };

  return (
    <div className="col container">
      <div className="layout-container">
        <h1> {moduleName} </h1>
        <div className="prompt-container">
          {isSuccess && (
            <Alert variant="filled" color="success" sx={{ marginBottom: 3 }}>
              Prompt created.{" "}
              <Link
                to="/prompts"
                className="text-white text-decoration-underline"
              >
                Manage Prompts
              </Link>
            </Alert>
          )}

          {isError && (
            <Alert variant="filled" color="error" sx={{ marginBottom: 3 }}>
              Unable to create prompt at this moment, please try again later.
            </Alert>
          )}

          <div className="input-group px-3 mb-3">
            <span
              style={{ minWidth: "150px" }}
              className="bg-secondary text-white input-group-text fw-semibold"
            >
              Prompt name
            </span>
            <input
              type="text"
              name="name"
              className="form-control"
              aria-describedby="Enter prompt name"
              onChange={handleInput}
              /* onChange={(event) =>
                  setPromptName(
                    event.target.value.replace(/[^\w\s]/gi, "").replace(/\s/g, "")
                  )
                } */
              value={formData.name}
            />
          </div>
          <div className="input-group px-3 mb-3">
            <span
              style={{ minWidth: "150px" }}
              className="bg-secondary text-white input-group-text fw-semibold"
            >
              Description
            </span>
            <input
              type="text"
              name="description"
              className="form-control"
              aria-describedby="Enter description for prompt"
              value={formData.description}
              //onChange={(event) => setPromptDescription(event.target.value)}
              onChange={handleInput}
            />
          </div>
          {inputList.length > 0
            ? inputList.map((input, inx) => (
                <div key={inx} className="prompt-inputs">
                  <div>
                    <div className="prompt-message">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <Button
                            className="bg-primary text-white input-group-text"
                            /* onClick={(e) => handleRoleClick(e, inx)} */
                            variant="contained"
                            sx={{ width: "150px" }}
                            disabled={input.role == moduleConstants.SYSTEM}
                          >
                            {input.role}
                          </Button>
                        </div>
                        <TextareaAutosize
                          className="form-control"
                          aria-describedby="Enter prompt description"
                          onChange={(event) => handleInputChange(event, inx)}
                          value={input.content}
                          style={{ minHeight: "10px" }}
                        />
                        {/* {
                          <div
                            className="input-group-append"
                            role="button"
                            onClick={() => handleRemoveItem(inx)}
                          >
                            <RemoveCircleIcon />
                          </div>
                        } */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "No item in the list "}

          {/* <div className="prompt-message">
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              multiple
              options={userList}
              getOptionLabel={(option: any) => option.email} //filter
              fullWidth
              onChange={(event, value) => handleSharePrompt(event, value)}
              renderInput={(params) => (
                <TextField {...params} label="Share Prompt" />
              )}
            />
          </div> */}

          {isLoading && (
            <Fragment>
              <Loading message="Please wait we are generating preview." />
            </Fragment>
          )}

          {promptPreview.length !== 0 && (
            <Fragment>
              <div className="mt-5 mb-5">
                <strong>User input</strong>
                <div dangerouslySetInnerHTML={{ __html: testPromptInput }} />
                <hr />
                <strong>AI generated output</strong>
                <div dangerouslySetInnerHTML={{ __html: promptPreview }} />
              </div>
            </Fragment>
          )}

          <div className="d-flex justify-content-between py-3">
            {
              <>
                {/* <Button
                  className="add-new-row p-2"
                  variant="text"
                  onClick={handleListAdd}
                >
                  Add Message
                </Button> */}

                <AIModel
                  defaultValue={aiModel}
                  handleAIModelChange={handleAIModelChange}
                ></AIModel>
              </>
            }

            <div className="d-flex justify-content-between">
              <Button
                className="add-new-row p-2 mx-2"
                variant="contained"
                color="error"
                style={{ width: "200px" }}
                onClick={handleClickOpen}
                disabled={!formData.name}
              >
                Test Prompt
              </Button>

              <Button
                className="add-new-row p-2"
                variant="contained"
                onClick={handlePromptSubmit}
                style={{ width: "200px" }}
                disabled={!formData.name}
              >
                Save Prompt
              </Button>
            </div>
          </div>
          <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Test Prompt</DialogTitle>
            <DialogContent>
              <DialogContentText></DialogContentText>

              <TextField
                autoFocus
                multiline
                maxRows={4}
                margin="dense"
                id="test-input"
                label="Enter input text to validate prompt."
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setTestPromptInput(event.target.value)}
              />

              {isLoading && (
                <Fragment>
                  <Loading message="Please wait we are generating preview." />
                </Fragment>
              )}

              {promptPreview.length !== 0 && (
                <Fragment>
                  <div className="mt-3">
                    <strong>AI generated output</strong>
                    <div dangerouslySetInnerHTML={{ __html: promptPreview }} />
                  </div>
                </Fragment>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  applyPreview();
                  setOpen(false);
                }}
              >
                Test Prompt
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        {/* {isLoading && (
          <Fragment>
            <Loading message="Please wait we are generating preview." />
          </Fragment>
        )}

        {promptPreview.length !== 0 && (
          <Fragment>
            <strong>Preview</strong>
            <div dangerouslySetInnerHTML={{ __html: promptPreview }} />
          </Fragment>
        )} */}
      </div>
      {/* <ItemTable data={items} /> */}
    </div>
  );
};

export default PromptForm;

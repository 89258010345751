import { Add, Delete } from "@mui/icons-material";
import { Alert } from "@mui/material";
import IconButton from "@mui/material/IconButton/IconButton";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import ListSubheader from "@mui/material/ListSubheader/ListSubheader";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import authService from "services/auth.service";
import { endpoints } from "services/configs/endpoints";
import promptService from "services/item/prompt.service";
import { Prompt, UserView } from "services/types/types";
import constants from "services/utils/Constants";

const ProjectForm = () => {
  const moduleConstants = {
    CREATE_PROJECT: "Create Project",
    UPDATE_PROJECT: "Update Project",
  };

  const params = useLocation();
  const promptTable: Prompt[] = [];
  const selectedPrompts: Prompt[] = [];

  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [projectName, setProjectName] = useState("");
  const [viewId, setViewId] = useState(-1);
  const [selectedList, setSelectedList] = useState(selectedPrompts);
  const [prompts, setPrompts] = useState(promptTable);

  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [moduleName, setModuleName] = useState(moduleConstants.CREATE_PROJECT);

  const handleProjectName = (event: any) => {
    setProjectName(event.target.value);
  };
  const deSelectItem = (prompt: Prompt) => {
    const newList = prompts.concat(prompt);
    setPrompts(newList);

    const inx = selectedList.findIndex((i) => {
      return i.id === prompt.id;
    });

    if (inx !== -1) {
      selectedList.splice(inx, 1);
    }
  };

  const selectItem = (prompt: Prompt) => {
    const newList = selectedList.concat(prompt);
    setSelectedList(newList);

    const inx = prompts.findIndex((i) => {
      return i.id === prompt.id;
    });

    if (inx !== -1) {
      prompts.splice(inx, 1);
    }
  };

  const createProject = async () => {
    const id = params.state?.id;

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const selectedPrompt = selectedList.map(({ id, name }) => ({ id, name }));
    //const extractColumn = selectedList.map(({ name }) => ({ name }));

    let extractColumn = selectedList.map((x) => x["name"]);
    let userData = authService.getUserInfo();
    let data = {
      view_name: projectName,
      selected_columns: JSON.stringify(extractColumn),
      selected_prompts: JSON.stringify(selectedPrompt),
      modified_date: `${year}-${month}-${day}`,
      owner_id: userData.id,
    };

    let response: any = {};

    if (isEditMode) {
      response = await promptService.updateView(id, data);
    } else {
      response = await promptService.createView(data);
    }

    if (response.status == constants.HTTP_STATUS.SUCCESS) {
      setDisplaySuccess(true);
      insert_mock(response.data.id);
    } else {
      setDisplayError(true);
    }
  };

  const insert_mock = (view_id: string) => {
    let data = {
      table_name: "view_" + view_id,
    };

    axios
      .get(
        endpoints.prompts.insertmock.replace("{table_name}", data.table_name)
      )
      .then((res: any) => {});
  };

  const loadUserViewData = async (promptsData: Prompt[]) => {
    const id = params.state?.id;
    let response: any = {};
    if (id) {
      setIsEditMode(true);
      setModuleName(moduleConstants.UPDATE_PROJECT);

      response = await promptService.getViews();

      if (response.status == constants.HTTP_STATUS.SUCCESS) {
        const views: UserView[] = response.data;

        const data = views.filter((view: UserView) => {
          return view.id == id;
        });

        const view = data[0];

        const input = JSON.parse(view.selected_prompts);
        const selectedPrompts = promptsData.filter((prompt) =>
          input.includes(prompt.name)
        );

        /* selectedPrompts.map((prompt) => {
          //selectItem(prompt);
          selectedList.push(prompt);
        }); */

        setProjectName(view.view_name);
        setViewId(view.id);
      } else {
        setIsEditMode(false);
        setModuleName(moduleConstants.CREATE_PROJECT);
      }
    }
  };

  const loadPrompts = async () => {
    const response = await promptService.getPrompts();
    if (response.status == constants.HTTP_STATUS.SUCCESS) {
      setPrompts(response.data);
      loadUserViewData(response.data);
    }
  };

  useEffect(() => {
    loadPrompts();
  }, []);

  useEffect(() => {}, [selectedList]);

  return (
    <>
      <div className="col container-fluid">
        <h1> {moduleName} </h1>
        <div className="prompt">
          {displaySuccess && (
            <>
              <Alert variant="filled" color="success" sx={{ marginBottom: 3 }}>
                <div className="d-flex">
                  <div className="float-start">Project created.</div>
                  <div className="float-end">
                    <Link
                      to="/projects"
                      className="text-white text-decoration-underline"
                    >
                      Manage Project
                    </Link>
                  </div>
                </div>
              </Alert>
            </>
          )}
          {displayError && (
            <>
              <Alert variant="filled" color="error" sx={{ marginBottom: 3 }}>
                Unable to create project at this moment, please try again later.
              </Alert>
            </>
          )}

          <div className="form-floating mb-5">
            <input
              type="text"
              className="form-control"
              id="txtPromptName"
              value={projectName}
              placeholder="Prompt name"
              onChange={handleProjectName}
            />
            <label htmlFor="txtPromptName">Enter your project name</label>
          </div>

          <strong> Select prompts to your project </strong>
          <div className="d-flex flex-row p-0">
            <div className="w-50">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 500,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    sx={{ paddingLeft: "0px" }}
                    component="div"
                    id="nested-list-subheader"
                  >
                    Available Prompts
                  </ListSubheader>
                }
              >
                {prompts.map((prompt, index) => (
                  <ListItem
                    sx={{ paddingLeft: "0px" }}
                    key={index}
                    secondaryAction={
                      <IconButton
                        key={index}
                        onClick={() => {
                          selectItem(prompt);
                        }}
                        edge="end"
                        aria-label="Add"
                      >
                        <Add />
                      </IconButton>
                    }
                  >
                    <ListItemText key={prompt.id}>
                      <div className="text-capitalize">{prompt.name}</div>
                      <span className="text-muted">{prompt.description}</span>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
            <div className="w-50">
              <List
                sx={{
                  width: "100%",
                  maxWidth: 500,
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    sx={{ paddingLeft: "0px" }}
                    component="div"
                    id="nested-list-subheader"
                  >
                    Selected Prompts
                  </ListSubheader>
                }
              >
                {selectedList.map((prompt, index) => (
                  <ListItem
                    sx={{ paddingLeft: "0px" }}
                    key={prompt.name}
                    secondaryAction={
                      <IconButton
                        key={index}
                        onClick={() => {
                          deSelectItem(prompt);
                        }}
                        edge="end"
                        aria-label="Add"
                      >
                        <Delete key={index} />
                      </IconButton>
                    }
                  >
                    <ListItemText key={prompt.id}>
                      <div className="text-capitalize">{prompt.name}</div>
                      <span className="text-muted">{prompt.description}</span>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>

          <div className="d-grid w-50 mt-5 pt-3 border-top-1 border-secondary">
            <button
              className="btn btn-primary text-uppercase fw-bold w-50"
              type="submit"
              onClick={createProject}
              disabled={!projectName}
            >
              Create Project
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectForm;

import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import { endpoints } from "../configs/endpoints";

export const sendFeedback = async (data) => {
  const requestData = {
    message_id: data.messageId,
    status: data.status,
    details: data.details,
    more_details: data.moreDetails,
    user_id: authService.getUserId(),
    matter_id: data.matterId,
  };
  const url = endpoints.chat.send_feedback;
  return axiosInstance.post(url, requestData);
};

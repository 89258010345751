import {
  Breadcrumb,
  BreadcrumbLinkKeyDownEvent,
  BreadcrumbLinkMouseEvent,
} from "@progress/kendo-react-layout";
import React from "react";

export const BreadcrumbComponent = (props: any) => {
  const handleItemSelect = (event: BreadcrumbLinkMouseEvent) => {
    if (props.onBreadcrumbSelect) props.onBreadcrumbSelect(event);
  };

  const handleKeyDown = (event: BreadcrumbLinkKeyDownEvent) => {
    if (props.onBreadcrumbSelect) props.onBreadcrumbSelect(event);
  };

  return (
    <Breadcrumb
      data={props.data ? props.data : ""}
      textField={"name"}
      onItemSelect={handleItemSelect}
      onKeyDown={handleKeyDown}
    />
  );
};

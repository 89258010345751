import { format } from "date-fns";
import fileDownload from "js-file-download";
import { endpoints } from "../configs/endpoints";

class Utils {
  getCurrentDateTime = () => {
    const currentDateTime = new Date();
    const formattedDateTime = format(currentDateTime, "MM/dd/yyyy, HH:mm:ss a");

    return formattedDateTime;
  };

  getISODateString = () => {
    const currentDateTime = new Date();
    return currentDateTime.toISOString();
  };

  formatBytes = (bytes: any, decimals: any) => {
    if (bytes === 0) return "0 Bytes";
    if (bytes === undefined) return "";
    var k = 1024,
      dm = decimals <= 0 ? 0 : decimals || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  getDisplayDate = (dateTime: string) => {
    const now: Date = new Date();
    const inputDate: Date = new Date(dateTime);
    const timeDifference: number = now.getTime() - inputDate.getTime();
    const secondsDifference: number = timeDifference / 1000;
    const minutesDifference: number = secondsDifference / 60;
    const hoursDifference: number = minutesDifference / 60;
    const daysDifference: number = hoursDifference / 24;
    const weeksDifference: number = daysDifference / 7;
    const monthsDifference: number = weeksDifference / 4; // Approximate months

    if (secondsDifference < 60) {
      return "Just now";
    } else if (minutesDifference < 60) {
      return `${Math.floor(minutesDifference)} minutes ago`;
    } else if (hoursDifference < 24) {
      return `${Math.floor(hoursDifference)} hours ago`;
    } else if (daysDifference < 7) {
      return `${Math.floor(daysDifference)} days ago`;
    } else if (weeksDifference < 4) {
      return `${Math.floor(weeksDifference)} weeks ago`;
    } else {
      return inputDate.toISOString().split("T")[0]; // Return the date if more than a month ago
    }
  };

  getDateForNotes = (input: string): string | null => {
    try {
      const currentDate = new Date();

      const formattedDateTime = currentDate.toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZone: "UTC", // You can adjust the timeZone according to your requirements
      });

      // Replace '/' and ',' with '_' and remove AM/PM
      const result = formattedDateTime
        .replace(/[\/:,]/g, "_")
        .replace(/\s/g, "");

      return result;
    } catch (error) {
      console.error("Invalid date format");
      return null;
    }
  };
  handlePrint = (divId: string) => {
    const printContents = document.getElementById(divId);
    if (!printContents) {
      console.error(`Element with id '${divId}' not found`);
      return;
    }

    // Clone the element to avoid modifying the original DOM
    const clonedContents = printContents.cloneNode(true) as HTMLElement;

    // Remove elements with the class 'arrow-downward' from the cloned content
    const arrowDownwardElements =
      clonedContents.querySelectorAll(".arrow-downward");
    arrowDownwardElements.forEach((element) => {
      element.remove();
    });

    const printWindow = window.open("", "_blank");
    printWindow?.document.write(
      "<html><head><title>RelevAInce - Your AI Legal Assistant Platform</title>"
    );

    printWindow?.document.write(
      '<link rel="stylesheet" type="text/css" href="your-styles.css">'
    );
    printWindow?.document.write(
      `<style>
        @media print {
          .print-hide { display: none !important; }
        }
      </style>`
    );
    printWindow?.document.write("</head><body>");
    printWindow?.document.write(clonedContents.outerHTML);
    printWindow?.document.write("</body></html>");
    printWindow?.document.close();
    printWindow?.print();
  };

  sanitizeFilename = (
    inputString: string,
    delimiter: string = "<|>"
  ): string | null => {
    const parts = inputString.split(delimiter);
    return parts.length > 1 ? parts[1].trim() : parts[0].trim();
  };

  truncateFilename = (filename: string, max_length = 50) => {
    if (filename.length <= max_length) {
      return filename;
    }

    const splitFilename = filename.split(".");
    const rootName = splitFilename[0];
    const extension = splitFilename.length > 1 ? splitFilename[1] : "";

    const halfMaxLength = Math.floor(max_length / 2);

    return `${rootName.substring(0, halfMaxLength)}...${rootName.substring(
      rootName.length - halfMaxLength,
      rootName.length
    )}.${extension}`;
  };

  downloadFile = async (matter_id: number, filename: string) => {
    async function downloadFile() {
      const parts = filename.split("<|>");
      const file_name = parts[1].trim();

      try {
        const url = `${endpoints.ai.getAttachmentFile
          .replace("{matter_id}", matter_id.toString())
          .replace("{file_name}", file_name)}`;
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.blob();
        fileDownload(data, file_name);
      } catch (error) {
        console.error("An error occurred:", error);
        throw new Error(`HTTP error! status: ${error.message}`);
      }
    }
  };
  highlightTextpdf = (str: string, highlighttext: string[]): string => {
    const words: string[] = str.split(" ");
    const highlightedWords: string[] = words.map((word) => {
      if (
        highlighttext.some((element) => element.includes(word)) &&
        word.length > 7
      ) {
        return `<mark style="background-color: yellow">${word}</mark>`;
      } else {
        return word;
      }
    });
    return highlightedWords.join(" ");
  };
}

export default new Utils();

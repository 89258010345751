import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import { endpoints } from "../configs/endpoints";

export const visionQuery = async (prompt) => {
  const url = endpoints.imagechat.visionquery;

  const response = await axiosInstance.get(url, {
    params: {
      image_query: prompt,
    },
  });
  return response.data;
};

export const visionupload = async (files, defaultPrompt) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }
  const apiUrl = `${
    endpoints.imagechat.visionupload
  }?image_query=${encodeURIComponent(defaultPrompt)}`;
  const response = await axiosInstance.post(apiUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

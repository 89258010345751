import TextField from "@mui/material/TextField/TextField";
import { Fragment } from "react";

const CreatePeoples = ({ nextStep, prevStep, handleFormData, values }: any) => {
  return (
    <Fragment>
      <div className="form-floating mb-5">
        <TextField
          required
          sx={{ m: 1, width: "50ch" }}
          label="Enter peoples who are all involved in projects"
          variant="filled"
          placeholder="Enter people names"
          fullWidth
          multiline
          rows={5}
          onChange={handleFormData("peoples")}
          defaultValue={values.peoples}
        />
      </div>
    </Fragment>
  );
};
export default CreatePeoples;

import React from "react";
import BasicModal from "../../../controls/BasicModal";
import "../css/logo.css";
const Logo = () => {
  return (
    <>
      <div className="logo-container">
        <div className="logo-holder logo">
          <h3>
            {process.env.REACT_APP_TITLE}
            {/* Evident<span className="logo-ai">AI</span> */}
          </h3>
          <span>
            Your AI Legal Assistant Platform
            <BasicModal />
          </span>
        </div>
      </div>
    </>
  );
};

export default Logo;

import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/Login/Login";
import Signup from "../pages/Login/Signup";
import ChatInterface from "../pages/chat/ChatInterface";
import BulkUploadLayout from "../pages/company/BulkUploadLayout";
import CompanyLayout from "../pages/company/CompanyLayout";
import MyProjects from "../pages/company/MyProjects";
import Matter from "../pages/dashboard/Matter";
import FileCabinetLayout from "../pages/filecabinet/FileCabinetLayout";
import ProjectForm from "../pages/projects/ProjectForm";
import ProjectLayout from "../pages/projects/ProjectLayout";
import ManagePrompts from "../pages/prompts/ManagePrompts";
import PromptForm from "../pages/prompts/promptForm";
import TagsLayout from "../pages/tags/TagsLayout";
import Profile from "../pages/user/Profile";
import ProtectedRoutes from "./ProtectedRoutes";
import ImageChat from "../pages/ImageChat/ImageChat";
import SignupForm from "../pages/Login/SignupForm";
import Feedback from "../pages/feedback/Feedback";
import VisionLayout from "../pages/vision/VisionLayout";
import FeedbackSupport from "../pages/feedback/FeedbackSupport";
import MatterCreation from "../pages/Matter_creation/MatterCreation";
import ReleaseNotes from "../pages/ReleaseNotes/ReleaseNotes";
import AddReleaseNotes from "../pages/ReleaseNotes/AddReleaseNote"
import CreateReleaseNote from "../pages/ReleaseNotes/CreateReleaseNote";
import DisplayNotes  from "../pages/ReleaseNotes/DisplayNotes";
const protectedRoutes = [
  { path: "file", element: <Matter /> },
  { path: "add", element: <SignupForm /> },
  { path: "profile", element: <Profile /> },
  { path: "prompts/create", element: <PromptForm /> },
  { path: "prompts/create/:id", element: <PromptForm /> },
  { path: "prompts/", element: <ManagePrompts /> },
  { path: "projects", element: <ProjectLayout /> },
  { path: "myprojects", element: <MyProjects /> },
  { path: "projects/create", element: <ProjectForm /> },
  { path: "projects/create/:id", element: <ProjectForm /> },
  { path: "aichat/", element: <ChatInterface /> },
  { path: "aichat/:matterid", element: <ChatInterface /> },
  { path: "aichat/:id", element: <ChatInterface/> },
  { path: "company", element: <CompanyLayout /> },
  { path: "tags", element: <TagsLayout /> },
  { path: "bulk", element: <BulkUploadLayout /> },
  { path: "folder", element: <FileCabinetLayout /> },
  { path: "feedbacks", element: <Feedback /> },
  { path: "vision", element: <VisionLayout /> },
  { path: "imagechat", element: <ImageChat /> },
  { path: "feedbacks/:id", element: <FeedbackSupport /> },
  { path: "matter-creation/:id", element: <MatterCreation /> },
  { path: "releasenotes", element: <ReleaseNotes /> }, 
  { path: "releasenotes/add", element: <AddReleaseNotes /> },
  {path: "releasenotes/create", element: <CreateReleaseNote/>},
  {path: "releasenotes/display", element: <DisplayNotes/>},
  
];

const AppRoutes = () => {
  return (
    <Routes>
      <Route key={0} path="/" element={<LoginPage />} />
      <Route key={1} path="/signup" element={<Signup />} />

      <Route element={<ProtectedRoutes />}>
        {protectedRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={`/${route.path}`}
              element={route.element}
            />
          );
        })}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
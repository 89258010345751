import React, { useState } from "react";
import { Grid, TextField, MenuItem, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Constants from "../../services/utils/Constants";
import ToastMessage from "../../components/ToastMessage";
import { createReleaseNote } from "../../services/releasenotes/releaseNotes.service";

function CreateReleaseNote() {
  const [version, setVersion] = useState("");
  const [title, setTitle] = useState("");
  const [features, setFeatures] = useState([{ feature_type: "", feature: "" }]);
  const [toast, setToast] = useState({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });
  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    const releaseNoteResponse = await createReleaseNote({
      version: version,
      title: title,
      features: features,
    });
    if (releaseNoteResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      setVersion("");
      setTitle("");
      setFeatures([{ feature_type: "", feature: "" }]);
      setToast((toast) => {
        return {
          ...toast,
          openState: true,
          title: "Success",
          message: "Release Created",
          onCloseToast: onCloseToast,
          messageType: "bg-primary text-white",
        };
      });

      //
    }
  };

  const handleVersionChange = (event) => {
    setVersion(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleOptionChange = (index, event) => {
    const newFeatures = [...features];
    newFeatures[index].feature_type = event.target.value;
    setFeatures(newFeatures);
  };

  const handleDescriptionChange = (index, event) => {
    const newFeatures = [...features];
    newFeatures[index].feature = event.target.value;
    setFeatures(newFeatures);
  };

  const handleAddFeature = () => {
    setFeatures([...features, { feature_type: "", feature: "" }]);
  };

  return (
    <div
      className="container"
      style={{ alignItems: "center", marginTop: "2%" }}
    >
      <ToastMessage {...toast} />
      <Typography
        gutterBottom
        variant="h5"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        Create a Release
      </Typography>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "2%", backgroundColor: "white", padding: "1%" }}
      >
        {/* Version and Title */}
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Version"
              variant="outlined"
              fullWidth
              value={version}
              onChange={handleVersionChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="h6" style={{}}>
              Features
            </Typography>
          </Grid>

          {features.map((feature, index) => (
            <Grid key={index} item xs={10} container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  select
                  label="Select"
                  fullWidth
                  variant="outlined"
                  value={feature.feature_type}
                  onChange={(event) => handleOptionChange(index, event)}
                >
                  <MenuItem value="Bug Fix">Bug Fix</MenuItem>
                  <MenuItem value="Feature Request">Feature Request</MenuItem>
                  <MenuItem value="Enhancement">Enhancement</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={2}
                  value={feature.feature}
                  onChange={(event) => handleDescriptionChange(index, event)}
                />
              </Grid>
            </Grid>
          ))}

          <Grid item xs={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddFeature}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "23%",
              }}
            ></Button>
          </Grid>
        </Grid>
        {/* Create Button */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            Create
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default CreateReleaseNote;

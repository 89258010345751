import AddToDriveRoundedIcon from "@mui/icons-material/AddToDriveRounded";
import AdjustIcon from "@mui/icons-material/Adjust";
import DirectionsIcon from "@mui/icons-material/Directions";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Input,
  InputBase,
  LinearProgress,
  Paper,
  Popover,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useMatters } from "../../context/MattersProvider";

import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import AIModel from "../../controls/AIModel";
import AIOpenChat from "../../controls/AIModels_openChat";
import MattersDropdown from "../../controls/MattersDropdown";
import VoiceToText from "../../controls/VoiceToText";
import indexService from "../../services/chat/index.service";
import {
  FilingCabinetData,
  addChat,
  addChatToFilingCabinet,
} from "../../services/filingcabinet/filingcabinet.service";
import Constants from "../../services/utils/Constants";
import utils from "../../services/utils/utils";
import ToastMessage, { IToast } from "../ToastMessage";
import ChatDrawer from "../filecabinet/components/ChatDrawer";
import NotesForm from "../notes/NotesForm";
import FileReference from "./FileReference";
import History from "./History";
//import QueryInput from "./QueryInput";
import authService from "../../services/auth.service";
import { sendFeedback } from "../../services/chat/chat.feedback.service";
import endpoints from "../../services/configs/endpoints";
import projectService from "../../services/project/project.service";
import SubqueryPopover from "./SubqueryPopover";
import "./chat.css";
type outputData = {
  key: string;
  question: string;
  answer: string;
  filenames?: string[];
  response?: string[];
};

type LoadingRows = {
  [key: string]: boolean;
};
const IndexQuery = ({ data }) => {
  const location = useLocation();
  const OPEN_CHAT = -11;
  const { matter, setMatter } = useMatters();
  const info = location.state;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const chatId = params.get("id"); // bar
  const mi = params.get("matterid");
  const [selectedMatterId, setSelectedMatterId] = useState(
    mi || (info?.project ? info.project.id : null)
  );
  const [selectedMatter, setSelectedMatter] = useState(info?.project || null);

  const [isLoading, setIsLoading] = useState(false);
  const [isMatterSelected, setIsMatterSelected] = useState(true);
  const [airesponse, setAIResponse] = useState<any>([]);
  const [queryText, setQueryText] = useState("");
  const [queryRawText, setQueryRawText] = useState("");
  const [qaData, setQaData] = useState([]);
  const [loadingRows, setLoadingRows] = useState<LoadingRows>({});
  const scrollableContainerRef = useRef(null);
  const userInputRef = useRef<HTMLInputElement | null>(null);
  const [aiModel, setAIModel] = useState("gpt3");
  const [aiModelValue, setAIModelValue] = useState("3.5");
  const matterContext = "";
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const [chatDrawerData, setChatDrawerData] = useState("");
  const [voiceInputState, setVoiceInputState] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chatFileReferenceName, setChatFileReferenceName] = useState("");
  const navigate = useNavigate();
  const [toast, setToast] = useState<IToast>({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });
  const uniqueFileNames = () => {
    return qaData.map((qa, index) => {
      let fusionFileNames = [];
      let topkFileNames = [];
      let treeSummarizeFileNames = [];
      if (qa?.fusion?.source_nodes) {
        fusionFileNames = qa.fusion.source_nodes
          .map((nodes) => {
            return nodes.node.metadata?.file_name || null;
          })
          .filter(Boolean);
      }

      if (qa?.topk?.source_nodes) {
        topkFileNames = qa.topk.source_nodes
          .map((nodes) => {
            return nodes.node.metadata?.file_name || null;
          })
          .filter(Boolean);
      }

      if (qa?.tree_summarize?.source_nodes) {
        treeSummarizeFileNames = qa.tree_summarize.source_nodes
          .map((nodes) => {
            return nodes.node.metadata?.file_name || null;
          })
          .filter(Boolean);
      }

      const files = [
        ...fusionFileNames,
        ...topkFileNames,
        ...treeSummarizeFileNames,
      ];
      return [...new Set(files)];
    });
  };
  const [fileStatus, setFileStatus] = useState<
    {
      owner_id: number;
      file_name: string;
      status: string;
      matter_id: any;
      id?: number;
    }[]
  >([]);
  const readStatus = () => {
    const url = endpoints.ai.v2.getFileStatus
      .replace("{matter_id}", selectedMatterId.toString())
      .replace("{owner_id}", authService.getUserId().toString());

    const response = fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(uniqueFileNames()[0]),
    });
    response.then((res) => {
      res.json().then((data) => {
        let defaultStatus = [];
        if (data.length === 0) {
          //@ts-ignore
          defaultStatus = uniqueFileNames()[0].map((file: string) => {
            return {
              owner_id: authService.getUserId().toString(),
              file_name: file.toString(),
              status: "unread",
              matter_id: selectedMatterId,
            };
          });
        } else {
          defaultStatus = data;
        }
        setFileStatus(defaultStatus);
      });
    });
  };
  useEffect(() => {
    if (uniqueFileNames[0]?.length > 0) {
      readStatus();
    }
  }, [uniqueFileNames]);
  const [queryModels, setQueryModels] = useState([
    "gpt",
    "mistral",
    "llama",
    "gemini",
  ]);

  const [isVisible, setIsVisible] = useState(false); // Initially visible

  const open = Boolean(anchorEl);

  const [openSlider, setOpenSlider] = useState(false);
  const [sliderValue, setSliderValue] = useState(10);

  const buttonRef = useRef(null);
  const handleClickOpenSlider = () => {
    setOpenSlider(true);
  };
  const handleCloseSlider = () => {
    setOpenSlider(false);
  };
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleInputChangeSlider = (event) => {
    setSliderValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (sliderValue < 3) {
      setSliderValue(3);
    } else if (sliderValue > 20) {
      setSliderValue(20);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useNavigate();
  if (info?.project?.id === selectedMatterId) {
    history(`/aichat?matterid=${selectedMatterId}`);
  }
  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [qaData]);
  const handleChatResponse = (chat: any) => {
    if (chat) {
      const answer = chat.response.response;
      const conversationId = chat.conversation_id;
      if (chatId === null && conversationId) {
        history(`/aichat?id=${conversationId}`);
      }

      setAIResponse(chat);
      setQueryText("");
      setQueryRawText("");
      setVoiceInputState(true);
      userInputRef.current?.focus();
      setSelectedText(null);
    }
  };
  const getFirstMatter = () => {
    return new Promise((resolve, reject) => {
      projectService
        .getMattersByUserId()
        .then((projectsResponse) => {
          if (projectsResponse.status === Constants.HTTP_STATUS.SUCCESS) {
            const firstProject = projectsResponse.data[0]; // Get the first project
            resolve(firstProject);
          } else {
            reject(new Error("Failed to fetch matters"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Call the function and log its output
  if (selectedMatterId == null) {
    getFirstMatter()
      .then((firstProject) => {
        setSelectedMatterId(firstProject.id);
        history(`/aichat?matterid=${firstProject.id}`);
      })
      .catch((error) => {
        console.error("Error fetching first project:", error);
      });
  }

  const handleAIModelChange = (
    event: React.MouseEvent<HTMLElement>,
    newAIModel: string
  ) => {
    setAIModel(newAIModel);
    setAIModelValue(event.target?.dataset?.value);
    handleClose();
  };

  const startLoading = (key: string) => {
    setLoadingRows((prevLoadingRows) => ({
      ...prevLoadingRows,
      [key]: true,
    }));
  };

  const stopLoading = (key: string) => {
    setLoadingRows((prevLoadingRows) => ({
      ...prevLoadingRows,
      [key]: false,
    }));
  };

  const addQA = (key: string, question: string, answer: string) => {
    setQaData((prevData) => [
      ...prevData,
      { key: key, question: question, answer: answer },
    ]);
    startLoading(key);
  };

  const updateQA = ({ key, response, question, type, aiModel = null }) => {
    const fileNames: any = [];

    const ex = response?.topk?.source_nodes?.map((node: any) => ({
      file_name: node?.node?.extra_info?.file_name,
      text: node?.node?.text,
    }));
    // Convert the extracted data to a JSON string
    const extractedDataJson = JSON.stringify(ex, null, 2);

    stopLoading(key);

    if (question) {
      stopLoading(key);
    }

    setQaData((prevData) => {
      const newData = prevData.map((item) => {
        //const res = JSON.parse(response);
        if (item.key === key) {
          if (selectedMatterId == OPEN_CHAT) {
            return {
              ...item,
              response: response,
              answer: response ? response.response : "",
              filenames: fileNames.length > 0 ? fileNames : item.filenames,
            };
          } else {
            const res = JSON.parse(response);
            return {
              ...item,
              // response: { ...item.response, response },
              response: [item?.response ?? item.response, response],
              topk: res?.topk ? res?.topk : item.topk,
              aiModel: aiModel,
              tree_summarize: res?.tree_summarize
                ? res?.tree_summarize
                : item.tree_summarize,
              fusion: res?.fusion ? res?.fusion : item.fusion,
              answer: res ? res.response : "",
              filenames: fileNames.length > 0 ? fileNames : item.filenames,
              messageId: type ? type : null,
            };
          }
        }
        return item;
      });

      return newData;
    });
  };
  const addResponseValue = (newValue: string) => {};

  const executeQuery = async (input?: string, popoverQuestion?: string) => {
    //let voiceInput = input;
    setVoiceInputState(false);
    setQueryRawText(input || ""); // Provide a default value for the input parameter
    setQueryText(input || ""); // Provide a default value for the input parameter

    if (!queryText && !selectedText) return;

    const prefix = "Summarize: ";
    const summarizeQuery = `Summarize: ${
      popoverQuestion?.startsWith(prefix)
        ? popoverQuestion?.substring(prefix.length)
        : popoverQuestion
    }`;
    const question = selectedText ? summarizeQuery : queryText;

    if (selectedMatterId == -1) {
      setIsMatterSelected(false);
      return;
    }

    setIsLoading(true);

    const queryKey = uuidv4();
    //

    addQA(queryKey, question, "");
    //scrollToBottom();

    const projectId = matter?.id ? matter?.id : null;

    if (projectId == OPEN_CHAT) {
      indexService
        .just_chat(queryText, queryModels)
        .then((queryResponse: any) => {
          //

          let data = {};
          if (queryResponse.status == Constants.HTTP_STATUS.SUCCESS) {
            const dataArray = queryResponse.data.response;
            data = { response: dataArray?.join("") };
          } else {
            data = "Unable to get response at this moment.";
          }
          updateQA({
            key: queryKey,
            response: data,
            question: queryText,
            type: null,
          });
        });
    } else {
      let data = "";
      const res = indexService.query_index(
        projectId,
        question,
        aiModel,
        sliderValue,
        matterContext,
        chatId,
        `${selectedMatter["project_name"]}_${utils.getCurrentDateTime()}`
      );

      res?.addEventListener("message", (event) => {
        data = event.data;
        setIsLoading(false);
        setAIResponse(data);
        addResponseValue(data);
        updateQA({
          key: queryKey,
          response: data,
          question: queryText,
          type: null,
        });
      });
      res?.addEventListener("id", (event) => {
        if (chatId === null && event.data) {
          history(`/aichat?id=${event.data}&matterid=${selectedMatterId}`);
        }
      });
      res?.addEventListener("message_id", (event) => {
        const id = event.data;
        updateQA({
          key: queryKey,
          response: null,
          question: null,
          type: id,
        });
      });
      res?.addEventListener("llm_model", (event) => {
        const model = event.data;
        updateQA({
          key: queryKey,
          response: null,
          question: null,
          aiModel: model,
        });
      });
      res.onerror = (event) => {
        res.close();
      };
    }
    setQueryRawText("");
    setQueryText("");
    // setVoiceInputState(true);
    userInputRef.current?.focus();
    setSelectedText(null);

    setSelectionInfo(null);
    setSelectedText(null);
  };

  const handleQuery = async (input?: string) => {
    executeQuery(queryRawText);
  };

  const setQueryInput = (input: string) => {
    setQueryRawText(input);
    if (!input) return;

    setQueryText(input);
  };

  const handleVoiceInput = async (voiceInput: string) => {
    //
    if (!voiceInput) return;

    setQueryRawText(voiceInput);
    const clearText = voiceInput;
    setQueryText(voiceInput);
    setQueryRawText(voiceInput);

    if (selectedMatterId == -1) {
      setIsMatterSelected(false);
      return;
    }

    setIsLoading(true);
    addQA(clearText, voiceInput, "");
    //scrollToBottom();

    const projectId = matter?.id ? matter?.id : null;

    const queryResponse: any = await indexService.query_index(
      projectId,
      clearText,
      aiModel,
      sliderValue,
      matterContext,
      projectId,
      `${selectedMatter["project_name"]}_${utils.getCurrentDateTime()}`
    );

    let data = "";
    if (queryResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      data = queryResponse.data;
    } else {
      data = "Unable to get response at this moment.";
    }

    setIsLoading(false);
    setAIResponse(data);
    addResponseValue(data);
    updateQA({ key: null, response: data, question: clearText, type: null });
    //scrollToBottom();
    setQueryRawText("");
    setQueryText("");
    userInputRef.current?.focus();
  };

  const handlePopoverEvents = (event: any, query) => {
    event.stopPropagation();
    if (event.target.id === "btnSummarize") {
      executeQuery(null, query);
    }
  };

  const handleChange = (event: any) => {
    //
    setQueryInput(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handleQuery(queryRawText);
    }
  };

  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };

  const handleChatAction = (event: any, selectedChat: any) => {
    event.preventDefault();

    setOpenChatDrawer(!openChatDrawer);

    setChatDrawerData(selectedChat);
  };

  const [fileReferenceData, setFileReferenceData] = useState<any>();

  const handleSelection = async (event: any) => {
    const projectId = event.id;
    if (!projectId) {
      setIsMatterSelected(false);
      return;
    }
    const chatId = params.get("id");
    const mi = params.get("matterid");
    if (selectedMatterId != event.id) {
      history(`/aichat?matterid=${projectId}`);
    } else if (chatId != null)
      history(`/aichat?id=${chatId}&matterid=${projectId}`);
    else history(`/aichat?matterid=${projectId}`);
    setIsMatterSelected(true);
    setSelectedMatter(event);
    setSelectedMatterId(event.id);
  };

  const handleChatToFolder = async (): Promise<void> => {
    const chatStr = JSON.stringify(chatDrawerData);
    setOpenChatDrawer(false);
    const addChatResponse = await addChat(chatStr, selectedMatterId);

    if (addChatResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      let chatData = addChatResponse.data;
      const data: FilingCabinetData = {
        filename: `${chatFileReferenceName}.chat`,
        originalPath: `${chatFileReferenceName}.chat`,
        file_type: "chat",
        parent_node_ids: selectedFolders,
        size: 0,
        matter_id: selectedMatterId,
        chat_id: chatData.id,
      };

      const addChatToFile = await addChatToFilingCabinet(data);

      if (addChatToFile?.status == Constants.HTTP_STATUS.SUCCESS) {
        setToast({
          ...toast,
          openState: true,
          title: "Success",
          message: "Document/Chat has been added to the filing cabinet.",
          onCloseToast: onCloseToast,
          messageType: "bg-primary text-white",
        });
      } else {
        setToast({
          ...toast,
          openState: true,
          title: "Error",
          message: "Unable to create folder.",
          onCloseToast: onCloseToast,
          messageType: "bg-danger text-white",
        });
      }
    } else {
      setToast({
        ...toast,
        openState: true,
        title: "Error",
        message: "Unable to create folder.",
        onCloseToast: onCloseToast,
        messageType: "bg-danger text-white",
      });
    }
  };

  const handleSelectedFolder = async (selectedFolders) => {
    //
    setSelectedFolders(selectedFolders);
  };

  const dynamicDivRef = useRef(null);
  const [selectedText, setSelectedText] = useState(null);

  const handleMouseUp = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const selection = window?.getSelection()?.toString().trim();
    if (selection) {
      const range = window?.getSelection()?.getRangeAt(0);
      const rect = range?.getBoundingClientRect();
      setSelectionInfo({
        selection,
        rect,
      });
      setSelectedText(selection);
    } else {
      setSelectionInfo(null);
    }
  }, []);

  const handlePopupClose = () => {
    setSelectionInfo(null);
    setSelectedText(null);
  };
  const [selectionInfo, setSelectionInfo] = useState(null);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [isOpenChat, setOpenChat] = useState(false);

  useEffect(() => {
    if (!!location.state?.messages) {
      let dataArray = [];
      location.state.messages.forEach((message) => {
        const data = {
          key: message.id,
          question: message.query,
          answer: message?.response?.response,
          filenames: message.filenames ? message.filenames : undefined,

          fusion: message?.response?.fusion,
          topk: message?.response?.topk,
          tree_summarize: message?.response?.tree_summarize,
          gemini: message?.response?.gemini,
          response: message?.response,
          aiModel: message.aiModel,
        };
        dataArray.push({ ...data });
      });
      setQaData([...dataArray]);
    }
  }, [location.state, matter]);
  useEffect(() => {
    setQaData([]);
  }, [matter]);
  useEffect(() => {
    if (chatId === null) {
      setQaData([]);
    }
  }, [chatId]);
  // TODO get the data from the server if id is present
  const [feedbackOpen, setFeedbackOpen] = useState({
    good: "false",
    bad: "false",
  });

  const [isFeedbackDrawerOpen, setOpenFeedbackDrawer] = useState(false);
  const [badFeedback, setBadFeedback] = useState("");
  const feedbackTypes = [
    "Don't like the style",
    "Not factually correct",
    "Incorrect References",
    "More...",
  ];
  const [moreFeedback, setMoreFeedback] = useState("");
  const feedback = async (status, id, key) => {
    let data = {};
    if (
      qaData[key]?.feedbackOpen.good === "true" ||
      (qaData[key]?.feedbackOpen.bad === "true" && id === null)
    ) {
      return;
    }
    // setFeedbackOpen({
    //   good: status === "good" ? "true" : "false",
    //   bad: status === "bad" ? "true" : "false",
    // });

    if (status === "bad" && id === null) {
      setOpenFeedbackDrawer(true);
      return;
    } else if (status === "bad" && id !== null) {
      data = {
        messageId: id,
        status: status,
        details: badFeedback,
        moreDetails: moreFeedback,
        matterId: matter?.id,
      };
    }

    if (status === "good") {
      data = {
        messageId: id,
        status: status,
        details: null,
        moreDetails: null,
        matterId: matter?.id,
      };
    }
    setQaData((prevData) => {
      const newData = prevData.map((item) => {
        //const res = JSON.parse(response);
        if (item.key === key) {
          return {
            ...item,
            feedbackOpen: {
              good: status === "good" ? "true" : "false",
              bad: status === "bad" ? "true" : "false",
            },
          };
        }
        return item;
      });

      return newData;
    });
    const response = await sendFeedback(data);

    if (response.status == Constants.HTTP_STATUS.SUCCESS) {
      setToast({
        ...toast,
        openState: true,
        title: "Success",
        message: "Feedback has been submitted.",
        onCloseToast: onCloseToast,
        messageType: "bg-primary text-white",
      });
      setBadFeedback("");
      setMoreFeedback("");
    } else {
      setToast({
        ...toast,
        openState: true,
        title: "Error",
        message: "Unable to submit feedback.",
        onCloseToast: onCloseToast,
        messageType: "bg-danger text-white",
      });
      setBadFeedback("");
      setMoreFeedback("");
    }
  };

  const getAiModelValue = (aiModel: string) => {
    switch (aiModel) {
      case "gpt3":
        return "GPT 3.5 turbo";
      case "gpt4x":
        return "GPT 4 turbo";
      case "gpt-4o":
        return "GPT 4-o";
      default:
        return aiModel;
    }
  };

  const navigateToFilingCabinet = () => {
    navigate(`/folder?matterid=${selectedMatterId}`);
  };

  return (
    <Fragment>
      {selectedMatterId ? (
        <NotesForm matter_id={selectedMatterId} context_type="chat" />
      ) : null}
      <div className="p-0 container">
        {qaData.length > 0 && (
          <div className="row">
            <div className="col-md-12 chat-response mt-3">
              <ul className="w-100 list-group">
                <div>
                  {qaData.map((qa, index) => {
                    return (
                      <li
                        key={index}
                        className={`p-3 list-group-item mb-3 chat-bg rounded ${
                          index === qaData.length - 1 ? "shadow" : "shadow-sm"
                        }`}
                      >
                        <div className="d-flex justify-content-between bd-highlight pb-3 mb-2 border-bottom">
                          <div className="px-2 justify-content-center bdd-user-question">
                            {qa.question}
                          </div>

                          {!loadingRows[qa.key] && (
                            <div className="">
                              <IconButton
                                onClick={(e) => handleChatAction(e, qa)}
                                className="float-end p-0"
                                color="primary"
                                aria-label="Click to add to File cabinate"
                              >
                                <AddToDriveRoundedIcon />
                              </IconButton>
                            </div>
                          )}
                        </div>

                        <div className="d-flex flex-row bd-highlight mb-1">
                          {/* <div className="bd-highlight chat-gpt"></div> */}
                          {/* <CycloneIcon /> */}
                          <div className="px-2 bd-highlight fw-normal w-100">
                            {loadingRows[qa.key] ? (
                              // <Shimmer line={5} gap={10} />
                              <div className="w-50 py-3 bdd-loading-indicator">
                                <LinearProgress />
                              </div>
                            ) : (
                              <Fragment>
                                <div>
                                  <Fragment>
                                    <div
                                      className=""
                                      ref={dynamicDivRef}
                                      onMouseUp={handleMouseUp}
                                      // dangerouslySetInnerHTML={{
                                      //   __html: qa.answer,
                                      // }}
                                    >
                                      {selectedMatterId == OPEN_CHAT && (
                                        <Fragment>
                                          <div
                                            className="mb-2"
                                            dangerouslySetInnerHTML={{
                                              __html: qa?.response?.response,
                                            }}
                                          ></div>
                                        </Fragment>
                                      )}

                                      {selectedMatterId !== OPEN_CHAT && (
                                        <Fragment>
                                          <div className="py-1 mb-2 fs-5 border-bottom fw-bold">
                                            Researchers{" "}
                                            <sup className="mutedText">
                                              {getAiModelValue(qa.aiModel)}
                                            </sup>
                                          </div>

                                          <div
                                            className="mb-2 bdd-topk-response bdd-agent-response"
                                            dangerouslySetInnerHTML={{
                                              __html: qa?.topk?.response
                                                ? `<strong>Top Docs:</strong> ${qa?.topk?.response}`
                                                : "<strong>Top Docs:</strong> Loading...",
                                            }}
                                          ></div>
                                          <div
                                            className="mb-2 bdd-tree-summarize-response bdd-agent-response"
                                            dangerouslySetInnerHTML={{
                                              __html: qa?.tree_summarize
                                                ?.response
                                                ? `<strong>Expansive:</strong> ${qa?.tree_summarize?.response}`
                                                : " <strong>Expansive:</strong> Loading...",
                                            }}
                                          ></div>
                                          <div
                                            className="mb-2 bdd-fusion-response bdd-agent-response"
                                            dangerouslySetInnerHTML={{
                                              __html: qa?.fusion?.response
                                                ? `<strong>Multi-Q:</strong> ${qa?.fusion?.response}`
                                                : "<strong>Multi-Q:</strong> Loading...",
                                            }}
                                          ></div>
                                        </Fragment>
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "start",
                                      }}
                                    >
                                      <Dialog
                                        open={isFeedbackDrawerOpen}
                                        onClose={() =>
                                          setOpenFeedbackDrawer(false)
                                        }
                                      >
                                        <DialogTitle>Feedback</DialogTitle>
                                        <DialogContent>
                                          <DialogContentText m={2}>
                                            Please provide feedback for the
                                            response.
                                          </DialogContentText>
                                          <TextField
                                            select
                                            label="Feedback Type"
                                            fullWidth
                                            value={badFeedback}
                                            onChange={(e) =>
                                              setBadFeedback(e.target.value)
                                            }
                                            required
                                          >
                                            <MenuItem
                                              key="please choose"
                                              value="Please Choose"
                                              selected
                                              disabled
                                            >
                                              -Please Choose-
                                            </MenuItem>
                                            {feedbackTypes.map((option) => (
                                              <MenuItem
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                          <TextField
                                            autoFocus
                                            multiline
                                            margin="dense"
                                            id="name"
                                            label="Feedback"
                                            type="text"
                                            fullWidth
                                            value={moreFeedback}
                                            onChange={(e) =>
                                              setMoreFeedback(e.target.value)
                                            }
                                          />
                                        </DialogContent>
                                        <DialogActions>
                                          <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                              setOpenFeedbackDrawer(false);
                                              feedback(
                                                "bad",
                                                qa.messageId,
                                                qa.key
                                              );
                                            }}
                                          >
                                            Submit
                                          </button>
                                          <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                              setOpenFeedbackDrawer(false);
                                              // setFeedbackOpen({
                                              //   good: "false",
                                              //   bad: "false",
                                              // });
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </DialogActions>
                                      </Dialog>
                                      {"messageId" in qa &&
                                      qa?.messageId !== null ? (
                                        <>
                                          {qa?.feedbackOpen?.good === "true" ? (
                                            <ThumbUpAltIcon />
                                          ) : (
                                            <ThumbUpOffAltIcon
                                              onClick={() =>
                                                feedback(
                                                  "good",
                                                  qa.messageId,
                                                  qa.key
                                                )
                                              }
                                            />
                                          )}
                                          {qa?.feedbackOpen?.bad === "true" ? (
                                            <ThumbDownAltIcon />
                                          ) : (
                                            <ThumbDownOffAltIcon
                                              onClick={() =>
                                                feedback("bad", null, qa.key)
                                              }
                                            />
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                    <Popover
                                      className="rounded"
                                      open={Boolean(selectionInfo)}
                                      onClose={handlePopupClose}
                                      anchorReference="anchorPosition"
                                      anchorPosition={{
                                        top: selectionInfo?.rect.top,
                                        left:
                                          (selectionInfo?.rect.left +
                                            selectionInfo?.rect.right) /
                                          2,
                                      }}
                                      transformOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                      }}
                                    >
                                      <SubqueryPopover
                                        query={selectionInfo?.selection}
                                        handleQueryEvents={handlePopoverEvents}
                                        setSelectedText={setSelectedText}
                                      />
                                    </Popover>
                                  </Fragment>
                                </div>
                              </Fragment>
                            )}
                          </div>
                        </div>
                        <div className="px-1 bd-highlight fw-normal">
                          {loadingRows[qa.key] ? (
                            <div className="w-100"></div>
                          ) : (
                            <Fragment>
                              {selectedMatter &&
                                selectedMatter["status_id"] == 8 && (
                                  <Memoised
                                    matterId={matter?.id}
                                    setFileReferenceData={setFileReferenceData}
                                    response={qa.response}
                                    dynamicDivRef={dynamicDivRef}
                                    handleMouseUp={handleMouseUp}
                                    qa={qa}
                                  />
                                )}

                              {selectedMatter &&
                                selectedMatter["status_id"] != 8 && (
                                  <div className="w-100 border-top pt-2 border-top-2">
                                    <p className="text-muted fst-italic fs-6">
                                      {" "}
                                      Note: References will be available once
                                      upload process is complete.
                                    </p>
                                  </div>
                                )}
                            </Fragment>
                          )}
                        </div>
                      </li>
                    );
                  })}
                </div>

                <div className="mt-4" ref={scrollableContainerRef}></div>
              </ul>
            </div>
          </div>
        )}
        <div className="container chat-input col-md-10 position-fixed"></div>

        <div className="container chat-input position-fixed">
          <div className="col">
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MattersDropdown
                selectedMatter={selectedMatter}
                onSelectMatter={handleSelection}
                isOpenChatEnabled={true}
                setOpenChat={setOpenChat}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

              <IconButton
                color="default"
                className="bdd-filing-cabinet"
                aria-label="Click here to navigate to filing cabinet"
                onClick={() => navigateToFilingCabinet()}
              >
                <DriveFileMoveIcon />
              </IconButton>

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Ask RelevAInce to answer your question"
                inputProps={{
                  "aria-label": "Ask RelevAInce to answer your question",
                }}
                autoFocus={true}
                onKeyDown={handleKeyDown}
                value={queryRawText}
                onChange={handleChange}
                ref={userInputRef}
                className="bdd-chat-input"
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

              <IconButton
                color="primary"
                ref={buttonRef}
                sx={{ p: "10px" }}
                className="bdd-chat-submit"
                aria-label="Click here to ask RelevAInce to answer your question"
                onClick={() => handleClickOpenSlider()}
              >
                <AdjustIcon />
              </IconButton>

              <Popover
                open={openSlider}
                anchorEl={buttonRef.current}
                onClose={handleCloseSlider}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <div style={{ padding: 20 }}>
                  <Typography variant="h6">Top K</Typography>
                  <div
                    style={{
                      width: 200,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Slider
                      value={sliderValue}
                      onChange={handleSliderChange}
                      aria-labelledby="vertical-slider"
                      min={3}
                      max={20}
                      valueLabelDisplay="auto"
                    />
                    <Input
                      value={sliderValue}
                      size="small"
                      onChange={handleInputChangeSlider}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 3,
                        max: 20,
                        type: "number",
                        "aria-labelledby": "vertical-slider",
                      }}
                      // style={{
                      //   marginLeft: 0,
                      //   borderBottom: "none",
                      // }}
                    />
                  </div>
                </div>
              </Popover>

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                className="bdd-chat-submit"
                aria-label="Click here to ask RelevAInce to answer your question"
                onClick={() => handleQuery()}
              >
                <DirectionsIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <VoiceToText
                onQueryChange={setQueryInput}
                onExecute={handleVoiceInput}
                voiceInputState={voiceInputState}
                setVoiceInputState={setVoiceInputState}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <div className="bdd-chat-history">
                <History
                  historyOpen={historyOpen}
                  setHistoryOpen={setHistoryOpen}
                  matterId={matter?.id}
                />{" "}
              </div>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={handleClick}
              >
                <Badge badgeContent={aiModelValue} color="error">
                  <TuneRoundedIcon />
                </Badge>
              </IconButton>
            </Paper>
          </div>
        </div>

        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          autoFocus={false}
          tabIndex={-1}
          TransitionComponent={Fade}
          style={{
            position: "fixed",
            top: "-180px",
            left: "-25px",
          }}
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem autoFocus={false}>
            <div className="d-flex flex-column">
              <small className="p-0">AI Model</small>
              <div
                className="p-0"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "3px",
                }}
              >
                {!isOpenChat ? (
                  <AIModel
                    defaultValue={aiModel}
                    handleAIModelChange={handleAIModelChange}
                    disableLlama={true}
                  ></AIModel>
                ) : (
                  <AIOpenChat
                    queryModels={queryModels}
                    setQueryModels={setQueryModels}
                  />
                )}
              </div>
            </div>
          </MenuItem>
        </Menu>

        <ChatDrawer
          matter_id={selectedMatterId}
          chatResponse={chatDrawerData}
          openState={openChatDrawer}
          setOpenState={setOpenChatDrawer}
          onSelection={handleSelectedFolder}
          onAddToFolderClick={handleChatToFolder}
          onChatFileNameChange={setChatFileReferenceName}
        />

        <ToastMessage {...toast} />
      </div>
    </Fragment>
  );
};

export default IndexQuery;

const frag = ({
  matterId,
  setFileReferenceData,
  response,
  dynamicDivRef,
  handleMouseUp,
  qa,
  fileStatus,
  readStatus,
}) => (
  <Fragment>
    <FileReference
      key={uuidv4()}
      selectedMatterId={matterId}
      setFileReferenceData={setFileReferenceData}
      response={qa.response}
      dynamicDivRef={dynamicDivRef}
      mouseUp={handleMouseUp}
      qa={qa}
      fileStatus={fileStatus}
      readStatus={readStatus}
    />
  </Fragment>
);

const Memoised = React.memo(frag);

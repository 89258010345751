import TextField from "@mui/material/TextField/TextField";
// import { Button } from "@progress/kendo-react-buttons";
import Button from "@mui/material/Button/Button";
import { DialogCloseEvent } from "@progress/kendo-react-dialogs";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { convertToBreadcrumbData } from "../helpers/helperMethods";
import { BreadcrumbComponent } from "./Breadcrumb";

export const EditDialog = (props: any) => {
  const [inputValue, setInputValue] = React.useState(props.editValue);
  const handleDialogClick = (event: any) => {
    props.onDialogClick({
      event,
      path: props.editValue.path,
      value: typeof inputValue === "string" ? inputValue : inputValue.path,
      type: event.target.value,
    });
  };

  const handleDialogClose = (event: DialogCloseEvent) => {
    props.onDialogClose(event);
  };

  const handleInputChange = (event: InputChangeEvent) => {
    setInputValue(event.value);
  };

  return (
    <React.Fragment>
      <Modal {...props} size="md" aria-labelledby="rename-folder" centered>
        <Modal.Header closeButton>
          <Modal.Title id="rename-folder">
            {/* {props.addValue && `${props.addValue?.path}/`}
            {!props.addValue && "Home/"} */}
            <BreadcrumbComponent
              data={convertToBreadcrumbData(props.selectedTreeItem)}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Folder name</h4>
          {/* <Input
            maxLength={40}
            value={getName(inputValue.path)}
            style={{ width: "350px", margin: "25px", textAlign: "center" }}
            className={"k-textbox"}
            onChange={handleInputChange}
          /> */}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button
            variant="contained"
            data-action="rename"
            onClick={handleDialogClick}
          >
            Rename
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>

    // <DialogComponent title={"Please confirm"} onClose={handleDialogClose}>
    //   <p style={{ width: "350px", margin: "25px", textAlign: "center" }}>
    //     Enter new name for the file.
    //   </p>
    //   <Input
    //     maxLength={40}
    //     value={getName(inputValue.path)}
    //     style={{ width: "350px", margin: "25px", textAlign: "center" }}
    //     className={"k-textbox"}
    //     onChange={handleInputChange}
    //   />
    //   <DialogActionsBar>
    //     <Button value={"rename"} onClick={handleDialogClick}>
    //       Rename
    //     </Button>
    //     <Button value={"cancel"} onClick={handleDialogClick}>
    //       Cancel
    //     </Button>
    //   </DialogActionsBar>
    // </DialogComponent>
  );
};

export const DeleteDialog = (props: any) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleDialogClick = (event: any) => {
    props.onDialogClick({
      event,
      path: props.addValue?.path,
      value: typeof inputValue === "string" ? inputValue : inputValue,
      type: event.currentTarget.dataset.action,
    });
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="md"
        aria-labelledby="delete-files-folder"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="delete-files-folder">Delete File/Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete the selected file? You cannot undo
            this action.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button
            data-action="delete"
            variant="contained"
            onClick={handleDialogClick}
          >
            <i className="bi bi-trash"></i>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export const AddDialog = (props: any) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleDialogClick = (event: any) => {
    props.onDialogClick({
      event,
      path: props.addValue?.path,
      value: typeof inputValue === "string" ? inputValue : inputValue,
      type: event.currentTarget.dataset.action,
    });
  };

  return (
    <React.Fragment>
      <Modal {...props} size="md" aria-labelledby="add-new-folder" centered>
        <Modal.Header closeButton>
          <Modal.Title id="add-new-folder">
            {/* {props.addValue && `${props.addValue?.path}/`}
            {!props.addValue && "Home/"} */}
            <BreadcrumbComponent
              data={convertToBreadcrumbData(props.selectedTreeItem)}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextField
            id="file-folder-name"
            className="w-100"
            label="New Folder/File name"
            onChange={(event) => setInputValue(event.target.value)}
            variant="filled"
            value={inputValue}
            autoFocus={true}
            inputProps={{ max: 10, min: 0 }} // Set max and min values
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>

          {(props.action == "add" || props.action == "root") && (
            <Button
              variant="contained"
              data-action={props.action}
              onClick={handleDialogClick}
            >
              Add
            </Button>
          )}

          {props.action == "rename" && (
            <Button
              variant="contained"
              data-action="rename"
              onClick={handleDialogClick}
            >
              Rename
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

import exp from "constants";

import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";

export const getSourceFiles = async (matterId: number, offset: number) => {
  const url = endpoints.create_matter.getSourceFiles
    .replace("{matter_id}", matterId.toString())
    .replace("{u_id}", authService.getUserId().toString())
    .replace("{offset}", offset.toString());

  return axiosInstance.get(url);
};

export const getExtractedFiles = async (matterId: number, source: number) => {
  const url = endpoints.create_matter.getExtractedFiles
    .replace("{matter_id}", matterId.toString())
    .replace("{s_id}", source.toString());

  return axiosInstance.get(url);
};

export const triggerJenkins = async (matterId: number, user_id) => {
  const url = endpoints.create_matter.triggerJenkins
    .replace("{matter_id}", matterId.toString())
    .replace("{u_id}", user_id.toString());

  return axiosInstance.post(url);
};

export const indexingStatus = async (matterId: number) => {
  const url = endpoints.create_matter.indexingStatus.replace(
    "{matter_id}",
    matterId.toString()
  );

  return axiosInstance.get(url);
};

export const createMatter = async (
  name: string,
  desc: string,
  emails: string[]
) => {
  const url = endpoints.create_matter.createMatter;
  const data = {
    project_name: name,
    project_desc: desc,
    owner_id: authService.getUserId(),
    emails: emails,
  };
  return axiosInstance.post(url, data);
};

export const getSummary = async (matterId: number) => {
  const url = endpoints.create_matter.summary
    .replace("{matter_id}", matterId.toString())
    .replace("{owner_id}", authService.getUserId().toString());

  return axiosInstance.get(url);
};

export const getExtractedSummary = async (matterId: number) => {
  const url = endpoints.create_matter.extractedSummary.replace(
    "{matter_id}",
    matterId.toString()
  );
  return axiosInstance.get(url);
};

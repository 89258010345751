import { useState } from "react";
import Graph from "react-graph-vis";

const options = {
  layout: {
    hierarchical: false,
  },
  edges: {
    color: "#000000",
  },
};

const randomColor = () => {
  const red = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  const green = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  const blue = Math.floor(Math.random() * 256)
    .toString(16)
    .padStart(2, "0");
  return `#${red}${green}${blue}`;
};

const PeopleGraph = () => {
  /* const createNode = (x: any, y: any) => {
    const color = randomColor();
    setState(({ graph: { nodes, edges }, counter, ...rest }: any) => {
      const id = counter + 1;
      const from = Math.floor(Math.random() * (counter - 1)) + 1;
      return {
        graph: {
          nodes: [...nodes, { id, label: `Node ${id}`, color, x, y }],
          edges: [...edges, { from, to: id }],
        },
        counter: id,
        ...rest,
      };
    });
  }; */
  const [state, setState] = useState({
    counter: 5,
    graph: {
      nodes: [
        {
          id: 1,
          label: "Angi Farrugia ",
          shape: "image",
          image: "./email-network.png",
          title: "afarrugia@austin-ind.com",
        },
        {
          id: 2,
          label: "curt@logsdoninc.com",
          shape: "image",
          image: "./email-network.png",
          title: "curt@logsdoninc.com",
        },
        {
          id: 3,
          label: "Gibbons, Diane",
          shape: "image",
          image: "./email-network.png",
          title: "Diane.Gibbons@lockton.com",
        },
        {
          id: 4,
          label: "Jennifer Lockhart",
          shape: "image",
          image: "./email-network.png",
          title: "jen@fcuinc.net",
        },
        {
          id: 5,
          label: "grisha@floodbarrier.com",
          shape: "image",
          image: "./email-network.png",
          title: "grisha@floodbarrier.com",
        },
        {
          id: 6,
          label: "kelly.murray@kone.com",
          shape: "image",
          image: "./email-network.png",
          title: "kelly.murray@kone.com",
        },
        {
          id: 7,
          label: "Val Hanson ",
          shape: "image",
          image: "./email-network.png",
          title: "VHANSON@austin-ind.com",
        },
        {
          id: 8,
          label: "Bobby Terriaco ",
          shape: "image",
          image: "./email-network.png",
          title: "BTerriaco@austin-ind.com",
        },
        {
          id: 9,
          label: "Lena Ho ",
          shape: "image",
          image: "./email-network.png",
          title: "llui@Austin-Ind.com",
        },
        {
          id: 10,
          label: "Dean, Matt",
          shape: "image",
          image: "./email-network.png",
          title: "Matt.Dean@mvwc.com",
        },
        {
          id: 11,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "Scott.Mcfarlane@mvwc.com",
        },
        {
          id: 12,
          label: "sales@federalrentafence.com",
          shape: "image",
          image: "./email-network.png",
          title: "sales@federalrentafence.com",
        },
        {
          id: 13,
          label: "Dupre, Charlotte L.",
          shape: "image",
          image: "./email-network.png",
          title: "CDupre@GilbaneCo.com",
        },
        {
          id: 14,
          label: "pcesari@austin-ind.com",
          shape: "image",
          image: "./email-network.png",
          title: "pcesari@austin-ind.com",
        },
        {
          id: 15,
          label: "Michael Burgess ",
          shape: "image",
          image: "./email-network.png",
          title: "mburgess@austin-ind.com",
        },
        {
          id: 16,
          label: "RReace@valleycrest.com",
          shape: "image",
          image: "./email-network.png",
          title: "RReace@valleycrest.com",
        },
        {
          id: 17,
          label: "Derek Williams ",
          shape: "image",
          image: "./email-network.png",
          title: "DWilliams@Austin-Ind.com",
        },
        {
          id: 18,
          label: "Pete Cesari ",
          shape: "image",
          image: "./email-network.png",
          title: "PCESARI@austin-ind.com",
        },
        {
          id: 19,
          label: "Baker, Milton",
          shape: "image",
          image: "./email-network.png",
          title: "Milton.Baker@hines.com",
        },
        {
          id: 20,
          label: "David F. LaCamera",
          shape: "image",
          image: "./email-network.png",
          title: "DLacamer@GilbaneCo.com",
        },
        {
          id: 21,
          label: "Caroline McGraw ",
          shape: "image",
          image: "./email-network.png",
          title: "cmcgraw@Austin-Ind.com",
        },
        {
          id: 22,
          label: "Dick McCally ",
          shape: "image",
          image: "./email-network.png",
          title: "dmccally@Austin-Ind.com",
        },
        {
          id: 23,
          label: " Josh Reed ",
          shape: "image",
          image: "./email-network.png",
          title: "jreed@gilbaneco.com",
        },
        {
          id: 24,
          label: "Franco Reynosa ",
          shape: "image",
          image: "./email-network.png",
          title: "franco@Austin-Ind.com",
        },
        {
          id: 25,
          label: "Steve Rivers ",
          shape: "image",
          image: "./email-network.png",
          title: "SRivers@austin-ind.com",
        },
        {
          id: 26,
          label: "Megan Chappo ",
          shape: "image",
          image: "./email-network.png",
          title: "megan.chappo@texturacorp.com",
        },
        {
          id: 27,
          label: "Rusty Livingston ",
          shape: "image",
          image: "./email-network.png",
          title: "RLivingston@austin-ind.com",
        },
        {
          id: 28,
          label: "Carolyn Downing ",
          shape: "image",
          image: "./email-network.png",
          title: "cdowning@Austin-Ind.com",
        },
        {
          id: 29,
          label: "bhart@bearsplumbing.com",
          shape: "image",
          image: "./email-network.png",
          title: "bhart@bearsplumbing.com",
        },
        {
          id: 30,
          label: "Eric Ackerman ",
          shape: "image",
          image: "./email-network.png",
          title: "eackerman@ackermanplumbinginc.com",
        },
        {
          id: 31,
          label: "Jorgensen Dane E. ",
          shape: "image",
          image: "./email-network.png",
          title: "DJorgens@GilbaneCo.com",
        },
        {
          id: 32,
          label: "Dennis Todd ",
          shape: "image",
          image: "./email-network.png",
          title: "dennisgtodd@gmail.com",
        },
        {
          id: 33,
          label: "Monica Schoenemann ",
          shape: "image",
          image: "./email-network.png",
          title: "monicas@Austin-Ind.com",
        },
        {
          id: 34,
          label: "Michael Janssen ",
          shape: "image",
          image: "./email-network.png",
          title: "mjanssen@austin-ind.com",
        },
        {
          id: 35,
          label: "Kelly.aspinwall@kone.com",
          shape: "image",
          image: "./email-network.png",
          title: "Kelly.aspinwall@kone.com",
        },
        {
          id: 36,
          label: "Jean McCarthy",
          shape: "image",
          image: "./email-network.png",
          title: "Jean@crowther.net",
        },
        {
          id: 37,
          label: "Aspinwall Kelly ",
          shape: "image",
          image: "./email-network.png",
          title: "Kelly.Aspinwall@KONE.com",
        },
        {
          id: 38,
          label: "Ted Verderame ",
          shape: "image",
          image: "./email-network.png",
          title: "ted.verderame@texturacorp.com",
        },
        {
          id: 39,
          label: "Diana Rieger",
          shape: "image",
          image: "./email-network.png",
          title: "DianaR@crowther.net",
        },
        {
          id: 40,
          label: "Reed, Joshua",
          shape: "image",
          image: "./email-network.png",
          title: "JReed@GilbaneCo.com",
        },
        {
          id: 41,
          label: "Milton Baker (milton.baker@hines.com)",
          shape: "image",
          image: "./email-network.png",
          title: "milton.baker@hines.com",
        },
        {
          id: 42,
          label: "Kent McNorton ",
          shape: "image",
          image: "./email-network.png",
          title: "kmcnorton@Austin-Ind.com",
        },
        {
          id: 43,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "Tricia.Dorn@lcec.net",
        },
        {
          id: 44,
          label: "Chris Ediger ",
          shape: "image",
          image: "./email-network.png",
          title: "CEdiger@austin-ind.com",
        },
        {
          id: 45,
          label: "gregburkhart@keyglass.com",
          shape: "image",
          image: "./email-network.png",
          title: "gregburkhart@keyglass.com",
        },
        {
          id: 46,
          label: "Rob Allen ",
          shape: "image",
          image: "./email-network.png",
          title: "rallen@austin-ind.com",
        },
        {
          id: 47,
          label: "Will Sneed ",
          shape: "image",
          image: "./email-network.png",
          title: "wsneed@jhgi.com",
        },
        {
          id: 48,
          label: "FernandezB@gtlaw.com",
          shape: "image",
          image: "./email-network.png",
          title: "FernandezB@gtlaw.com",
        },
        {
          id: 49,
          label: "James Sims ",
          shape: "image",
          image: "./email-network.png",
          title: "JSims@Austin-Ind.com",
        },
        {
          id: 50,
          label: "Roger Files ",
          shape: "image",
          image: "./email-network.png",
          title: "rfiles@Austin-Ind.com",
        },
        {
          id: 51,
          label: "erik.santiago@khss.com",
          shape: "image",
          image: "./email-network.png",
          title: "erik.santiago@khss.com",
        },
        {
          id: 52,
          label: "John Kucaba ",
          shape: "image",
          image: "./email-network.png",
          title: "john@leedrywall.com",
        },
        {
          id: 53,
          label: "Shannon Mungovan ",
          shape: "image",
          image: "./email-network.png",
          title: "smungovan@austin-ind.com",
        },
        {
          id: 54,
          label: "Tim Mairn ",
          shape: "image",
          image: "./email-network.png",
          title: "t.mairn@morrow.com",
        },
        {
          id: 55,
          label: "Monte Peiser ",
          shape: "image",
          image: "./email-network.png",
          title: "mpeiser@austin-ind.com",
        },
        {
          id: 56,
          label: "John Ford ",
          shape: "image",
          image: "./email-network.png",
          title: "jford@FORDDRYWALL.COM",
        },
        {
          id: 57,
          label: "jshum@titusbuild.com",
          shape: "image",
          image: "./email-network.png",
          title: "jshum@titusbuild.com",
        },
        {
          id: 58,
          label: "Monte Peiser ",
          shape: "image",
          image: "./email-network.png",
          title: "mpeiser@Austin-Ind.com",
        },
        {
          id: 59,
          label: "Peter Durbin ",
          shape: "image",
          image: "./email-network.png",
          title: "pdurbin@Austin-Ind.com",
        },
        {
          id: 60,
          label: "rlivingston@austin-ind.com",
          shape: "image",
          image: "./email-network.png",
          title: "rlivingston@austin-ind.com",
        },
        {
          id: 61,
          label: "matt@florida-civil.com",
          shape: "image",
          image: "./email-network.png",
          title: "matt@florida-civil.com",
        },
        {
          id: 62,
          label: "lisa.sandfer@ge.com",
          shape: "image",
          image: "./email-network.png",
          title: "lisa.sandfer@ge.com",
        },
        {
          id: 63,
          label: "jstrecker@sgrinc.com",
          shape: "image",
          image: "./email-network.png",
          title: "jstrecker@sgrinc.com",
        },
        {
          id: 64,
          label: "Kathleen Simpson ",
          shape: "image",
          image: "./email-network.png",
          title: "ksimpson@bandiflorida.com",
        },
        {
          id: 65,
          label: "DLacamer@gilbaneco.com",
          shape: "image",
          image: "./email-network.png",
          title: "DLacamer@gilbaneco.com",
        },
        {
          id: 66,
          label: "Hensey, Timothy D.",
          shape: "image",
          image: "./email-network.png",
          title: "THensey@GilbaneCo.com",
        },
        {
          id: 67,
          label: "Karen Toone ",
          shape: "image",
          image: "./email-network.png",
          title: "ktoone@Austin-Ind.com",
        },
        {
          id: 68,
          label: "carl@completepumps.com",
          shape: "image",
          image: "./email-network.png",
          title: "carl@completepumps.com",
        },
        {
          id: 69,
          label: "thomas.bay@hines.com",
          shape: "image",
          image: "./email-network.png",
          title: "thomas.bay@hines.com",
        },
        {
          id: 70,
          label: "gg@designersspecialty.com",
          shape: "image",
          image: "./email-network.png",
          title: "gg@designersspecialty.com",
        },
        {
          id: 71,
          label: "Nancy Dvorak ",
          shape: "image",
          image: "./email-network.png",
          title: "ndvorak@austin-ind.com",
        },
        {
          id: 72,
          label: "Mark Lewis ",
          shape: "image",
          image: "./email-network.png",
          title: "mlewis@austin-ind.com",
        },
        {
          id: 73,
          label: "Ralph Placencia ",
          shape: "image",
          image: "./email-network.png",
          title: "ralph@ap-const.com",
        },
        {
          id: 74,
          label: "Pete Cesari (pcesari@cesarigroup.com)",
          shape: "image",
          image: "./email-network.png",
          title: "pcesari@cesarigroup.com",
        },
        {
          id: 75,
          label: "Hamil, James B.",
          shape: "image",
          image: "./email-network.png",
          title: "Jim.Hamil@terracon.com",
        },
        {
          id: 76,
          label: "dwilliams@austin-ind.com",
          shape: "image",
          image: "./email-network.png",
          title: "dwilliams@austin-ind.com",
        },
        {
          id: 77,
          label: "pamato@flsamerica.com",
          shape: "image",
          image: "./email-network.png",
          title: "pamato@flsamerica.com",
        },
        {
          id: 78,
          label: "dmccally@austin-ind.com",
          shape: "image",
          image: "./email-network.png",
          title: "dmccally@austin-ind.com",
        },
        {
          id: 79,
          label: "Medwedeff, Rick",
          shape: "image",
          image: "./email-network.png",
          title: "Rick.Medwedeff@marriott.com",
        },
        {
          id: 80,
          label: "Al Price ",
          shape: "image",
          image: "./email-network.png",
          title: "aprice@austin-ind.com",
        },
        {
          id: 81,
          label: "Dustin Heath ",
          shape: "image",
          image: "./email-network.png",
          title: "dheath@FORDDRYWALL.COM",
        },
        {
          id: 82,
          label: "cmcgraw@austin-ind.com",
          shape: "image",
          image: "./email-network.png",
          title: "cmcgraw@austin-ind.com",
        },
        {
          id: 83,
          label: "Steve Rivers (srivers@austin-ind.com)",
          shape: "image",
          image: "./email-network.png",
          title: "srivers@austin-ind.com",
        },
        {
          id: 84,
          label: "rbruder@hksinc.com",
          shape: "image",
          image: "./email-network.png",
          title: "rbruder@hksinc.com",
        },
        {
          id: 85,
          label: "Paul Johnson (PJohnson@austin-ind.com)",
          shape: "image",
          image: "./email-network.png",
          title: "PJohnson@austin-ind.com",
        },
        {
          id: 86,
          label: "Jere Gunstanson ",
          shape: "image",
          image: "./email-network.png",
          title: "JGunstanson@Austin-Ind.com",
        },
        {
          id: 87,
          label: "Lutz, Rich",
          shape: "image",
          image: "./email-network.png",
          title: "Rich.Lutz@marriott.com",
        },
        {
          id: 88,
          label: "Richard Lundberg ",
          shape: "image",
          image: "./email-network.png",
          title: "rlundberg@forgeeng.com",
        },
        {
          id: 89,
          label: "Sieczkowski, Thomas A.",
          shape: "image",
          image: "./email-network.png",
          title: "tsieczkowski@gilbaneco.com",
        },
        {
          id: 90,
          label: "Anna Howe ",
          shape: "image",
          image: "./email-network.png",
          title: "AHowe@Austin-Ind.com",
        },
        {
          id: 91,
          label: "Becky Jones ",
          shape: "image",
          image: "./email-network.png",
          title: "BJones@Austin-Ind.com",
        },
        {
          id: 92,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "Andy.Trowbridge@hines.com",
        },
        {
          id: 93,
          label: "GG Harrison ",
          shape: "image",
          image: "./email-network.png",
          title: "GG@designersspecialty.com",
        },
        {
          id: 94,
          label: "Jillian Higginbotham ",
          shape: "image",
          image: "./email-network.png",
          title: "jHigginbotham@austin-ind.com",
        },
        {
          id: 95,
          label: "Rachel Whitman ",
          shape: "image",
          image: "./email-network.png",
          title: "Rachel.Whitman@tcelectric.com",
        },
        {
          id: 96,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "AKinsey@GilbaneCo.com",
        },
        {
          id: 97,
          label: "jay@aerialinnovations.com",
          shape: "image",
          image: "./email-network.png",
          title: "jay@aerialinnovations.com",
        },
        {
          id: 98,
          label: "Scott Belew ",
          shape: "image",
          image: "./email-network.png",
          title: "SBelew@austin-ind.com",
        },
        {
          id: 99,
          label: "jorge@turnkeymechanical.com",
          shape: "image",
          image: "./email-network.png",
          title: "jorge@turnkeymechanical.com",
        },
        {
          id: 100,
          label: "Becky Jones ",
          shape: "image",
          image: "./email-network.png",
          title: "BJones@austingilbane.com",
        },
        {
          id: 101,
          label: "David Cope ",
          shape: "image",
          image: "./email-network.png",
          title: "dcope@austin-ind.com",
        },
        {
          id: 102,
          label: "Caroline McGraw ",
          shape: "image",
          image: "./email-network.png",
          title: "cmcgraw@austingilbane.com",
        },
        {
          id: 103,
          label: "Esteban Venegas ",
          shape: "image",
          image: "./email-network.png",
          title: "EVenegas@Bauerfoundations.com",
        },
        {
          id: 104,
          label: "Donaldson, Mary",
          shape: "image",
          image: "./email-network.png",
          title: "Mary.Donaldson@Lockton.com",
        },
        {
          id: 105,
          label: "James Sims ",
          shape: "image",
          image: "./email-network.png",
          title: "JSims@austingilbane.com",
        },
        {
          id: 106,
          label: "Hughes, Dennis",
          shape: "image",
          image: "./email-network.png",
          title: "dhughes@hksinc.com",
        },
        {
          id: 107,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "DLawson@partneresi.com",
        },
        {
          id: 108,
          label: "Jessy G. Cole",
          shape: "image",
          image: "./email-network.png",
          title: "jcole@Austin-Ind.com",
        },
        {
          id: 109,
          label: "Ruan Hill ",
          shape: "image",
          image: "./email-network.png",
          title: "RHill@bonnessinc.com",
        },
        {
          id: 110,
          label: "Shelly Reed ",
          shape: "image",
          image: "./email-network.png",
          title: "sreed@austingilbane.com",
        },
        {
          id: 111,
          label: "Rob Luke ",
          shape: "image",
          image: "./email-network.png",
          title: "RLuke@smithfence.com",
        },
        {
          id: 112,
          label: "david@shielsengineering.com",
          shape: "image",
          image: "./email-network.png",
          title: "david@shielsengineering.com",
        },
        {
          id: 113,
          label: "Karen King ",
          shape: "image",
          image: "./email-network.png",
          title: "kking@Austin-Ind.com",
        },
        {
          id: 114,
          label: "ACS ",
          shape: "image",
          image: "./email-network.png",
          title: "ACS@Austin-Ind.com",
        },
        {
          id: 115,
          label: " ABR ",
          shape: "image",
          image: "./email-network.png",
          title: "ABR@austin-ind.com",
        },
        {
          id: 116,
          label: "Scott McFarlane (scott.mcfarlane@mvwc.com)",
          shape: "image",
          image: "./email-network.png",
          title: "scott.mcfarlane@mvwc.com",
        },
        {
          id: 117,
          label: "Justin Strecker ",
          shape: "image",
          image: "./email-network.png",
          title: "JStrecker@sgrinc.com",
        },
        {
          id: 118,
          label: "Kathy Ison ",
          shape: "image",
          image: "./email-network.png",
          title: "kison@sgrinc.com",
        },
        {
          id: 119,
          label: "Tighe, Michael",
          shape: "image",
          image: "./email-network.png",
          title: "Michael.Tighe@marriott.com",
        },
        {
          id: 120,
          label: "Chad Morrow ",
          shape: "image",
          image: "./email-network.png",
          title: "CMorrow@morrowsteel.com",
        },
        {
          id: 121,
          label: "David Graham ",
          shape: "image",
          image: "./email-network.png",
          title: "dgraham@Austin-Ind.com",
        },
        {
          id: 122,
          label: "Solange Puckett ",
          shape: "image",
          image: "./email-network.png",
          title: "solange@ap-const.com",
        },
        {
          id: 123,
          label: "Robinson, David",
          shape: "image",
          image: "./email-network.png",
          title: "David.Robinson@hines.com",
        },
        {
          id: 124,
          label: "William McAdoo ",
          shape: "image",
          image: "./email-network.png",
          title: "wmcadoo@Austin-Ind.com",
        },
        {
          id: 125,
          label: "Robert (robert@crystalwaterscapes.com)",
          shape: "image",
          image: "./email-network.png",
          title: "robert@crystalwaterscapes.com",
        },
        {
          id: 126,
          label: "Philip Younavjak ",
          shape: "image",
          image: "./email-network.png",
          title: "pyounavjak@cives.com",
        },
        {
          id: 127,
          label: "John Curcio ",
          shape: "image",
          image: "./email-network.png",
          title: "John@southwestconstruction.comcastbiz.net",
        },
        {
          id: 128,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "James.Gabriel@cainhoyenterprises.com",
        },
        {
          id: 129,
          label: "darrell@turnertree-landscape.com",
          shape: "image",
          image: "./email-network.png",
          title: "darrell@turnertree-landscape.com",
        },
        {
          id: 130,
          label: "Robert Hill ",
          shape: "image",
          image: "./email-network.png",
          title: "RHill@bddeng.com",
        },
        {
          id: 131,
          label: "Jack Roberts ",
          shape: "image",
          image: "./email-network.png",
          title: "JRoberts@Austin-Ind.com",
        },
        {
          id: 132,
          label: "Chuck Hunt ",
          shape: "image",
          image: "./email-network.png",
          title: "chunt@ionelectricllc.com",
        },
        {
          id: 133,
          label: "Dewayne Watkins ",
          shape: "image",
          image: "./email-network.png",
          title: "DWatkins@Austin-Ind.com",
        },
        {
          id: 134,
          label: "mary.donaldson@lockton.com",
          shape: "image",
          image: "./email-network.png",
          title: "mary.donaldson@lockton.com",
        },
        {
          id: 135,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "dlacamera@austingilbane.com",
        },
        {
          id: 136,
          label: "McLellan, Jimmy",
          shape: "image",
          image: "./email-network.png",
          title: "jmclellan@natlfire.com",
        },
        {
          id: 137,
          label: " ",
          shape: "image",
          image: "./email-network.png",
          title: "ttabor@natlfire.com",
        },
        {
          id: 138,
          label: "Melanie Yeakley ",
          shape: "image",
          image: "./email-network.png",
          title: "myeakley@Austin-Ind.com",
        },
        {
          id: 139,
          label: "Tom Neil ",
          shape: "image",
          image: "./email-network.png",
          title: "tomneil@Austin-Ind.com",
        },
        {
          id: 140,
          label: " Amber Lemmen ",
          shape: "image",
          image: "./email-network.png",
          title: "ALemmen@austin-ind.com",
        },
        {
          id: 141,
          label: "Jonathan Craig ",
          shape: "image",
          image: "./email-network.png",
          title: "JCraig@Austin-Ind.com",
        },
        {
          id: 142,
          label: " ACLP ",
          shape: "image",
          image: "./email-network.png",
          title: "ACLP@austin-ind.com",
        },
        {
          id: 143,
          label: " Shelly Reed ",
          shape: "image",
          image: "./email-network.png",
          title: "sreed@austin-ind.com",
        },
        {
          id: 144,
          label: "Jeff Abraham ",
          shape: "image",
          image: "./email-network.png",
          title: "JAbraham@Austin-Ind.com",
        },
      ],
      edges: [
        { from: 1, to: 2 },
        { from: 3, to: 4 },
        { from: 3, to: 5 },
        { from: 3, to: 6 },
        { from: 3, to: 1 },
        { from: 7, to: 3 },
        { from: 3, to: 7 },
        { from: 1, to: 8 },
        { from: 3, to: 4 },
        { from: 1, to: 9 },
        { from: 10, to: 11 },
        { from: 3, to: 12 },
        { from: 13, to: 14 },
        { from: 3, to: 7 },
        { from: 3, to: 4 },
        { from: 3, to: 7 },
        { from: 3, to: 7 },
        { from: 7, to: 8 },
        { from: 7, to: 8 },
        { from: 8, to: 7 },
        { from: 15, to: 8 },
        { from: 8, to: 7 },
        { from: 7, to: 16 },
        { from: 17, to: 18 },
        { from: 19, to: 20 },
        { from: 21, to: 22 },
        { from: 21, to: 23 },
        { from: 21, to: 24 },
        { from: 21, to: 24 },
        { from: 21, to: 17 },
        { from: 21, to: 20 },
        { from: 21, to: 25 },
        { from: 21, to: 17 },
        { from: 21, to: 17 },
        { from: 21, to: 17 },
        { from: 26, to: 1 },
        { from: 26, to: 9 },
        { from: 22, to: 21 },
        { from: 25, to: 21 },
        { from: 22, to: 21 },
        { from: 22, to: 27 },
        { from: 24, to: 21 },
        { from: 24, to: 21 },
        { from: 20, to: 24 },
        { from: 28, to: 7 },
        { from: 20, to: 22 },
        { from: 20, to: 21 },
        { from: 20, to: 21 },
        { from: 20, to: 21 },
        { from: 20, to: 29 },
        { from: 20, to: 30 },
        { from: 21, to: 20 },
        { from: 1, to: 18 },
        { from: 1, to: 18 },
        { from: 20, to: 31 },
        { from: 8, to: 18 },
        { from: 8, to: 7 },
        { from: 8, to: 7 },
        { from: 8, to: 18 },
        { from: 1, to: 32 },
        { from: 33, to: 1 },
        { from: 8, to: 33 },
        { from: 34, to: 14 },
        { from: 7, to: 34 },
        { from: 1, to: 26 },
        { from: 1, to: 9 },
        { from: 19, to: 20 },
        { from: 19, to: 25 },
        { from: 24, to: 21 },
        { from: 19, to: 25 },
        { from: 3, to: 7 },
        { from: 7, to: 8 },
        { from: 28, to: 3 },
        { from: 3, to: 28 },
        { from: 20, to: 35 },
        { from: 1, to: 7 },
        { from: 7, to: 1 },
        { from: 8, to: 1 },
        { from: 1, to: 8 },
        { from: 3, to: 36 },
        { from: 24, to: 21 },
        { from: 37, to: 20 },
        { from: 38, to: 1 },
        { from: 3, to: 39 },
        { from: 21, to: 20 },
        { from: 40, to: 20 },
        { from: 1, to: 38 },
        { from: 38, to: 1 },
        { from: 1, to: 8 },
        { from: 21, to: 20 },
        { from: 20, to: 41 },
        { from: 20, to: 18 },
        { from: 8, to: 7 },
        { from: 8, to: 7 },
        { from: 21, to: 37 },
        { from: 42, to: 18 },
        { from: 19, to: 20 },
        { from: 21, to: 25 },
        { from: 19, to: 43 },
        { from: 19, to: 25 },
        { from: 8, to: 44 },
        { from: 8, to: 7 },
        { from: 7, to: 8 },
        { from: 25, to: 45 },
        { from: 21, to: 17 },
        { from: 21, to: 17 },
        { from: 46, to: 21 },
        { from: 47, to: 19 },
        { from: 3, to: 4 },
        { from: 43, to: 48 },
        { from: 49, to: 50 },
        { from: 47, to: 48 },
        { from: 10, to: 7 },
        { from: 10, to: 25 },
        { from: 25, to: 10 },
        { from: 34, to: 8 },
        { from: 1, to: 18 },
        { from: 45, to: 25 },
        { from: 1, to: 8 },
        { from: 1, to: 8 },
        { from: 20, to: 31 },
        { from: 42, to: 18 },
        { from: 31, to: 20 },
        { from: 40, to: 51 },
        { from: 40, to: 52 },
        { from: 20, to: 41 },
        { from: 8, to: 18 },
        { from: 34, to: 18 },
        { from: 7, to: 18 },
        { from: 40, to: 18 },
        { from: 20, to: 18 },
        { from: 25, to: 8 },
        { from: 7, to: 18 },
        { from: 20, to: 21 },
        { from: 19, to: 20 },
        { from: 20, to: 25 },
        { from: 13, to: 14 },
        { from: 1, to: 34 },
        { from: 34, to: 1 },
        { from: 1, to: 18 },
        { from: 1, to: 53 },
        { from: 25, to: 13 },
        { from: 19, to: 25 },
        { from: 46, to: 21 },
        { from: 21, to: 17 },
        { from: 54, to: 55 },
        { from: 8, to: 18 },
        { from: 3, to: 7 },
        { from: 7, to: 3 },
        { from: 3, to: 7 },
        { from: 21, to: 17 },
        { from: 40, to: 56 },
        { from: 40, to: 57 },
        { from: 40, to: 52 },
        { from: 40, to: 51 },
        { from: 40, to: 57 },
        { from: 21, to: 20 },
        { from: 58, to: 54 },
        { from: 21, to: 17 },
        { from: 24, to: 21 },
        { from: 8, to: 7 },
        { from: 19, to: 25 },
        { from: 21, to: 24 },
        { from: 3, to: 36 },
        { from: 22, to: 21 },
        { from: 59, to: 21 },
        { from: 7, to: 8 },
        { from: 17, to: 25 },
        { from: 54, to: 18 },
        { from: 49, to: 25 },
        { from: 25, to: 49 },
        { from: 21, to: 19 },
        { from: 20, to: 41 },
        { from: 21, to: 17 },
        { from: 20, to: 35 },
        { from: 20, to: 60 },
        { from: 20, to: 61 },
        { from: 1, to: 62 },
        { from: 8, to: 18 },
        { from: 20, to: 19 },
        { from: 20, to: 63 },
        { from: 54, to: 55 },
        { from: 8, to: 18 },
        { from: 64, to: 65 },
        { from: 64, to: 65 },
        { from: 64, to: 65 },
        { from: 20, to: 21 },
        { from: 8, to: 34 },
        { from: 58, to: 54 },
        { from: 66, to: 20 },
        { from: 19, to: 20 },
        { from: 7, to: 8 },
        { from: 27, to: 20 },
        { from: 7, to: 8 },
        { from: 8, to: 18 },
        { from: 8, to: 7 },
        { from: 7, to: 8 },
        { from: 8, to: 15 },
        { from: 8, to: 67 },
        { from: 15, to: 8 },
        { from: 66, to: 27 },
        { from: 1, to: 33 },
        { from: 19, to: 20 },
        { from: 7, to: 8 },
        { from: 1, to: 33 },
        { from: 1, to: 18 },
        { from: 1, to: 18 },
        { from: 21, to: 20 },
        { from: 1, to: 18 },
        { from: 1, to: 18 },
        { from: 20, to: 68 },
        { from: 25, to: 21 },
        { from: 21, to: 25 },
        { from: 13, to: 14 },
        { from: 40, to: 69 },
        { from: 33, to: 7 },
        { from: 21, to: 17 },
        { from: 27, to: 66 },
        { from: 7, to: 18 },
        { from: 7, to: 3 },
        { from: 3, to: 7 },
        { from: 3, to: 7 },
        { from: 7, to: 3 },
        { from: 3, to: 7 },
        { from: 40, to: 70 },
        { from: 8, to: 7 },
        { from: 1, to: 8 },
        { from: 1, to: 71 },
        { from: 10, to: 7 },
        { from: 8, to: 33 },
        { from: 1, to: 8 },
        { from: 17, to: 72 },
        { from: 17, to: 46 },
        { from: 73, to: 74 },
        { from: 75, to: 14 },
        { from: 40, to: 76 },
        { from: 22, to: 40 },
        { from: 7, to: 18 },
        { from: 40, to: 77 },
        { from: 40, to: 76 },
        { from: 20, to: 40 },
        { from: 20, to: 78 },
        { from: 8, to: 1 },
        { from: 10, to: 7 },
        { from: 20, to: 69 },
        { from: 8, to: 18 },
        { from: 40, to: 18 },
        { from: 79, to: 20 },
        { from: 22, to: 20 },
        { from: 1, to: 10 },
        { from: 8, to: 80 },
        { from: 67, to: 53 },
        { from: 20, to: 66 },
        { from: 20, to: 30 },
        { from: 81, to: 40 },
        { from: 81, to: 56 },
        { from: 20, to: 40 },
        { from: 21, to: 17 },
        { from: 8, to: 44 },
        { from: 44, to: 8 },
        { from: 20, to: 40 },
        { from: 40, to: 82 },
        { from: 40, to: 83 },
        { from: 40, to: 84 },
        { from: 1, to: 18 },
        { from: 20, to: 17 },
        { from: 21, to: 49 },
        { from: 66, to: 20 },
        { from: 20, to: 85 },
        { from: 20, to: 46 },
        { from: 20, to: 37 },
        { from: 27, to: 40 },
        { from: 54, to: 55 },
        { from: 42, to: 18 },
        { from: 20, to: 27 },
        { from: 40, to: 22 },
        { from: 25, to: 18 },
        { from: 20, to: 21 },
        { from: 20, to: 25 },
        { from: 58, to: 86 },
        { from: 19, to: 20 },
        { from: 87, to: 88 },
        { from: 89, to: 18 },
        { from: 90, to: 20 },
        { from: 40, to: 66 },
        { from: 27, to: 66 },
        { from: 21, to: 25 },
        { from: 20, to: 21 },
        { from: 91, to: 59 },
        { from: 25, to: 18 },
        { from: 20, to: 19 },
        { from: 25, to: 92 },
        { from: 93, to: 89 },
        { from: 25, to: 21 },
        { from: 20, to: 68 },
        { from: 15, to: 7 },
        { from: 40, to: 20 },
        { from: 20, to: 31 },
        { from: 24, to: 94 },
        { from: 95, to: 20 },
        { from: 20, to: 21 },
        { from: 7, to: 8 },
        { from: 7, to: 10 },
        { from: 42, to: 20 },
        { from: 25, to: 96 },
        { from: 25, to: 18 },
        { from: 25, to: 91 },
        { from: 25, to: 18 },
        { from: 25, to: 40 },
        { from: 20, to: 18 },
        { from: 25, to: 33 },
        { from: 20, to: 97 },
        { from: 8, to: 98 },
        { from: 20, to: 99 },
        { from: 25, to: 18 },
        { from: 25, to: 18 },
        { from: 7, to: 33 },
        { from: 100, to: 18 },
        { from: 25, to: 7 },
        { from: 20, to: 17 },
        { from: 20, to: 17 },
        { from: 58, to: 18 },
        { from: 66, to: 25 },
        { from: 101, to: 102 },
        { from: 90, to: 18 },
        { from: 20, to: 18 },
        { from: 7, to: 25 },
        { from: 40, to: 20 },
        { from: 103, to: 20 },
        { from: 17, to: 18 },
        { from: 104, to: 7 },
        { from: 8, to: 33 },
        { from: 17, to: 18 },
        { from: 105, to: 18 },
        { from: 106, to: 20 },
        { from: 19, to: 20 },
        { from: 20, to: 107 },
        { from: 7, to: 85 },
        { from: 17, to: 66 },
        { from: 20, to: 19 },
        { from: 19, to: 20 },
        { from: 46, to: 18 },
        { from: 17, to: 18 },
        { from: 102, to: 101 },
        { from: 102, to: 108 },
        { from: 1, to: 7 },
        { from: 105, to: 58 },
        { from: 105, to: 58 },
        { from: 46, to: 18 },
        { from: 46, to: 18 },
        { from: 92, to: 17 },
        { from: 109, to: 40 },
        { from: 20, to: 18 },
        { from: 20, to: 88 },
        { from: 19, to: 110 },
        { from: 92, to: 17 },
        { from: 110, to: 19 },
        { from: 20, to: 111 },
        { from: 20, to: 41 },
        { from: 20, to: 41 },
        { from: 20, to: 112 },
        { from: 110, to: 20 },
        { from: 20, to: 112 },
        { from: 17, to: 92 },
        { from: 20, to: 18 },
        { from: 20, to: 105 },
        { from: 25, to: 18 },
        { from: 20, to: 20 },
        { from: 88, to: 20 },
        { from: 7, to: 10 },
        { from: 1, to: 18 },
        { from: 19, to: 18 },
        { from: 7, to: 18 },
        { from: 110, to: 20 },
        { from: 19, to: 18 },
        { from: 20, to: 110 },
        { from: 1, to: 18 },
        { from: 25, to: 18 },
        { from: 113, to: 114 },
        { from: 113, to: 115 },
        { from: 7, to: 37 },
        { from: 1, to: 18 },
        { from: 113, to: 114 },
        { from: 113, to: 115 },
        { from: 7, to: 10 },
        { from: 25, to: 116 },
        { from: 33, to: 7 },
        { from: 33, to: 10 },
        { from: 25, to: 7 },
        { from: 25, to: 10 },
        { from: 34, to: 101 },
        { from: 17, to: 18 },
        { from: 1, to: 18 },
        { from: 20, to: 18 },
        { from: 20, to: 18 },
        { from: 101, to: 34 },
        { from: 101, to: 18 },
        { from: 117, to: 118 },
        { from: 20, to: 19 },
        { from: 1, to: 18 },
        { from: 1, to: 18 },
        { from: 1, to: 18 },
        { from: 46, to: 18 },
        { from: 1, to: 18 },
        { from: 46, to: 18 },
        { from: 119, to: 20 },
        { from: 17, to: 120 },
        { from: 7, to: 37 },
        { from: 37, to: 18 },
        { from: 37, to: 7 },
        { from: 25, to: 46 },
        { from: 25, to: 18 },
        { from: 7, to: 37 },
        { from: 37, to: 7 },
        { from: 37, to: 18 },
        { from: 46, to: 25 },
        { from: 20, to: 18 },
        { from: 25, to: 92 },
        { from: 25, to: 121 },
        { from: 8, to: 122 },
        { from: 17, to: 25 },
        { from: 25, to: 92 },
        { from: 123, to: 17 },
        { from: 46, to: 25 },
        { from: 124, to: 25 },
        { from: 25, to: 89 },
        { from: 10, to: 1 },
        { from: 102, to: 25 },
        { from: 123, to: 66 },
        { from: 20, to: 88 },
        { from: 3, to: 125 },
        { from: 34, to: 25 },
        { from: 20, to: 31 },
        { from: 126, to: 18 },
        { from: 20, to: 105 },
        { from: 34, to: 18 },
        { from: 40, to: 126 },
        { from: 40, to: 127 },
        { from: 20, to: 102 },
        { from: 7, to: 128 },
        { from: 20, to: 68 },
        { from: 20, to: 102 },
        { from: 128, to: 7 },
        { from: 128, to: 10 },
        { from: 20, to: 129 },
        { from: 105, to: 130 },
        { from: 131, to: 20 },
        { from: 37, to: 18 },
        { from: 7, to: 44 },
        { from: 25, to: 132 },
        { from: 131, to: 7 },
        { from: 131, to: 102 },
        { from: 25, to: 132 },
        { from: 102, to: 133 },
        { from: 25, to: 128 },
        { from: 20, to: 130 },
        { from: 20, to: 18 },
        { from: 20, to: 105 },
        { from: 7, to: 134 },
        { from: 20, to: 110 },
        { from: 126, to: 135 },
        { from: 136, to: 25 },
        { from: 136, to: 137 },
        { from: 25, to: 18 },
        { from: 105, to: 138 },
        { from: 8, to: 7 },
        { from: 103, to: 20 },
        { from: 126, to: 18 },
        { from: 25, to: 100 },
        { from: 126, to: 102 },
        { from: 7, to: 18 },
        { from: 139, to: 18 },
        { from: 53, to: 80 },
        { from: 53, to: 140 },
        { from: 53, to: 80 },
        { from: 53, to: 140 },
        { from: 141, to: 115 },
        { from: 141, to: 142 },
        { from: 25, to: 18 },
        { from: 25, to: 18 },
        { from: 34, to: 18 },
        { from: 34, to: 143 },
        { from: 7, to: 18 },
        { from: 21, to: 20 },
        { from: 7, to: 18 },
        { from: 59, to: 28 },
        { from: 3, to: 18 },
        { from: 25, to: 10 },
        { from: 33, to: 7 },
        { from: 58, to: 18 },
        { from: 25, to: 1 },
        { from: 8, to: 144 },
        { from: 7, to: 18 },
        { from: 44, to: 25 },
        { from: 33, to: 67 },
        { from: 33, to: 25 },
        { from: 25, to: 33 },
        { from: 131, to: 58 },
        { from: 34, to: 58 },
        { from: 58, to: 34 },
        { from: 85, to: 7 },
        { from: 8, to: 18 },
        { from: 8, to: 7 },
        { from: 7, to: 18 },
        { from: 10, to: 7 },
        { from: 7, to: 37 },
        { from: 7, to: 18 },
        { from: 37, to: 7 },
        { from: 37, to: 18 },
        { from: 7, to: 34 },
        { from: 8, to: 7 },
        { from: 7, to: 37 },
      ],
    },
    events: {
      select: ({ nodes, edges }: any) => {
        alert("Selected node: " + nodes);
      },
      doubleClick: ({ pointer: { canvas } }: any) => {
        //createNode(canvas.x, canvas.y);
      },
    },
  });
  const { graph, events } = state;

  return (
    <div>
      <Graph
        graph={graph}
        options={options}
        /* events={events} */
        style={{ height: "640px" }}
      />
    </div>
  );
};

export default PeopleGraph;

import ModuleLayout from "components/ModuleLayout";
import ManageProjects from "./ManageProjects";

const ProjectLayout = () => {
  return (
    <>
      <ModuleLayout title="">
        <ManageProjects />
      </ModuleLayout>
    </>
  );
};

export default ProjectLayout;

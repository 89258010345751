import { SortDescriptor, State, process } from "@progress/kendo-data-query";
import { clone, getter } from "@progress/kendo-react-common";
import {
  GridRowClickEvent,
  GridRowDoubleClickEvent,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { useInternationalization } from "@progress/kendo-react-intl";
import {
  BreadcrumbLinkMouseEvent,
  Splitter,
  SplitterOnChangeEvent,
} from "@progress/kendo-react-layout";
import { Offset } from "@progress/kendo-react-popup";
import { TreeViewItemClickEvent } from "@progress/kendo-react-treeview";
import { UploadFileInfo } from "@progress/kendo-react-upload";
import "@progress/kendo-theme-default/dist/all.css";
import AWS from "aws-sdk";
import config from "../../services/configs/config";
import { addToFolders } from "../../services/filingcabinet/filingcabinet.service";

import * as React from "react";

import { BreadcrumbComponent } from "../../components/filecabinet/components/Breadcrumb";
import {
  AddDialog,
  DeleteDialog,
} from "../../components/filecabinet/components/Dialog";
import { FileInformation } from "../../components/filecabinet/components/FileInformation";
import { FileManagerToolbar } from "../../components/filecabinet/components/FileManagerToolbar";
import { FolderTree } from "../../components/filecabinet/components/FolderTree";
import { GridView } from "../../components/filecabinet/components/GridView";
import { ListView } from "../../components/filecabinet/components/ListView";

import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ToastMessage, { IToast } from "../../components/ToastMessage";
import { IFileCabinetReference } from "../../components/chat/types";
import { ContextMenu } from "../../components/filecabinet/components/ContextMenu";
import {
  addDataItem,
  convertExtensionToIcon,
  convertToBreadcrumbData,
  createFolder,
  createRootFolder,
  findParentFolder,
  formatData,
  getFolderPath,
  getFolderTree,
  removeDataItem,
  renameFolder,
  searchTreeItem,
} from "../../components/filecabinet/helpers/helperMethods";
import {
  AppSwitchChangeEvent,
  ContextMenuEvent,
  DataModel,
  PanesModel,
  SelectedItemType,
  SortChangeEvent,
  SplitBtnItems,
  UploadAddEvent,
  ViewChangeEvent,
} from "../../components/filecabinet/interfaces/FileManagerModels";
import NotesForm from "../../components/notes/NotesForm";
import aiSevice from "../../services/ai/ai.services";
import {
  deleteFolderOrFile,
  fileupload,
  getChat,
  getFiles,
} from "../../services/filingcabinet/filingcabinet.service";
import { FolderRequest } from "../../services/types/filing.cabinet.types";
import Constants from "../../services/utils/Constants";
const splitterPanes: PanesModel[] = [
  {
    size: "15%",
    min: "20px",
    collapsible: true,
  },
  {},
  {
    size: "40%",
    min: "20px",
    collapsible: true,
  },
];

const initialSort: SortDescriptor[] = [
  {
    field: "path",
    dir: "asc",
  },
];
const s3 = new AWS.S3({
  accessKeyId: config.accessKeyId,
  secretAccessKey: config.secretAccessKey,
  region: "nyc3",
  endpoint: config.endpoint,
});
const DATA_ITEM_KEY = "path";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const FileCabinet = ({ data }) => {
  const intl = useInternationalization();

  // const [stateData, setStateData] = useState<DataModel[]>(
  //   formatData(serverData, intl)
  // );
  const [stateData, setStateData] = useState<DataModel[]>([]);
  const [panes, setPanes] = useState<PanesModel[]>(splitterPanes);
  const [selectedGridItem, setSelectedGridItem] = useState<DataModel>({});
  const [selectedTreeItem, setSelectedTreeItem] = useState<DataModel | null>(
    null
  );
  const [gridSelection, setGridSelection] = useState<{
    [id: string]: boolean | number[];
  }>({});

  const [detailsData, setDetailsData] = useState<null | number | Object>(null);
  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const [contentView, setContentView] = useState<string>("grid");
  const [contextMenuView, setContextMenuView] = useState<boolean>(false);
  const [menuOffSet, setMenuOffSet] = useState<Offset>({
    left: 0,
    top: 0,
  });
  const [addDialogView, setAddDialogView] = useState<boolean>(false);
  const [addRootDialogView, setAddRootDialogView] = useState<boolean>(false);
  const [editDialogView, setEditDialogView] = useState<boolean>(false);
  const [deleteDialogView, setDeleteDialogView] = useState<boolean>(false);
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  const [chatDrawerData, setChatDrawerData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const location = useLocation();
  const info = location.state;
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const mi = params.get("matterid");
  const [selectedMatterId, setSelectedMatterId] = useState(
    mi || (info?.project ? info.project.id : null)
  );
  const [selectedMatter, setSelectedMatter] = useState(info?.project || null);
  const history = useNavigate();
  if (info?.project?.id === selectedMatterId) {
    history(`/folder?matterid=${selectedMatterId}`);
  }
  const [isMatterSelected, setIsMatterSelected] = useState(true);

  // const [selectedMatterId, setSelectedMatterId] = useState(
  //   data ? data.project.id : -1
  // );
  // const [selectedMatter, setSelectedMatter] = useState(
  //   data ? data.project : null
  // );

  const [toast, setToast] = useState<IToast>({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });

  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };

  const setToastMessage = (title: string, message: string, type?: string) => {
    setToast({
      ...toast,
      openState: true,
      title: title,
      message: message,
      onCloseToast: onCloseToast,
      messageType:
        type == "error" ? "bg-danger text-white" : "bg-primary text-white",
    });
  };

  const splitBtnItems: SplitBtnItems[] = [
    { text: "Name", value: "path" },
    { text: "File Size", value: "size" },
    { text: "Date Created", value: "dateCreated" },
  ];

  const initialLogic: "and" | "or" = "and";

  const [stateContentData, setStateContentData] = useState<State>({
    sort: initialSort,
    filter: {
      logic: initialLogic,
      filters: [{ field: "path", operator: "contains", value: "" }],
    },
  });

  const getChatResponse = async (chatId) => {
    const chatDataResponse = await getChat(chatId);

    if (chatDataResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      const data = chatDataResponse?.data[0];

      const chat = JSON.stringify(data.chat);

      return "Testing";
    } else {
      return "Unable to fetch chat data";
    }
  };

  const updateFileDetailsData = useCallback(
    async (selection: SelectedItemType) => {
      let data: any = selection;
      if (data.path) {
        const newSelectedItem = searchTreeItem(stateData, data);

        if (data?.type == "chat" || data?.type == "note") {
          const chatDataResponse = await getChat(data.chat_id);

          if (chatDataResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
            const data = chatDataResponse?.data[0];

            const chat = JSON.parse(data.chat);
            newSelectedItem.data = chat;
          } else {
            newSelectedItem.data = "Unable to fetch chat data";
          }
        } else if (data?.type == "File") {
          if (data.path.includes("txt")) {
            const fileDataResponse = await aiSevice.getFileResponse(
              selectedMatterId,
              data.original_path
            );

            if (fileDataResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
              const data = fileDataResponse?.data;
              newSelectedItem.data = data;
              newSelectedItem.type = "File";
            } else {
              newSelectedItem.data = "Unable to fetch chat data";
              newSelectedItem.type = "TODO";
            }
          } else if (data.path.includes("json")) {
            newSelectedItem.data = data;
            newSelectedItem.type = "json";
          } else if (data.path.includes("pdf")) {
            newSelectedItem.data = data;
            newSelectedItem.type = "pdf";
          } else if (data.path.includes(".doc")) {
            newSelectedItem.data = data;
            newSelectedItem.type = "doc";
          } else if (data.path.includes(".docx")) {
            newSelectedItem.data = data;
            newSelectedItem.type = "docx";
          } else {
            newSelectedItem.type = "TODO";
          }
        } else if (data?.type == "note") {
          const fileDataResponse = await aiSevice.getDocumentContent(
            selectedMatterId
          );

          if (fileDataResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
            const data = fileDataResponse?.data;
            newSelectedItem.data = data;
            newSelectedItem.type = "File";
          } else {
            newSelectedItem.data = "Unable to fetch chat data";
          }
        }
        setDetailsData(newSelectedItem);
      } else if (!selection.path && Object.keys(selection).length) {
        setDetailsData(Object.keys(selection).length);
      } else {
        setDetailsData(null);
      }
    },
    [stateData]
  );

  const handleChatAction = (event: any, selectedChat: any) => {
    event.preventDefault();
    const chat = JSON.stringify(selectedChat);
    setOpenChatDrawer(!openChatDrawer);
    setChatDrawerData(selectedChat);
  };

  const handleTreeItemClick = (event: TreeViewItemClickEvent) => {
    //  console.log(event,'click event');
    const newSelectedItem: DataModel = searchTreeItem(stateData, event.item);
    newSelectedItem.expanded = !event.item.expanded;
    // console.log(event.item.expanded, 'expand');
    setSelectedTreeItem(newSelectedItem);
    setDetailsData(event.item);
    setSelectedGridItem({});
    setGridSelection({});
  };

  const handleSplitterChange = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  const handleSelectionChange = (event: GridRowClickEvent) => {
    if (event.dataItem.type == "chat") {
      event.dataItem["data"] = "Chat data can be populated here";
    }
    setSelectedGridItem(event.dataItem);
    updateFileDetailsData(event.dataItem);
    if (event.dataItem.path) {
      const key = event.dataItem["path"].toString();
      setGridSelection({ [key]: true });
    }
  };

  const handleMultipleSelection = (event: any) => {
    const newSelection = getSelectedState({
      event,
      selectedState: gridSelection,
      dataItemKey: DATA_ITEM_KEY,
    });

    setGridSelection(newSelection);
    updateFileDetailsData(newSelection);
  };

  const handleSortChange = (event: SortChangeEvent) => {
    const newSortedData = clone(stateContentData);

    if (event.direction === "asc" || event.direction === "desc") {
      newSortedData.sort[0].dir = event.direction;
    }

    if (event.item) {
      newSortedData.sort[0].field = event.item.value;
    }

    if (event.sort) {
      newSortedData.sort = event.sort;
    }
    setStateContentData(newSortedData);
  };

  const handleGridDoubleClick = (event: GridRowDoubleClickEvent) => {
    const itemType = convertExtensionToIcon(event.dataItem.path);
    if (itemType?.type === "Folder") {
      if (selectedTreeItem) {
        selectedTreeItem.expanded = true;
      }

      const newSelectedItem = event.dataItem;
      newSelectedItem.expanded = true;
      // setSelectedTreeItem(searchTreeItem(stateData, newSelectedItem));
    } else if (itemType?.type === "chat") {
      if (event.dataItem.type == "chat") {
        event.dataItem["data"] = "Chat data can be populated here";
      }

      setSelectedGridItem(event.dataItem);
      updateFileDetailsData(event.dataItem);
    }
  };

  const handleNewFolderClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    options: any
  ) => {
    if (options == Constants.FileCabinet.ADD) {
      setAddDialogView(true);
    } else if (options == "root") {
      setAddRootDialogView(true);
    }

    //setAddRootDialogView(true);
    //setAddDialogView(true);
  };

  const handleSearchChange = (event: InputChangeEvent) => {
    let eventPath = "";
    if (selectedTreeItem?.items) {
      const curPath = getFolderPath(selectedTreeItem.items[0].path);
      eventPath = `${curPath}/${event.value}`;
    } else {
      const curPath = getFolderPath(stateData[0].path);
      eventPath = `${curPath}/${event.value}`;
    }

    setStateContentData({
      ...stateContentData,
      filter: {
        logic: initialLogic,
        filters: [{ field: "path", operator: "contains", value: eventPath }],
      },
    });
  };

  const handleSwitchChange = (event: AppSwitchChangeEvent) => {
    const newPanes: PanesModel[] = panes.slice(0);
    if (event.value) {
      newPanes[2].size = "30%";
      setPanes(newPanes);
    } else {
      newPanes[2].size = "0%";
      setPanes(newPanes);
    }
  };

  const handleViewChange = (event: ViewChangeEvent) => {
    if (event.viewValue.gridView) {
      setContentView("grid");
    }
    if (event.viewValue.listView) {
      setContentView("list");
    }
  };

  const handleFileChange = (event: UploadAddEvent) => {
    if (event.files) {
      setFiles(event.files);
    }
  };

  const handleClearFileList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (event) {
      setFiles([]);
    }
  };

  const handleUploadComplete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const newData: DataModel | any = addDataItem(
      stateData,
      selectedTreeItem,
      files,
      intl
    );

    setStateData(newData);
    setFiles([]);
  };

  const handleBreadcrumbSelection = (event: BreadcrumbLinkMouseEvent) => {
    setSelectedGridItem({});
    setGridSelection({});

    if (!event.nativeEvent.keyCode) {
      const newItem = searchTreeItem(stateData, { id: -1, path: event.id });
      setSelectedTreeItem(newItem);
    }

    if (event.nativeEvent.keyCode === 13) {
      const newItem = searchTreeItem(stateData, { id: -1, path: event.id });
      setSelectedTreeItem(newItem);
    }
  };

  const _createRootFolder = async (event: any) => {
    const path = event.path ? `${event.path}/${event.value}` : event.value;
    const rootFolderData: FolderRequest = {
      folder_name: path,
      matter_id: selectedMatterId,
    };
    const addToFolderResponse = await createRootFolder(rootFolderData);

    if (addToFolderResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      setToastMessage(
        "Success",
        "Folder has been added to the filing cabinet."
      );

      updateFolders(addToFolderResponse.data);
    } else {
      setToastMessage("Error", "Unable to create folder, try again later.");
    }
    setAddDialogView(false);
    setAddRootDialogView(false);
  };

  const handleAddToFolder = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    const input = document.createElement("input");
    input.type = "file";
    // input.multiple = true;
    input.style.display = "none";
    document.body.appendChild(input);
    input.click();

    const fileSelectionPromise = new Promise<FileList | null>((resolve) => {
      input.addEventListener("change", () => {
        resolve(input.files);
      });
    });

    const files = await fileSelectionPromise;
    setIsLoading(true);
    setUploadProgress(0);
    if (!files) {
      setIsLoading(false);
      return;
    }
    const fileReferences: {
      referenceType: string;
      filename: string;
      originalPath: string;
    }[] = [];
    // const uploadPromises: Promise<any>[] = [];

    for (let i = 0; i < files.length; i++) {
      const fname = files[i].name;
      const fileReference = {
        referenceType: "File",
        filename: fname,
        originalPath: fname,
      };
      fileReferences.push(fileReference);
    }

    try {
      // const uploadResponse = await fileupload(files, selectedMatterId);
      const uploadResponse = await fileupload(
        files,
        selectedMatterId,
        (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress); // Update progress
        }
      );
      for (let i = 0; i < fileReferences.length; i++) {
        try {
          const response = await addToFolders(
            selectedMatterId,
            fileReferences[i],
            [selectedTreeItem?.id]
          );
        } catch (error) {
          console.error("Error adding file to backend:", error);
        }
      }
      setToastMessage(
        "Success",
        "Files have been uploaded and added to the filing cabinet."
      );
    } catch (error) {
      console.error("Error uploading files:", error);
      setToastMessage("Error", "Failed to upload files.");
    } finally {
      setRefresh(true);
      setIsLoading(false);
    }
  };

  const handleDialogClick = async (event: any) => {
    if (event.type === "cancel") {
      setAddRootDialogView(false);
      setAddDialogView(false);
      setDeleteDialogView(false);
      setEditDialogView(false);
    }

    if (event.type === Constants.FileCabinet.ROOT) {
      _createRootFolder(event);
    }

    if (event.type === Constants.FileCabinet.ADD) {
      const path = event.path ? `${event.path}/${event.value}` : event.value;
      let newData;
      if (selectedTreeItem) {
        //newData = addFolder(stateData, selectedTreeItem, intl);

        const data: IFileCabinetReference = {
          filename: path,
          originalPath: path,
          referenceType: "Folder",
        };
        const addToFolderResponse = await createFolder(selectedMatterId, data, [
          selectedTreeItem.id,
        ]);

        let newFolder = "";
        if (addToFolderResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
          setToastMessage(
            "Success",
            "Folder has been added to the filing cabinet."
          );

          updateFolders(addToFolderResponse.data, selectedTreeItem.id);
        } else {
          setToastMessage("Error", "Unable to create folder, try again later.");
        }

        setAddDialogView(false);
        setAddRootDialogView(false);
      }
    }

    if (event.type === "delete") {
      const curItem: DataModel | null = Object.keys(selectedGridItem).length
        ? selectedGridItem
        : selectedTreeItem;
      if (!curItem) {
        return;
      }

      const newItem: string = curItem.path || "";
      const id: number = curItem.id || -1;

      const deleteResponse = await deleteFolderOrFile(selectedMatterId, id);

      setDeleteDialogView(false);
      if (deleteResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
        setRefresh(true);
        setSelectedGridItem({});
        setDetailsData({});
        setToastMessage("Success", "Folder/File has been delete.");
      } else {
        setToastMessage("Error", "Unable to delete folder, try again later.");
        return;
      }

      const oldPath = newItem.split("/");

      oldPath.pop();

      const updatedData = removeDataItem(stateData, curItem);
      let newItemPath = oldPath.join("/");

      // setSelectedTreeItem(
      //   searchTreeItem(updatedData, { id, path: newItemPath })
      // );
      setStateData(updatedData);
      setDeleteDialogView(false);
    }

    if (event.type === Constants.FileCabinet.RENAME) {
      const curItem: DataModel | null = Object.keys(selectedGridItem).length
        ? selectedGridItem
        : selectedTreeItem;
      if (!curItem) {
        return;
      }

      const splitFilename = event.path?.split(".");

      const extention =
        splitFilename.length > 1 ? `.${splitFilename.pop()}` : "";

      const folderPath = getFolderPath(event.path);

      let newPath = event.value;
      if (folderPath) newPath = `${folderPath}/${event.value}`;

      if (!/\.[0-9a-z]+$/i.test(newPath)) {
        newPath += extention;
      }
      const rootFolderData: FolderRequest = {
        folder_name: newPath,
        folder_id: curItem.id,
        matter_id: selectedMatterId,
      };
      const renameFolderResponse = await renameFolder(rootFolderData);

      if (renameFolderResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
        setRefresh((refresh) => !refresh);
        const data = renameFolderResponse.data;
        setToastMessage(
          "Success",
          "Folder has been added to the filing cabinet."
        );

        const existingItem = searchTreeItem(stateData, {
          id: data.id,
          path: newPath,
        });

        // if (!existingItem) {
        //   const updatedData = editDataItem(stateData, curItem, newPath);
        //   setSelectedTreeItem(
        //     searchTreeItem(updatedData, { id: data.id, path: newPath })
        //   );
        //   setStateData(updatedData);
        // }
        // setSelectedTreeItem({ id: data.id, path: data.path });
      } else {
        setToastMessage("Error", "Unable to create folder, try again later.");
      }

      setEditDialogView(false);
    }
  };
  const handleListItemClick = (event: any) => {
    let newSelectedGridItem: any = {};
    if (event.dataItem.path) {
      newSelectedGridItem = event.dataItem;
      newSelectedGridItem[event.dataItem.path] = true;
      newSelectedGridItem["selected"] = true;
      setSelectedGridItem(newSelectedGridItem);
      if (event.dataItem.path) {
        const key = event.dataItem.path.toString();
        setGridSelection({ [key]: true });
      }
    }

    if (event.tree) {
      const newSelectedTreeItem: DataModel = searchTreeItem(
        stateData,
        newSelectedGridItem
      );
      // setSelectedTreeItem(newSelectedTreeItem);
    }
    updateFileDetailsData(newSelectedGridItem);
  };

  const handleContextMenuView = (event: ContextMenuEvent) => {
    setContextMenuView(true);
    setMenuOffSet({ left: event.event.clientX, top: event.event.clientY });
    handleListItemClick(event);
  };

  const handleContextMenuClick = (event: ContextMenuEvent) => {
    const NEW_FOLDER = "0";
    const RENAME = "1";
    const DELETE = "2";

    switch (event.itemId) {
      case NEW_FOLDER:
        setAddDialogView(true);
        break;
      case RENAME:
        setEditDialogView(true);
        break;
      case DELETE:
        setDeleteDialogView(true);
        break;
    }
  };

  const handleDialogClose = () => {
    setAddDialogView(false);
    setAddRootDialogView(false);
    setDeleteDialogView(false);
    setEditDialogView(false);
  };

  document.addEventListener("click", () => {
    setContextMenuView(false);
  });
  const [refresh, setRefresh] = useState(false);
  const loadData = async (matter_id) => {
    const getFilesDataResponse: any = await getFiles(matter_id);
    if (getFilesDataResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      const formattedData = formatData(getFilesDataResponse.data, intl);
      setStateData(formattedData);
      // console.log(formattedData,'state data');
    }
  };

  const updateName = async (id, folderData) => {
    const updatedStateData = stateData.map((item, inx) => {
      if (item.id === id && item.matter_id == folderData.matter_id) {
        //stateData[inx].path = folderData.path;
        item.path = folderData.path;
      }
    });
  };

  const updateFolders = async (folderData, parent_node_ids: any = []) => {
    const folder: any = findParentFolder(parent_node_ids, stateData);
    if (folder) {
      const data: DataModel = folderData;
      data.icon = convertExtensionToIcon(data.path);
      if (folder.items) {
        folder.items.push(data);
      } else {
        folder.items = [];
        folder.items.push(data);
      }
    } else {
      setStateData((prevArray) => [...prevArray, folderData]);
    }
  };

  const handleMatterChange = (matter: any) => {
    setSelectedMatter(matter);
    setSelectedMatterId(+matter.id);
  };

  useEffect(() => {
    if (selectedMatterId !== -1) {
      loadData(selectedMatterId);
      setRefresh(false);
    }
  }, [selectedMatterId, refresh]);

  useEffect(() => {
    const path = selectedTreeItem?.path;
    const flattenData = (data) => {
      return data.reduce((acc, item) => {
        if (item.items) {
          acc.push(item);
          acc = acc.concat(flattenData(item.items));
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
    };

    const flattenedData = flattenData(stateData);
    const matchingItem = flattenedData.find((item) => item.path === path);

    if (matchingItem) {
      // console.log(matchingItem, 'after state update');
      setSelectedTreeItem(matchingItem);
    } else {
      setSelectedGridItem(matchingItem);
      console.log("no matching ", path);
    }
  }, [stateData]);

  // useEffect(()=>{

  //   console.log(selectedTreeItem,"selected Tree Item")

  // },[selectedTreeItem])

  return (
    <div className="k-widget k-filemanager k-filemanager-resizable">
      <NotesForm
        matter={selectedMatter}
        matter_id={selectedMatterId}
        context_type="file"
      />
      <ToastMessage {...toast} />;
      <div className="k-filemanager-header">
        <FileManagerToolbar
          splitItems={splitBtnItems}
          sort={stateContentData.sort}
          files={files}
          selectedMatter={selectedMatter}
          onNewFolderClick={handleNewFolderClick}
          onSearchChange={handleSearchChange}
          onSwitchChange={handleSwitchChange}
          onViewChange={handleViewChange}
          onSortChange={handleSortChange}
          onFileChange={handleFileChange}
          onClearFileList={handleClearFileList}
          // onUploadComplete={handleUploadComplete}
          onUploadComplete={handleAddToFolder}
          onMatterChange={handleMatterChange}
          refresh={refresh}
          isLoading={isLoading}
          uploadProgress={uploadProgress}
          isMatterSelected={isMatterSelected}
          setIsMatterSelected={setIsMatterSelected}
          setSelectedMatter={setSelectedMatter}
          selectedMatterId={selectedMatterId}
          setSelectedMatterId={setSelectedMatterId}
        />
      </div>
      <div className="k-filemanager-content-container w-100">
        <Splitter panes={panes} onChange={handleSplitterChange}>
          <FolderTree
            data={getFolderTree(stateData, selectedTreeItem)}
            selected={selectedTreeItem}
            selectedItem={selectedTreeItem}
            onItemClick={handleTreeItemClick}
            onContextMenu={handleContextMenuView}
            refresh={refresh}
          />
          <div className="k-filemanager-content">
            <BreadcrumbComponent
              data={convertToBreadcrumbData(selectedTreeItem)}
              onBreadcrumbSelect={handleBreadcrumbSelection}
            />
            {contentView === "grid" ? (
              <GridView
                data={
                  selectedTreeItem && selectedTreeItem.items
                    ? process(
                        selectedTreeItem.items.map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: gridSelection[idGetter(item)],
                        })),
                        stateContentData
                      )
                    : !selectedTreeItem
                    ? process(
                        stateData.map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: gridSelection[idGetter(item)],
                        })),
                        stateContentData
                      )
                    : null
                }
                sort={stateContentData.sort}
                dataItemKey={DATA_ITEM_KEY}
                onSelectionChange={handleSelectionChange}
                onMultipleSelection={handleMultipleSelection}
                onSortChange={handleSortChange}
                onDoubleClick={handleGridDoubleClick}
                onContextMenu={handleContextMenuView}
                refresh={refresh}
              />
            ) : (
              <ListView
                data={
                  selectedTreeItem && selectedTreeItem.items
                    ? process(
                        selectedTreeItem.items.map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: gridSelection[idGetter(item)],
                        })),
                        stateContentData
                      )
                    : !selectedTreeItem
                    ? process(
                        stateData.map((item) => ({
                          ...item,
                          [SELECTED_FIELD]: gridSelection[idGetter(item)],
                        })),
                        stateContentData
                      )
                    : null
                }
                refresh={refresh}
                onSelectionChange={handleSelectionChange}
                onMultipleSelection={handleMultipleSelection}
                onItemClick={handleListItemClick}
                onDoubleClick={handleGridDoubleClick}
                onContextMenu={handleContextMenuView}
              />
            )}
            {contextMenuView ? (
              <ContextMenu
                offset={menuOffSet}
                onContextMenuCLick={handleContextMenuClick}
              />
            ) : (
              ""
            )}
            {addDialogView || addRootDialogView ? (
              <AddDialog
                addValue={
                  Object.keys(selectedGridItem).length
                    ? selectedGridItem
                    : selectedTreeItem
                }
                action={
                  addDialogView == true
                    ? Constants.FileCabinet.ADD
                    : Constants.FileCabinet.ROOT
                }
                selectedTreeItem={selectedTreeItem}
                onDialogClose={handleDialogClose}
                onDialogClick={handleDialogClick}
                show={addDialogView || addRootDialogView}
                onHide={() => {
                  setAddRootDialogView(false);
                  setAddDialogView(false);
                }}
              />
            ) : (
              ""
            )}
            {editDialogView ? (
              <AddDialog
                addValue={
                  Object.keys(selectedGridItem).length
                    ? selectedGridItem
                    : selectedTreeItem
                }
                action="rename"
                selectedTreeItem={selectedTreeItem}
                onDialogClose={handleDialogClose}
                onDialogClick={handleDialogClick}
                show={editDialogView}
                onHide={() => setEditDialogView(false)}
              />
            ) : (
              ""
            )}
            {deleteDialogView ? (
              <DeleteDialog
                onDialogClose={handleDialogClose}
                onDialogClick={handleDialogClick}
                show={deleteDialogView}
                onHide={() => setDeleteDialogView(false)}
              />
            ) : (
              ""
            )}
          </div>
          <FileInformation
            matter={selectedMatter}
            matter_id={selectedMatterId}
            data={detailsData}
            setRefresh={setRefresh}
          />
        </Splitter>
      </div>
    </div>
  );
};

export default FileCabinet;

import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import React from "react";

function AIOpenChat({ queryModels, setQueryModels }) {
  // const labels = ["GPT", "MISTRAL", "LLAMA", "GEMINI"];

  const labels = ["GPT 4x", "Mistral 7B" /*"Llama 2 70B", "Gemini Pro" */];
  const map = new Map();
  map.set("GPT 4x", "gpt");
  map.set("Mistral 7B", "mistral");
  //   map.set("Llama 2 70B","llama");
  //   map.set("Gemini Pro","gemini");

  // const [query, setQuery] = useState([{
  //     label: "GPT", moddel: "gpt"
  // }]);

  const handleCheckboxChange = (value) => (event) => {
    if (event.target.checked) {
      setQueryModels([...queryModels, value]);
    } else {
      setQueryModels(queryModels.filter((item) => item !== value));
    }
  };

  return (
    <Grid container spacing={0.5} alignItems="center">
      {labels.map((label, index) => (
        <Grid item key={index}>
          <FormControlLabel
            control={
              <Checkbox
                checked={queryModels.includes(map.get(label))}
                onChange={handleCheckboxChange(map.get(label))}
              />
            }
            label={label}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default AIOpenChat;

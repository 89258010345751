// import pdf from "../../sample.pdf";
// import { Document, Page } from "react-pdf";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton";
import { blue } from "@mui/material/colors";
import React, { Fragment, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { v4 as uuidv4 } from "uuid";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AdvanceTextarea from "../../controls/AdvanceTextarea";
import {
  FilingCabinetData,
  addChat,
  addChatToFilingCabinet,
} from "../../services/filingcabinet/filingcabinet.service";
import {
  createNote,
  deleteNote,
  getNotesByContext,
  updateNote,
} from "../../services/notes/notes.service";
import Constants from "../../services/utils/Constants";
import utils from "../../services/utils/utils";
import ToastMessage, { IToast } from "../ToastMessage";
import FoldersTree from "../filecabinet/folderstree/FoldersTree";

const cardStyle = {
  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  transition: "0.3s",
  borderRadius: "8px",
  marginBottom: "20px",
  border: "1px solid #e0e0e0",
  backgroundColor: "#fafafa",
};
const NotesForm = ({ matter_id, context_type, matter = null }: any) => {
  const [value, setValue] = useState(null);
  const [notes, setNotes] = useState<any>([]);
  //

  const [openNotesDrawer, setOpenNotesDrawer] = useState(false);

  const [selectedNote, setSelectedNote] = useState<any>(null);
  const [folderViewState, setFolderViewState] = useState(false);

  const [toast, setToast] = useState<IToast>({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });

  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };

  const toggleFolderView =
    (openState: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setFolderViewState(openState);
    };

  const [selectedFolders, setSelectedFolders] = useState([]);
  const handleSelectedFolder = async (selectedFolders) => {
    //
    setSelectedFolders(selectedFolders);
  };

  const handleNotesAction = (event: any) => {
    event.preventDefault();
    setOpenNotesDrawer(!openNotesDrawer);
  };

  const saveNotes = async () => {
    if (editing) {
      const notesResponse: any = await updateNote({
        ...notesContext,
        note_desc: JSON.stringify(value),
      });

      if (notesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        setEditing(false);
        getNotes();
        setValue(null);
      } else {
      }
    } else {
      const notesResponse: any = await createNote({
        context_id: matter_id,
        context_type,
        note: JSON.stringify(value),
      });
      if (notesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        const temp = [...notes, notesResponse.data];
        //
        temp.sort((a, b) => {
          return new Date(b.modified_date) - new Date(a.modified_date);
        });
        setNotes([...temp]);
        setToast((toast) => {
          return {
            ...toast,
            openState: true,
            title: "Success",
            message: "Notes has been saved.",
            onCloseToast: onCloseToast,
            messageType: "bg-primary text-white",
          };
        });
        setValue(null);
      }
    }
  };

  const handleDelete = async (note_id: number) => {
    const notesResponse: any = await deleteNote(note_id);
    if (notesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      getNotes();
    }
  };

  const getNotes = async () => {
    try {
      const notesResponse: any = await getNotesByContext(matter_id);
      if (notesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        const temp = [...notesResponse.data];
        temp.sort((a, b) => {
          return new Date(b.modified_date) - new Date(a.modified_date);
        });
        setNotes([...temp]);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
    }
  };

  const handleDisplayFolder = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    note: any
  ): void => {
    e.preventDefault();
    setFolderViewState(true);
    setSelectedNote(note);

    //
  };

  const handleAddToFolder = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    const chatStr = JSON.stringify(selectedNote);
    const addChatResponse = await addChat(chatStr, matter_id);

    if (addChatResponse?.status == Constants.HTTP_STATUS.SUCCESS) {
      let chatData = addChatResponse.data;

      const notesReference = `${utils.getDateForNotes(
        matter?.modified_date
      )}.note`;
      const data: FilingCabinetData = {
        filename: notesReference,
        originalPath: notesReference,
        file_type: "note",
        parent_node_ids: selectedFolders,
        size: 0,
        matter_id: matter_id,
        chat_id: chatData.id,
      };

      const addChatToFile = await addChatToFilingCabinet(data);

      if (addChatToFile?.status == Constants.HTTP_STATUS.SUCCESS) {
        setToast({
          ...toast,
          openState: true,
          title: "Success",
          message: "Document/Chat has been added to the filing cabinet.",
          onCloseToast: onCloseToast,
          messageType: "bg-primary text-white",
        });
      } else {
        setToast({
          ...toast,
          openState: true,
          title: "Error",
          message: "Unable to create folder.",
          onCloseToast: onCloseToast,
          messageType: "bg-danger text-white",
        });
      }
    }
  };

  useEffect(() => {
    if (openNotesDrawer) {
      getNotes();
    }
  }, [openNotesDrawer]);
  const [editing, setEditing] = useState(false);
  const [notesContext, setNotesContext] = useState<any>(null);
  return (
    <Fragment>
      <ToastMessage {...toast} />
      <IconButton
        onClick={(e) => handleNotesAction(e)}
        className="px-2"
        style={{
          position: "fixed",
          right: "32px",
          bottom: "100px",
          zIndex: 1600,
        }}
        color="primary"
        aria-label="Click to add Notes"
      >
        <div
          style={{
            padding: "16px",
            borderRadius: "100%",
            background: "white",
            boxShadow: "0px 0px 5px 0px #0000001a",
            width: "52px",
            height: "52px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: blue[500],
          }}
        >
          <DocumentScannerIcon style={{ color: "white" }} fontSize="small" />
        </div>
      </IconButton>
      <Drawer
        anchor="right"
        open={openNotesDrawer}
        onClose={() => setOpenNotesDrawer(false)}
      >
        <Box className="vh-100" sx={{ width: 600 }} role="presentation">
          {/* <PdfComp pdfFile={pdf} /> */}
          <Typography variant="h6" className="m-4">
            Add Notes
          </Typography>
          <AdvanceTextarea value={value} setValue={setValue} />

          <div className="d-flex justify-content-end my-4 m-4 gap-4">
            <Button onClick={saveNotes} variant="contained" size="small">
              Save
            </Button>
          </div>
          <Typography variant="h6" className="m-4">
            Notes <HistoryIcon />
          </Typography>
          <Grid container spacing={4} sx={{ px: 4 }}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {notes.map((note: any, index: number) => (
                <Notes
                  key={note.id}
                  note={note}
                  index={index}
                  handleDelete={handleDelete}
                  handleDisplayFolder={handleDisplayFolder}
                  getNotes={getNotes}
                  setValue={setValue}
                  setEditing={setEditing}
                  setNotesContext={setNotesContext}
                  editing={editing}
                  notesContext={notesContext}
                />
              ))}
            </Timeline>
          </Grid>
        </Box>
      </Drawer>
      <Drawer
        anchor="right"
        open={folderViewState}
        onClose={toggleFolderView(false)}
      >
        <Box className="vh-100" sx={{ width: 600 }} role="presentation">
          <Box className="p-5" role="presentation">
            <div className="mb-3">
              {selectedNote && (
                <Fragment>
                  Reference:{" "}
                  <mark>
                    <u>{`${utils.getDateForNotes(
                      matter?.modified_date
                    )}.note`}</u>
                  </mark>
                  <Editor
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    toolbarHidden
                    readOnly
                    defaultContentState={JSON.parse(selectedNote?.note_desc)}
                  />
                </Fragment>
              )}

              <hr className="m-0" />
            </div>

            <FoldersTree
              matter_id={matter_id}
              onSelection={handleSelectedFolder}
            />
            <Button
              variant="contained"
              className="mt-5"
              onClick={handleAddToFolder}
            >
              Add to Folder(s)
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};

const Notes = ({
  note,
  index,
  handleDelete,
  handleDisplayFolder,
  getNotes,
  setValue,
  setNotesContext,
  notesContext,
}: any) => {
  const [edit, setEdit] = useState(false);

  const [noteData, setNoteData] = useState<any>(JSON.parse(note.note_desc));
  const handleSave = async () => {
    const notesResponse: any = await updateNote({
      ...note,
      note_desc: JSON.stringify(noteData),
    });
    //
    if (notesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      setEdit(false);
      getNotes();
      setValue(null);
    } else {
    }
  };
  const handleCloseEditModal = () => {
    setEdit(false);
    setNotesContext(null);
  };
  const [editingNote, setEditingNote] = useState({});

  return (
    <>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div className="row">
            <Typography variant="body2" color="textSecondary">
              {utils.getDisplayDate(note.modified_date)}
            </Typography>
            {
              <Typography
                className="text-muted"
                variant="body2"
                color="textSecondary"
              >
                {note.context_type == "chat"
                  ? "Capture while chatting"
                  : "Capture on filing cabinet"}
              </Typography>
            }
          </div>
          <Grid item xs={12} key={uuidv4()}>
            <Card
              className="mt-3 "
              style={{ background: edit ? "white" : "#f4f498" }}
              sx={cardStyle}
            >
              <CardContent>
                <Editor
                  wrapperClassName="wrapper"
                  editorClassName="editor"
                  toolbarHidden
                  readOnly
                  defaultContentState={JSON.parse(note.note_desc)}
                />
              </CardContent>
              <hr className="m-0" />
              <CardActions>
                <div className="d-flex">
                  <IconButton
                    onClick={(e) => handleDisplayFolder(e, note)}
                    className="px-2"
                    color="primary"
                    aria-label="Click to add to File cabinate"
                  >
                    <AddToDriveIcon fontSize="small" />
                  </IconButton>
                  {!edit ? (
                    <IconButton
                      onClick={(e) => {
                        setEdit(!edit);
                        setValue(JSON.parse(note.note_desc));
                        // setEditing(true);
                        setNoteData(JSON.parse(note.note_desc));
                        setNotesContext(note);
                      }}
                      className="px-2"
                      color="primary"
                      aria-label="Click to Edit"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </div>

                {edit ? (
                  <Button
                    onClick={() => {
                      setEdit(false);
                      // setEditing(false);
                    }}
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ marginLeft: "auto" }} // Styling for the delete button
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => handleDelete(note.id)}
                    variant="outlined"
                    color="error"
                    size="small"
                    sx={{ marginLeft: "auto", color: "#f44336" }} // Styling for the delete button
                  >
                    Delete
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </TimelineContent>
      </TimelineItem>
      <Dialog open={edit} onClose={handleCloseEditModal}>
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          <AdvanceTextarea value={noteData} setValue={setNoteData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSave()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Editable = ({ data, setData }) => {
  //
  var objectConstructor = {}.constructor;
  const textdata =
    data.constructor === objectConstructor ? data : JSON.parse(data);

  return <AdvanceTextarea value={textdata} setValue={setData} />;
};

// function PdfComp(props) {
//   const [numPages, setNumPages] = useState();
//   const [pageNumber, setPageNumber] = useState(1);
//   const [searchText, setSearchText] = useState("");

//   function highlightPattern(text, pattern) {
//     return text.replace(pattern, (value) => `<mark>${value}</mark>`);
//   }
//   const textRenderer = useCallback(
//     (textItem) => highlightPattern(textItem.str, searchText),
//     [searchText]
//   );
//   function onDocumentLoadSuccess({ numPages }) {
//
//     setNumPages(numPages);
//   }
//   function onChange(event) {
//     setSearchText(event.target.value);
//   }
//
//   return (
//     <div className="pdf-div">
//       <p>
//         Page {pageNumber} of {numPages}
//       </p>
//       <div>
//         <label htmlFor="search">Search:</label>
//         <input
//           type="search"
//           id="search"
//           value={searchText}
//           onChange={onChange}
//         />
//       </div>
//       <Document file={props.pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
//         {Array.apply(null, Array(numPages))
//           .map((x, i) => i + 1)
//           .map((page) => {
//             return (
//               <Page
//                 pageNumber={page}
//                 customTextRenderer={textRenderer}
//                 renderTextLayer={false}
//                 renderAnnotationLayer={false}
//               />
//             );
//           })}
//       </Document>
//     </div>
//   );
// }

// function PdfComp(props) {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);
//   const [searchText, setSearchText] = useState("");
//   function highlightPattern(text, pattern) {
//     return text.replace(pattern, (value) => `<mark>${value}</mark>`);
//   }
//   const textRenderer = ({ str }) => {
//     return highlightPattern(str, searchText);
//   };

//   function onChange(event) {
//     setSearchText(event.target.value);
//   }
//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//     setPageNumber(1);
//   }

//   function changePage(offset) {
//     setPageNumber((prevPageNumber) => prevPageNumber + offset);
//   }

//   function previousPage() {
//     changePage(-1);
//   }

//   function nextPage() {
//     changePage(1);
//   }

//   return (
//     <>
//       <label htmlFor="search">Search:</label>
//       <input type="search" id="search" value={searchText} onChange={onChange} />
//       <Document file={props.pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
//         <Page
//           pageNumber={pageNumber}
//           // renderTextLayer={false}
//           renderAnnotationLayer
//           // renderMode="custom"
//           customTextRenderer={({ str, itemIndex }) =>
//             str.replace(
//               /( An updated schedule for floors 5 through 9 |further than the swinging fen)/g,
//               (value) => `<mark>${value}</mark>`
//             )
//           }
//         />
//       </Document>
//       <div>
//         <p>
//           Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
//         </p>
//         <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
//           Previous
//         </button>
//         <button
//           type="button"
//           disabled={pageNumber >= numPages}
//           onClick={nextPage}
//         >
//           Next
//         </button>
//         <div></div>
//       </div>
//     </>
//   );
// }
export default NotesForm;

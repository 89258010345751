import React, { Fragment } from "react";
import ModuleLayout from "../../components/ModuleLayout";

const VisionLayout = () => {
  return (
    <Fragment>
      <ModuleLayout title="">
        <h1>Vision</h1>
      </ModuleLayout>
    </Fragment>
  );
};

export default VisionLayout;

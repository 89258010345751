import { Avatar } from "@mui/material";
import ModuleLayout from "components/ModuleLayout";
import NavigationHeader from "components/branding/header/NavigationHeader";
import authService from "services/auth.service";

const Profile = () => {
  const user = authService.getUserInfo();

  return (
    <>
      <ModuleLayout>
        <NavigationHeader />
        <div className="container-fluid px-5">
          <h1> Profile </h1>
          <div className="card mb-3" style={{ maxWidth: 500 }}>
            <div className="row g-0">
              <div className="col-md-2 align-self-center align-items-center justify-content-center">
                <Avatar sx={{ width: 48, height: 48 }} />{" "}
                <span className="mx-3"></span>
              </div>
              <div className="col-md-10">
                <div className="card-body">
                  <h5 className="card-title">{user.full_name}</h5>
                  <p className="card-text">{user.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModuleLayout>
    </>
  );
};

export default Profile;

import DeleteIcon from "@mui/icons-material/Delete";
import { Button, MenuItem } from "@mui/material";
import { MRT_ColumnDef, MaterialReactTable } from "material-react-table";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import promptService from "services/item/prompt.service";
import { UserView } from "services/types/types";

const ManageProjects = () => {
  const navigate = useNavigate();

  const userView: UserView[] = [];
  const [viewData, setViewData] = useState(userView);
  const viewDataColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "view_name",
        accessorKey: "view_name",
        header: "Project name",
        size: 150,
      },
      {
        id: "selectedColumnData",
        accessorKey: "selectedColumnData",
        header: "Selected prompts",
        size: 400,
      },
    ],
    []
  );

  const onLoad = async () => {
    await promptService.getViews().then((response: any) => {
      const views: UserView[] = response.data;

      const viewData = views.map((view: any) => ({
        ...view,
        selectedColumnData: JSON.parse(view.selected_columns).join(", "),
      }));

      setViewData(viewData);
    });
  };

  const handleActions = async (e: any, action: any, row: any) => {
    const id = row.original.id;

    switch (action) {
      case "edit": {
        navigate("/views/create", {
          state: {
            id,
          },
        });
        break;
      }
      case "delete": {
        await promptService.deleteView(id).then((response: any) => {
          const userView: UserView[] = response.data;

          const data = viewData.filter((view: UserView) => {
            return view.id !== id;
          });

          setViewData(data);
        });
      }
    }
  };

  useEffect(() => {
    onLoad();
  }, []);

  const handleNavigation = (): void => {
    navigate("/projects/create");
  };

  return (
    <Fragment>
      <div className="col container-fluid">
        <div className="row">
          <div className="col">
            <h1> Manage Projects </h1>
          </div>
          <div className="col">
            <Button
              color="info"
              variant="contained"
              className="float-end"
              onClick={handleNavigation}
            >
              Create Project
            </Button>
          </div>
        </div>

        <div className="">
          <MaterialReactTable
            columns={viewDataColumns}
            data={viewData}
            enableColumnFilterModes={true}
            enableStickyHeader
            enableRowActions
            enableHiding={false}
            positionToolbarAlertBanner="bottom"
            positionActionsColumn="last"
            positionExpandColumn="last"
            positionGlobalFilter="left"
            initialState={{
              showColumnFilters: true,
              showGlobalFilter: true,
              sorting: [
                {
                  id: "modified_date", //sort by age by default on page load
                  desc: true,
                },
              ],
              pagination: { pageSize: 5, pageIndex: 0 },
            }}
            renderRowActionMenuItems={({ row, table, closeMenu }) => [
              <MenuItem
                onClick={(e) => handleActions(e, "delete", row)}
                key={1}
                sx={{ m: 0 }}
              >
                <DeleteIcon fontSize="small"></DeleteIcon>
                Delete
              </MenuItem>,
            ]}
            muiSearchTextFieldProps={{
              placeholder: `Search`,
              sx: { minWidth: "500px", paddingTop: "10" },
              variant: "standard",
            }}
            muiTablePaginationProps={{
              sx: { color: "blue" },
              rowsPerPageOptions: [5, 10, 20],
              backIconButtonProps: {
                style: {},
              },
              nextIconButtonProps: {
                style: {},
              },
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ManageProjects;

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button/Button";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";

import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import promptService from "services/item/prompt.service";
import { Prompt } from "services/types/types";

const ManagePrompts = () => {
  const navigate = useNavigate();
  const prompts: Prompt[] = [];
  const [promptData, setPromptData] = useState(prompts);
  const promptDataColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        size: 150,
      },
      {
        id: "description",
        accessorKey: "description",
        header: "Description",
        size: 400,
      },
      {
        accessorFn: (row) => new Date(row.uploaded_date),
        Cell: ({ cell }) => cell.getValue<Date>()?.toLocaleDateString(),
        id: "uploaded_date",
        accessorKey: "uploaded_date",
        header: "Modified Date",
        size: 100,
      },
    ],
    []
  );

  const handleActions = async (e: any, action: any, row: any) => {
    const id = row.original.id;

    switch (action) {
      case "edit": {
        navigate("/prompts/create", {
          state: {
            id,
          },
        });
        break;
      }
      case "delete": {
        await promptService.deletePrompt(id).then((response: any) => {
          const prompts: Prompt[] = response.data;

          const data = promptData.filter((prompt: Prompt) => {
            return prompt.id !== id;
          });

          setPromptData(data);
        });
      }
    }

    if (action == "edit") {
      navigate("/prompts/create", {
        state: {
          id,
        },
      });
    }
  };

  const handleNavigation = (): void => {
    navigate("/prompts/create");
  };

  const onLoad = async () => {
    await promptService.getPrompts().then((response: any) => {
      const prompts: Prompt[] = response.data;

      /* const promptData = prompts.map((view: any) => ({
        ...view,
        selectedColumnData: JSON.parse(view.selected_columns).join(", "),
      })); */

      setPromptData(prompts);
    });
  };

  useEffect(() => {
    onLoad();
  }, []);
  return (
    <Fragment>
      <div className="col container-fluid px-5">
        <div className="row">
          <div className="col">
            <h1> Manage Prompts </h1>
          </div>
          <div className="col">
            <Button
              color="info"
              variant="contained"
              className="float-end"
              onClick={handleNavigation}
            >
              Create Prompt
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="">
            <MaterialReactTable
              columns={promptDataColumns}
              data={promptData}
              enableColumnFilterModes={true}
              enableStickyHeader
              enableRowActions
              enableHiding={false}
              positionToolbarAlertBanner="bottom"
              positionActionsColumn="last"
              positionExpandColumn="last"
              positionGlobalFilter="left"
              initialState={{
                showColumnFilters: true,
                showGlobalFilter: true,
                pagination: { pageSize: 5, pageIndex: 0 },
              }}
              state={{}}
              renderRowActionMenuItems={({ row, table, closeMenu }) => [
                <MenuItem
                  onClick={(e) => handleActions(e, "edit", row)}
                  key={0}
                  sx={{ m: 0 }}
                >
                  <EditIcon fontSize="small"></EditIcon> Edit
                </MenuItem>,
                <MenuItem
                  onClick={(e) => handleActions(e, "delete", row)}
                  key={1}
                  sx={{ m: 0 }}
                >
                  <DeleteIcon fontSize="small"></DeleteIcon>
                  Delete
                </MenuItem>,
              ]}
              muiSearchTextFieldProps={{
                placeholder: `Search`,
                sx: { minWidth: "500px", paddingTop: "10" },
                variant: "standard",
              }}
              muiTablePaginationProps={{
                sx: { color: "blue" },
                rowsPerPageOptions: [5, 10, 20],
                backIconButtonProps: {
                  style: {},
                },
                nextIconButtonProps: {
                  style: {},
                },
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ManagePrompts;

import StartProject from "./StartProject";

const CompanyProject = () => {
  return (
    <>
      <StartProject />
    </>
  );
};

export default CompanyProject;

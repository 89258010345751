import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { v4 as uuidv4 } from "uuid";

const TagsTable = ({ handleFileClick, data }: any) => {
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    handleFileClick(name);
  };
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Tag name</TableCell>
            <TableCell>File name</TableCell>
            <TableCell>Modified date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow
              onClick={(event) => handleClick(event, row.file_name)}
              key={uuidv4()}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.tag_name}
              </TableCell>
              <TableCell>
                <a href="#" className="text-decoration-underline fw-bolder">
                  {" "}
                  {row.file_name}{" "}
                </a>
              </TableCell>
              <TableCell>{row.modified_date}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TagsTable;

import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import authService from "./auth.service";

const instance = axios.create();

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    config.headers["x-request-id"] = uuidv4();
    config.headers["token"] = localStorage.getItem("token");
    config.headers["Bearer"] = ""; //pass bearer token

    const user = authService.getUserInfo();

    if (user) {
      config.headers["user"] = `${user.id}.${user.full_name}`;
    }

    return config;
  },
  (error) => {
    return error.response.status;
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error("An error occurred:", error);
    console.error("An error occurred:", error.response, error.response.data);
    return error.response.status;
    // return Promise.reject(error);
  }
);

export default instance;

import { Fragment, useEffect, useState } from "react";

import Alert from "components/Alerts";
import React from "react";
import { useNavigate } from "react-router-dom";
import authService from "services/auth.service";
import "./login.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isValidUser, setIsValidUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  });

  useEffect(() => {}, [isValidUser]);

  const routeToSignUp = async (event: { preventDefault: () => void }) => {
    navigate("/signup");
  };
  const loginSubmitHandler = async (event: { preventDefault: () => void }) => {
    setLoading(true);
    event.preventDefault();

    if (username?.trim() === "" || password === "") {
      return;
    }

    authService.login(username.trim(), password).then(
      (res: any) => {
        setIsValidUser((currentValue) => (currentValue = res));

        if (res) {
          setLoading(false);
          navigate("/file");
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        //console.error("Error on login", resMessage);

        setIsValidUser(false);

        return resMessage;
      }
    );
  };

  const html = (
    <Fragment>
      <div className="container-fluid bg d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card border-0 shadow rounded-3 my-5">
                <div className="card-body p-4 p-sm-5">
                  <h5 className="card-title text-center mb-5 fw-light fs-5">
                    <strong>Sign In</strong>
                  </h5>

                  {!isValidUser && (
                    <Alert
                      isError={true}
                      message="Invalid Credentials. Please check your email and password."
                    />
                  )}

                  <form onSubmit={loginSubmitHandler}>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="txtEmail"
                        placeholder="name@example.com"
                        onChange={(event) => setUsername(event.target.value)}
                      />
                      <label htmlFor="txtEmail">Email address</label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        className="form-control"
                        id="txtPassword"
                        placeholder="Password"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                      <label htmlFor="txtPassword">Password</label>
                    </div>

                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="rememberPasswordCheck"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rememberPasswordCheck"
                      >
                        Remember password
                      </label>
                    </div>
                    <div className="d-grid">
                      <button
                        className="btn btn-primary btn-login text-uppercase fw-bold"
                        type="submit"
                      >
                        Sign in
                      </button>
                      {/* <div className="d-flex justify-content-center my-2">
                        or
                      </div>
                      <button
                        className="btn btn-secondary btn-login text-uppercase fw-bold"
                        type="submit"
                        onClick={routeToSignUp}
                      >
                        Sign up
                      </button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
  return html;
};
export default LoginPage;

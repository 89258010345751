import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMatters } from "../context/MattersProvider";
import projectService from "../services/project/project.service";
import Constants from "../services/utils/Constants";
import ButtonDropdown from "./ButtonDropdown";

const MattersDropdown = ({
  onSelectMatter,
  isOpenChatEnabled = false,
  isChatPage = true,
  setOpenChat,
}) => {
  const [projects, setProjects] = useState([]);
  const [isMatterSelected, setIsMatterSelected] = useState(false);
  const { matter, setMatter } = useMatters();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelection = async (event: any) => {
    const projectId = event.currentTarget.dataset.output;

    if (!projectId) {
      setIsMatterSelected(false);
     if(isChatPage) navigate("/aichat", { replace: true });
      return;
    }

    const project = projects.find((p: any) => p.id == projectId);
    if (isOpenChatEnabled && isChatPage) {
      if (projectId == -11) {
        setOpenChat(true);
      } else {
        setOpenChat(false);
      }
    }
    if(isChatPage) navigate("/aichat", { replace: true });
    setMatter(project);
    onSelectMatter(project);
  };

  const getMatters = async () => {
    const projectsResponse: any = await projectService.getMattersByUserId();

    if (projectsResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      if (isOpenChatEnabled) {
        const openChat = {
          id: -11,
          project_name: "Open Chat",
        };

        projectsResponse.data.push(openChat);
      }

      setProjects(projectsResponse.data);
      const urlParams = new URLSearchParams(location.search);
      const matterIdFromUrl = urlParams.get('matterid');
      if (matterIdFromUrl) {
        const projectId = parseInt(matterIdFromUrl, 10); // Parse matterIdFromUrl to an integer
        const project = projectsResponse.data.find((p: any) => p.id === projectId);
        if (project) {
          setMatter(project);
          setIsMatterSelected(true);
          onSelectMatter(project);
        }
      }
    }
  };

  useEffect(() => {
    getMatters();
  }, []);

  return (
    <div>
      <ButtonDropdown
        className="w-100"
        data={projects}
        handleSelection={handleSelection}
      />
    </div>
  );
};

export default MattersDropdown;

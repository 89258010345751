import config from "./config";

const baseUrl = config.baseUrl;
const inferenceAPIUrl = config.inferenceAPIUrl;
const chatAPIUrl = config.chatAPIUrl;
export const endpoints = {
  user: {
    login: `${baseUrl}/login`,
    getUsers: `${baseUrl}/users`,
    putUser: `${baseUrl}/users`,
    getUser: `${baseUrl}/users/{user_id}`,
  },
  item: {
    getItems: `${baseUrl}/items/?skip=0&limit=100`,
    getViewData: `${baseUrl}/view_data/{viewid}?skip=0&limit=100`,
    updateFile: `${baseUrl}/update_file?skip=0&limit=100`,
    uploadFile: `${baseUrl}/upload_files/{userid}`,
    get_file_content: `${baseUrl}/get_file_content/`,
  },
  views: {
    createView: `${baseUrl}/view/`,
    getViews: `${baseUrl}/views/{userid}`,
    updateView: `${baseUrl}/view/{view_id}`,
    deleteView: `${baseUrl}/view/{view_id}`,
  },
  prompts: {
    createPrompt: `${baseUrl}/prompt`,
    getPrompts: `${baseUrl}/prompts`,
    getPromptsByUserId: `${baseUrl}/prompts/{userid}`,
    updatePrompt: `${baseUrl}/prompt/{prompt_id}`,
    deletePrompt: `${baseUrl}/prompt/{prompt_id}`,

    insertmock: `${baseUrl}/create_data/{table_name}`,
  },
  ai: {
    preview: `${baseUrl}/genai/previewprompt/`,
    //applyPromptById: `${baseUrl}/genai/{prompt_id}/{input_text}`,
    applyPromptById: `${baseUrl}/genai/applyprompt/`,
    applyPromptBatch: `${baseUrl}/genai/applyprompt/batch/`,
    applyPromptBatchx: `${baseUrl}/genai/applyprompt/batchx/`,
    getFile: `${baseUrl}/get_file/{file_name}`,
    getFileContent: `${baseUrl}/read_file/{document_id}`,
    getFileResponse: `${baseUrl}/get_file_summary/{matter_id}/{file_name}`,
    getHtmlOrJson: `${baseUrl}/get_html_or_json_file/{matter_id}/{file_name}`,
    getRefreshedEmailContent: `${inferenceAPIUrl}/llmservice/clean_email/?matter_id={matter_id}&file_name={file_name}`,
    getAttachmentFile: `${baseUrl}/get_file/{matter_id}/{file_name}`,
    downloadFile: `${baseUrl}/download/{matter_id}/{file_name}`,
    queryAI: `${inferenceAPIUrl}/query/{project_id}/{query_text}/{aiModel}/{topK}`,
    //query_ai_index: `${inferenceAPIUrl}/query_ai_index/`,
    query_ai_index: `${inferenceAPIUrl}/query/agents?project_id={project_id}&query_text={query_text}&similarity_top_k={similarity_top_k}&aiModel={aiModel}&matter_context={matter_context}&name={name}&user_id={user_id}&user_name={user_name}&conversation_id={conversation_id}`,
    just_chat: `${inferenceAPIUrl}/justchat/`,
    getEmailAttachments: `${baseUrl}/documents/link_emails_attachments?matter_id={matter_id}&filename={file_name}`,
    getSummary: `${baseUrl}/v2/get_summary?file_name={file_name}&matter_id={matter_id}`,
    v2: {
      getAttachmentFile: `${baseUrl}/v2/get_file/{matter_id}/{owner_id}/{file_name}`,
      getFileStatus: `${baseUrl}/v2/read_reference_status?matter_id={matter_id}&owner_id={owner_id}`,
    },
    //execute:
  },
  project: {
    create: `${baseUrl}/v1/project/`,
    getMattersUserId: `${baseUrl}/v1/projects/{userid}/`,
    issue: `${baseUrl}/issue/`,
    people: `${baseUrl}/people/`,
    uploadDocument: `${baseUrl}/document_upload/{userid}/{project_id}`,
    document: `${baseUrl}/document/`,
    documents: `${baseUrl}/documents/`,
    getDocumentByFilename: `${baseUrl}/document/{file_name}`,
    toggle: `${baseUrl}/v1/projects/{project_id}/toggle-active`,
    getInactiveProjects: `${baseUrl}/v1/projects/inactive/{user_id}`,
  },
  dashboard: {
    dashboard: `${baseUrl}/v1/dashboard/{project_id}`,
    ins: `${baseUrl}/v1/dashboard/ins/`,
  },
  tags: {
    createTag: `${baseUrl}/v1/tag/`,
    createTags: `${baseUrl}/v1/tags/`,
    getTags: `${baseUrl}/v1/tags/`,
    getTagsByUser: `${baseUrl}/v1/tags/`,
    getTagsByFile: `${baseUrl}/v1/tags/{filename}`,
    getDistinctTags: `${baseUrl}/v1/tags/get_distinct_tags`,
    deleteTag: `${baseUrl}/v1/tag/{tag_id}`,
  },
  index: {
    download_for_index: `${baseUrl}/download_for_index/{projectId}`,
  },
  filecabinate: {
    create: `${baseUrl}/v1/files/`,
    createMultiple: `${baseUrl}/v1/files/multiparents`,
    createMultiplev2: `${baseUrl}/v2/files/multiparents`,
    getFiles: `${baseUrl}/v1/files/{matter_id}`,
    getFolder: `${baseUrl}/v1/files/folders/{matter_id}`,
    rename: `${baseUrl}/v1/files/`,
    deleteFile: `${baseUrl}/v1/files/{matter_id}/{file_id}`,
    createChat: `${baseUrl}/v1/files/chat`,
    readChat: `${baseUrl}/v1/files/chat/{id}`,
    rootDirectory: `${baseUrl}/v1/files/rootDirectory`,
    uploadFiles: `${baseUrl}/document_upload/{user_id}/{project_id}`,
  },
  notes: {
    getNotesByContext: `${baseUrl}/v1/notes/{context_id}`,
    createNote: `${baseUrl}/v1/note/`,
    deleteNote: `${baseUrl}/v1/note/{note_id}`,
    postNote: `${baseUrl}/v1/note/`,
  },
  chat: {
    init: `${chatAPIUrl}/conversation/init`,
    get_messages: `${chatAPIUrl}/conversation/get_messages/{conversation_id}`,
    rename: `${chatAPIUrl}/conversation/rename/{conversation_id}/?name={name}`,
    restart: `${chatAPIUrl}/conversation/restart`,
    retriver: `${chatAPIUrl}/query/retriver`,
    get_names: `${chatAPIUrl}/conversation/names`,
    send_feedback: `${chatAPIUrl}/query/feedback`,
  },
  // feedback: {
  //   createFeedback: `${baseUrl}/v1/feedback/`,
  //   getFeedbackByUserId: `${baseUrl}/v1/feedback/userid/{user_id}`,
  // },
  feedback: {
    createFeedback: `${baseUrl}/v2/users/{user_id}/feedbacks/?title={ttle}&description={desc}&type={tp}`,
    getFeedbackByUserId: `${baseUrl}/v2/feedbacks/user/{user_id}`,
    getFeedbackByFeedbackId: `${baseUrl}/v2/feedbacks/{feedback_id}`,
    createComment: `${baseUrl}/v2/feedbacks/{feedback_id}/comments/?content={msg}&user_id={u_id}`,
    updateStatus: `${baseUrl}/v2/feedbacks/{feedback_id}/status?status={st}`,
    // deleteComment: `${baseUrl}/v2/comments/{comment_id}`
  },

  imagechat: {
    visionquery: `${chatAPIUrl}/vision/query`,
    visionupload: `${chatAPIUrl}/vision/upload`,
  },
  create_matter: {
    getSourceFiles: `${baseUrl}/v1/create_matter/source_files/{matter_id}?user_id={u_id}&offset={offset}`,
    getExtractedFiles: `${baseUrl}/v1/create_matter/extracted_files/{matter_id}?source_id={s_id}`,
    triggerJenkins: `${baseUrl}/v1/create_matter/jenkins/pipeline/{matter_id}?user_id={u_id}`,
    indexingStatus: `${baseUrl}/v1/create_matter/indexing_status/{matter_id}`,
    createMatter: `${baseUrl}/v1/create_matter/create`,
    summary: `${baseUrl}/v1/create_matter/source_files_summary/{matter_id}?owner_id={owner_id}`,
    extractedSummary: `${baseUrl}/v1/create_matter/extracted_files_summary/{matter_id}`,
  },
  releaseNotes: {
    getReleaseNotes: `${baseUrl}/v1/release_notes`,
    getReleaseNote: `${baseUrl}/v1/release_notes/{release_notes_id}`,
    editReleaseNotes: `${baseUrl}/v1/release_notes/{release_notes_id}`,
    createReleaseNote: `${baseUrl}/v1/release_notes`,
    deleteReleaseNote: `${baseUrl}/v1/release_notes/{release_notes_id}`,
  },
};
export default endpoints;

import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon"; // Importing InsertEmoticonIcon
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth.service";
import {
  createFeedback,
  createComment,
} from "../../services/feedback/feedback.service";
// import { getFeedbackByFeedbackId, createComment, updateStatus } from '../../services/feedback/feedback.service';
import Constants from "../../services/utils/Constants";
import ToastMessage from "../ToastMessage";
import AdvanceTextarea from "../../controls/AdvanceTextarea";
const user = authService.getUserInfo();
//const user= "Username";
const feedbackTypes = [
  // Added a default placeholder
  { value: "General", label: "General" },
  { value: "Feature Request", label: "Feature Request" },
  { value: "Bug", label: "Bug" },
];

const FeedbackForm = ({ open, onClose }) => {
  const user = authService.getUserInfo();

  let navigate = useNavigate();
  const [feedbackType, setFeedbackType] = useState("Please Choose");
  const [feedbackText, setFeedbackText] = useState("");
  const [feedbackTitle, setFeedbackTitle] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [value, setValue] = useState(null);
  const [toast, setToast] = useState({
    openState: false,
    title: "",
    message: "",
    onCloseToast: null,
  });
  const onCloseToast = () => {
    setToast({
      ...toast,
      openState: false,
    });
  };

  const handleFeedbackTypeChange = (event) => {
    setFeedbackType(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (feedbackText.trim().split(/\s+/).length < 5) {
    //   alert("Feedback length should be at least 5 words.");
    //   return;
    // }

    const feedbackResponse = await createFeedback({
      feedback_desc: JSON.stringify(value),
      feedback_title: feedbackTitle,
      feedback_type: feedbackType,
    });

    if (feedbackResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      const commentsResponse = await createComment({
        feedback_id: feedbackResponse.data.id,
        msg: JSON.stringify(value),
      });
      if (commentsResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      }

      const temp = [...feedbacks, feedbackResponse.data];

      temp.sort((a, b) => {
        return new Date(b.modified_date) - new Date(a.modified_date);
      });
      setFeedbackType("Please Choose");

      onClose(true);
      setFeedbackText("");
      setFeedbackTitle("");
      setValue(null);
      setFeedbacks([...temp]);
      setToast({
        openState: true,
        title: "Success",
        message: "Feedback Submitted",
        onCloseToast: onCloseToast,
        messageType: "bg-primary text-white",
        // messageBodyType: "bg-primary text-white",
      });
    }

    //
    //     feedbackType,
    //     feedbackText,
    // });
  };

  const handleClose = async () => {
    setFeedbackType("Please Choose");
    setFeedbackText("");
    setFeedbackTitle("");
    setValue(null);
    onClose(true);
  };
  return (
    <>
      <ToastMessage {...toast} />

      {open ? (
        <Button
          onClick={() => onClose(!open)}
          style={{
            position: "fixed",
            top: "50%",
            right: -30,
            rotate: "-90deg",
            zIndex: 20000,
          }}
          variant="contained"
          color="success"
        >
          Feedback
        </Button>
      ) : (
        <Dialog
          open={true}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        >
          <DialogTitle
            id="form-dialog-title"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <InsertEmoticonIcon sx={{ mr: 1 }} /> Feedback Form
          </DialogTitle>
          <DialogContent>
            <DialogContentText m={2}>
              Hi <strong>{user?.fname}</strong>, we are always looking for
              feedback.
            </DialogContentText>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <TextField
                select
                label="Feedback Type"
                fullWidth
                value={feedbackType}
                onChange={handleFeedbackTypeChange}
                required
              >
                <MenuItem
                  key="please choose"
                  value="Please Choose"
                  selected
                  disabled
                >
                  -Please Choose-
                </MenuItem>
                {feedbackTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                label="Feedback Title"
                multiline
                fullWidth
                rows={1}
                autoFocus
                value={feedbackTitle}
                onChange={(e) => setFeedbackTitle(e.target.value)}
              />
              <Typography
                variant="body1"
                color="initial"
                sx={{ marginLeft: 0, fontWeight: "bold" }}
              >
                Provide feedback
              </Typography>
              <AdvanceTextarea value={value} setValue={setValue} />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={
                feedbackType === "Please Choose"
                // ||
                // feedbackText.trim().length < 5
              }
              color="primary"
            >
              Submit Feedback
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default FeedbackForm;

import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";

class PromptService {
  createView = (data: any) => {
    return axiosInstance.post(endpoints.views.createView, data);
  };
  getViews = () => {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const userid = user?.id;

    const url = endpoints.views.getViews.replace("{userid}", userid);
    return axiosInstance.get(url);
  };
  updateView = (id: number, data: any) => {
    return axiosInstance.put(
      endpoints.views.updateView.replace("{view_id}", id?.toString()),
      data
    );
  };
  deleteView = (id: number) => {
    return axiosInstance.delete(
      endpoints.views.deleteView.replace("{view_id}", id?.toString())
    );
  };
  createPrompt = (data: any) => {
    return axiosInstance.post(endpoints.prompts.createPrompt, data);
  };
  getPrompts = () => {
    const user = JSON.parse(localStorage.getItem("user") || "");
    const userid = user?.id;

    return axiosInstance.get(
      endpoints.prompts.getPromptsByUserId.replace("{userid}", userid)
    );
  };
  updatePrompt = (id: number, data: any) => {
    return axiosInstance.put(
      endpoints.prompts.updatePrompt.replace("{prompt_id}", id?.toString()),
      data
    );
  };
  deletePrompt = (id: number) => {
    return axiosInstance.delete(
      endpoints.prompts.deletePrompt.replace("{prompt_id}", id?.toString())
    );
  };
}

export default new PromptService();

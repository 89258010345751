// import authService from "../auth.service";
// import axiosInstance from "../axiosInstance";
// import endpoints from "../configs/endpoints";
// import utils from "../utils/utils";

// export const createFeedback = async (data: any) => {
//   const stoken = localStorage.getItem("token");
//
//   const requestData = {
//     feedback: data.feedback,
//     feedback_type: data.feedback_type,
//     date_created: utils.getISODateString(),

//     user_id: authService.getUserId(),
//     session_token: stoken,
//   };
//   const url = endpoints.feedback.createFeedback;
//
//   return axiosInstance.post(url, requestData);
// };

// export const getFeedbacksByUserId = async (userId: number) => {
//   const url = endpoints.feedback.getFeedbackByUserId.replace(
//     "{user_id}",
//     userId.toString()
//   );
//   return axiosInstance.get(url);
// };

// import authService from "../auth.service";
// import axiosInstance from "../axiosInstance";
// import endpoints from "../configs/endpoints";
// import utils from "../utils/utils";

// export const createFeedback = async (data: any) => {
//   const stoken = localStorage.getItem("token");
//
//   const requestData = {
//     feedback: data.feedback,
//     feedback_type: data.feedback_type,
//     date_created: utils.getISODateString(),

//     user_id: authService.getUserId(),
//     session_token: stoken,
//   };
//   const url = endpoints.feedback.createFeedback;
//
//   return axiosInstance.post(url, requestData);
// };

// export const getFeedbacksByUserId = async (userId: number) => {
//   const url = endpoints.feedback.getFeedbackByUserId.replace(
//     "{user_id}",
//     userId.toString()
//   );
//   return axiosInstance.get(url);
// };

import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";
import utils from "../utils/utils";

// export const createFeedback = async (data: any) => {
//   const stoken = localStorage.getItem("token");
//
//   const requestData = {
//     feedback: data.feedback,
//     feedback_type: data.feedback_type,
//     date_created: utils.getISODateString(),

//     user_id: authService.getUserId(),
//     session_token: stoken,
//   };
//   const url = endpoints.feedback.createFeedback;
//
//   return axiosInstance.post(url, requestData);
// };
export const createFeedback = async (data: any) => {
  const stoken = localStorage.getItem("token");

  const requestData = {
    description: data.feedback_desc,
    created_at: null,
    user_id: authService.getUserId(),
    title: data.feedback_title,
    type: data.feedback_type,
    status: data.feedback_status,
    updated_at: null,
  };
  const url = endpoints.feedback.createFeedback
    .replace("{user_id}", authService.getUserId().toString())
    .replace("{ttle}", data.feedback_title)
    .replace("{desc}", data.feedback_desc)
    .replace("{tp}", data.feedback_type);

  return axiosInstance.post(url, requestData);
};

export const getFeedbacksByUserId = async (userId: number) => {
  const url = endpoints.feedback.getFeedbackByUserId.replace(
    "{user_id}",
    userId.toString()
  );
  return axiosInstance.get(url);
};

export const getFeedbackByFeedbackId = async (feedbackId: number) => {
  const url = endpoints.feedback.getFeedbackByFeedbackId.replace(
    "{feedback_id}",
    feedbackId.toString()
  );
  return axiosInstance.get(url);
};

export const createComment = async (data: any) => {
  const url = endpoints.feedback.createComment
    .replace("{feedback_id}", data.feedback_id.toString())
    .replace("{msg}", data.msg)
    .replace("{u_id}", authService.getUserId().toString());
  const requestData = {
    created_at: null,
    feedbackId: data.feedback_id,
    user_id: authService.getUserId(),
    content: data.msg,
    id: 5,
    parent_comment_id: null,
  };
  return axiosInstance.post(url, requestData);
};

// export const deleteComment = async (commentId: number) => {
//   const url = endpoints.feedback.deleteComment.replace(
//     "{comment_id}",
//     commentId.toString()
//   );
//   return axiosInstance.delete(url);
// };

export const updateStatus = async (data: any, feedback_id: number) => {
  const requestData = {
    description: data.feedback,
    created_at: null,
    user_id: authService.getUserId(),
    title: data.feedback,
    type: data.feedback_type,
    status: data.status,
    updated_at: null,
  };
  const url = endpoints.feedback.updateStatus
    .replace("{feedback_id}", data.feedback_id.toString())
    .replace("{st}", data.status);
  return axiosInstance.put(url, requestData);
};

import ModuleLayout from "components/ModuleLayout";
import CompanyProject from "./CompanyProject";

const CompanyLayout = () => {
  return (
    <>
      <ModuleLayout title="">
        <CompanyProject />
      </ModuleLayout>
    </>
  );
};

export default CompanyLayout;

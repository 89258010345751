import React from "react";
import {
  ScrollMenu,
  VisibilityContext,
  type publicApiType,
} from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import styled from "styled-jss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Modal, Typography } from "@mui/material";
import { set } from "date-fns";
export function ReferenceCard({ chunks, pageNumber, setPageNumber }) {
  const [items] = React.useState(() => getItems());
  const [selected, setSelected] = React.useState<string[]>([]);

  // NOTE: for drag by mouse
  const dragState = React.useRef(new DragDealer());

  const handleDrag =
    ({ scrollContainer }: typeof VisibilityContext) =>
    (ev: React.MouseEvent) =>
      dragState.current.dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });
  const onMouseDown = React.useCallback(
    () => dragState.current.dragStart,
    [dragState]
  );
  const onMouseUp = React.useCallback(
    () => dragState.current.dragStop,
    [dragState]
  );

  const isItemSelected = (id: string): boolean =>
    !!selected.find((el) => el === id);

  const handleItemClick = (itemId: string) => {
    if (dragState.current.dragging) {
      return false;
    }
    const itemSelected = isItemSelected(itemId);

    setSelected((currentSelected: string[]) =>
      itemSelected
        ? currentSelected.filter((el) => el !== itemId)
        : currentSelected.concat(itemId)
    );
  };
  const [isModalOpen, setIsModalOpen] = React.useState({
    isModalOpen: false,
    text: "",
    page: null,
  });
  return chunks && chunks.length > 0 ? (
    <>
      <NoScrollbar onMouseLeave={dragState.current.dragStop}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={handleDrag}
          onWheel={onWheel}
        >
          {chunks.map((chunk, id) => {
            return (
              <Card
                title={<p>{chunk.text.slice(0, 70)}</p>}
                itemId={id} // NOTE: itemId is required for track items
                key={id}
                onClick={() =>
                  setIsModalOpen({
                    isModalOpen: true,
                    text: chunk.text,
                    page: chunk.page,
                  })
                }
                selected={isItemSelected(id)}
              />
            );
          })}
        </ScrollMenu>
      </NoScrollbar>
      <Modal
        open={isModalOpen.isModalOpen}
        onClose={() =>
          setIsModalOpen({ isModalOpen: false, text: "", page: null })
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            minHeight: "50vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                window.navigator.clipboard.writeText(isModalOpen.text)
              }
            >
              Copy
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setPageNumber(isModalOpen.page);
                setIsModalOpen({
                  isModalOpen: false,
                  text: "",
                  page: null,
                });
              }}
            >
              Jump to Reference Page {">"}
              {isModalOpen.page}
            </Button>
          </div>
          <Typography variant="h6" component="h2">
            {isModalOpen.text}
          </Typography>
        </Box>
      </Modal>
    </>
  ) : null;
}
export default ReferenceCard;

class DragDealer {
  clicked: boolean;
  dragging: boolean;
  position: number;

  constructor() {
    this.clicked = false;
    this.dragging = false;
    this.position = 0;
  }

  public dragStart = (ev: React.MouseEvent) => {
    this.position = ev.clientX;
    this.clicked = true;
  };

  public dragStop = () => {
    window.requestAnimationFrame(() => {
      this.dragging = false;
      this.clicked = false;
    });
  };

  public dragMove = (ev: React.MouseEvent, cb: (posDiff: number) => void) => {
    const newDiff = this.position - ev.clientX;

    const movedEnough = Math.abs(newDiff) > 5;

    if (this.clicked && movedEnough) {
      this.dragging = true;
    }

    if (this.dragging && movedEnough) {
      this.position = ev.clientX;
      cb(newDiff);
    }
  };
}

const NoScrollbar = styled("div")({
  "& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar": {
    display: "none",
  },
  "& .react-horizontal-scrolling-menu--scroll-container": {
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
});

function LeftArrow() {
  const visibility = React.useContext<publicApiType>(VisibilityContext);
  const isFirstItemVisible = visibility.useIsVisible("first", true);

  return (
    <Arrow
      disabled={isFirstItemVisible}
      onClick={() => visibility.scrollPrev()}
      testId="left-arrow"
    >
      <ArrowBackIcon />
    </Arrow>
  );
}

function RightArrow() {
  const visibility = React.useContext<publicApiType>(VisibilityContext);
  const isLastItemVisible = visibility.useIsVisible("last", false);

  return (
    <Arrow
      disabled={isLastItemVisible}
      onClick={() => visibility.scrollNext()}
      testId="right-arrow"
    >
      <ArrowForwardIcon />
    </Arrow>
  );
}

function Arrow({
  children,
  disabled,
  onClick,
  className,
  testId,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  className?: string;
  testId: string;
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      color="primary"
      // className={"arrow" + `-${className}`}
      style={{
        // height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "auto",
        marginBottom: "auto",
        // borderRadius: "100%",
        border: "none",
      }}
      data-testid={testId}
    >
      {children}
    </Button>
  );
}

function Card({
  onClick,
  selected,
  title,
  itemId,
}: {
  onClick: (context: publicApiType) => void;
  selected: boolean;
  title: React.ReactNode;
  itemId: string;
}) {
  const visibility = React.useContext<publicApiType>(VisibilityContext);
  const isVisible = visibility.useIsVisible(itemId, true);

  return (
    <CardBody
      data-cy={itemId}
      onClick={() => onClick(visibility)}
      onKeyDown={(ev: React.KeyboardEvent) => {
        ev.code === "Enter" && onClick(visibility);
      }}
      data-testid="card"
      role="button"
      tabIndex={0}
      className="card"
      visible={true}
      selected={selected}
    >
      <div className="background">
        {" "}
        <div className="header">
          <div>{title}</div>
        </div>
      </div>
    </CardBody>
  );
}
const CardBody = styled("div")({
  border: "1px solid #ccc",
  display: "inline-block",
  margin: "0 10px",
  width: "160px",
  borderRadius: "8px",
  overflow: "hidden",
  color: "#333",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Adding a subtle shadow for depth
  transition: "box-shadow 0.3s ease-in-out", // Adding transition for smoother hover effect
  cursor: "pointer", // Changing cursor to indicate interactivity

  "&:hover": {
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Enhancing shadow on hover
  },

  "& .background": {
    backgroundColor: "#f3f3f3", // Lighter background color
    height: "120px",
    padding: "10px",
    borderBottom: "1px solid #ccc", // Adding border to separate content and background
    borderTopLeftRadius: "8px", // Applying border radius only to the top-left corner
    borderTopRightRadius: "8px",
  },

  ".content": {
    padding: "10px",
  },
});

const getId = (index: number) => `${"test"}${index}`;

const getItems = () =>
  Array(10)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

function onWheel(apiObj: publicApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else {
    apiObj.scrollPrev();
  }
}

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

interface AIModelProps {
  defaultValue: string;
  handleAIModelChange: (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => void;
  disableGpt4?: boolean;
  disableGpt4x?: boolean;
  disableGpt3?: boolean;
  disableLlama?: boolean;
  disableMistral?: boolean;
}

const AIModel = ({
  defaultValue,
  handleAIModelChange,
  disableGpt4 = false,
  disableGpt4x = false,
  disableGpt4o = false,
  disableGpt3 = false,
  disableLlama = false,
  disableMistral = false,
}) => {
  const models = [
    {
      value: "gpt3",
      label: "gpt 3.5",
      disabled: disableGpt3,
      dataValue: "3.5",
    },
    {
      value: "gpt4x",
      label: "gpt 4x",
      disabled: disableGpt4x,
      dataValue: "4x",
    },
    {
      value: "gpt-4o",
      label: "gpt 4o",
      disabled: disableGpt4o,
      dataValue: "4o",
    },
    {
      value: "mistral",
      label: "Mixtral 7b",
      disabled: disableMistral,
      dataValue: "Mixtral ",
    },
  ];

  return (
    <ToggleButtonGroup
      className="toggleButtonGroup"
      color="error"
      value={defaultValue}
      exclusive
      onChange={handleAIModelChange}
      aria-label="Platform"
    >
      {models.map((model) => (
        <ToggleButton
          key={model.value}
          disabled={model.disabled}
          className="toggleButton"
          value={model.value}
          data-value={model.dataValue}
        >
          {model.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default AIModel;

import Footer from "components/branding/footer/Footer";
import NavigationHeader from "components/branding/header/NavigationHeader";
import LoginPage from "pages/Login/Login";
import React from "react";
import { Outlet } from "react-router-dom";
import authService from "services/auth.service";
import {Navigate } from "react-router-dom";
const ProtectedRoutes = () => {
  const isUserAuthenticated = authService.isAuthenticated();
  return isUserAuthenticated ? (
    <>
      <NavigationHeader />
      <Outlet />
      <Footer />
    </>
  ) : 
     <Navigate to="/" />;
};

export default ProtectedRoutes;

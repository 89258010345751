import { useNavigate } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import endpoints from "./configs/endpoints";
import { SessionKeys, StorageUtils } from "./utils/StorageUtils";

const KEY_USER_INFO = "user";
const KEY_USERS_LIST = "USER_LIST";
class AuthService {
  signUp = (data: any) => {
    return axiosInstance.post(endpoints.user.putUser, data).then((response) => {
      const data = response.data;
      if (data) {
        //localStorage.setItem(KEY_USER_INFO, JSON.stringify(response.data));
        data.isReqistrationComplete = true;
      } else {
        data.isReqistrationComplete = false;
      }
      return data;
    });
  };

  login = (email: string, password: string) => {
    let isSignedIn = false;

    const data = {
      email: email,
      password: password,
    };

    return axiosInstance.post(endpoints.user.login, data).then((response) => {
      let user = response.data;
      StorageUtils.setItem(
        SessionKeys.KEY_USERS_LIST,
        JSON.stringify(response.data)
      );

      if (user) {
        StorageUtils.setItem(SessionKeys.KEY_USER_INFO, JSON.stringify(user));
        StorageUtils.setItem(SessionKeys.TOKEN, response.data.token);
        StorageUtils.setItem(SessionKeys.IS_ADMIN, response?.data?.is_admin);

        return true;
      } else {
        return false;
      }
    });
  };

  isAdmin = () => {
    const isAdmin = StorageUtils.getItem(SessionKeys.IS_ADMIN);
    return isAdmin == "true";
  };

  isAuthenticated = () => {
    let isUserExist = localStorage.getItem(KEY_USER_INFO);
    return isUserExist !== null;
  };

  getUserId = () => {
    const userId = this.getUserInfo()?.id;
    return userId;
  };

  getUserInfo = () => {
    const user = localStorage.getItem(KEY_USER_INFO);
    if (user) {
      return JSON.parse(user);
    }
  };

  getUsers = () => {
    const users = JSON.parse(localStorage.getItem(KEY_USERS_LIST) || "");
    return users;
  };

  signOut = () => {
    const navigate = useNavigate();
    localStorage.clear();
    navigate("/");
  };
}
export default new AuthService();

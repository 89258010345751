import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Typography from "@mui/material/Typography/Typography";
import axios from "axios";
import RowLoading from "components/RowLoading";
import AIModel from "controls/AIModel";
import { Fragment, useEffect, useState } from "react";
import aiServices from "services/ai/ai.services";
import endpoints from "services/configs/endpoints";
import Constants from "services/utils/Constants";
import "./project.css";

const Confirmation = ({
  nextStep,
  prevStep,
  handleFormData,
  submitProject,
  values,
}: any) => {
  const [files, setFiles] = useState([]);
  const [issues, setIssues] = useState([]);
  const [peoples, setPeoples] = useState([]);
  const [fileContent, setFileContent] = useState("");
  const [promptPreview, setPromptPreview] = useState("");
  const [aiModel, setAIModel] = useState("llama");
  const [issue, setIssue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const system = {
    role: Constants.PROMPT_ROLES.SYSTEM,
    content:
      "You are an expert assistant. Answer the question concisely. You only provide Desired format.",
    //content:"You are an expert assistant. Provide only desired format as response.",
  };

  const prompt = {
    role: Constants.PROMPT_ROLES.USER,
    content: "",
  };

  const reset = () => {
    setPromptPreview("");
    setFileContent("");
  };

  const handleFileClick = async (inx: number): Promise<void> => {
    reset();
    const file = files[inx];
    const content = await getFileContent(file);
    applyPreview();
  };

  const handleIssue = (event: any) => {
    setIssue((event.target as HTMLInputElement).value);
  };

  const getFileContent = async (file: any) => {
    //get_file_content
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let data = new FormData();
    data.append("files", file);
    axios
      .post(endpoints.item.get_file_content, data, config)
      .then((res) => {
        setFileContent(res.data.content);
        setPeoples(res.data.names);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const applyPreview = async () => {
    try {
      const val = values.issues[issue];

      setIsLoading(true);
      const userInput = `${issue} for the following Text: {text}`;
      prompt.content = userInput;

      const previewResponse = await aiServices.previewPrompt(
        values.issues[issue].pattern,
        fileContent,
        aiModel
      );

      if (previewResponse.status == Constants.HTTP_STATUS.SUCCESS) {
        setPromptPreview(previewResponse.data);
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAIModelChange = (
    event: React.MouseEvent<HTMLElement>,
    newAIModel: string
  ) => {
    setAIModel(newAIModel);
  };

  useEffect(() => {
    setFiles(values.files);
    setPeoples(values.peoples.split("\n"));

    const issues = values.issues;
    //const filteredIssues = issues.filter((issue: any) => issue.trim() !== "");

    setIssues(issues);
  }, []);

  return (
    <Fragment>
      <div className="row mb-3">
        <strong>Project {values.projectName} created successfully.</strong>
      </div>
      <Accordion className="">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Preview your Matter</Typography>
        </AccordionSummary>
        <AccordionDetails className="p-5">
          <div className="row">
            <div className="col-6 p-5 data-pane">
              <div className="mb-3">
                <AIModel
                  defaultValue={aiModel}
                  handleAIModelChange={handleAIModelChange}
                />
              </div>
              <hr />

              <div className="mt-3">
                <FormControl>
                  <strong>Issues Involved</strong>
                  <RadioGroup name="issues-group">
                    {issues.map((issue: any, inx) => {
                      return (
                        <FormControlLabel
                          key={inx}
                          value={inx}
                          control={<Radio />}
                          label={issue.name}
                          onChange={handleIssue}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="mt-3">
                <strong>Peoples Involved</strong>
                <ul>
                  {peoples.map((people: any, inx) => {
                    return <li key={inx}>{people}</li>;
                  })}
                </ul>
              </div>

              <div className="mt-3">
                <strong>File uploaded (Click to Preview)</strong>
                <ul>
                  {files.map((file: any, inx) => {
                    return (
                      <li key={inx}>
                        <a href="#" onClick={() => handleFileClick(inx)}>
                          {file.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-6 p-5 preview">
              <div className="row mb-5">
                <strong>Answer</strong>

                {isLoading && (
                  <div className="w-100">
                    <RowLoading />
                  </div>
                )}
                {!isLoading && <span>{promptPreview}</span>}
              </div>
              <div className="row">
                <strong>File content</strong>
                <span>{fileContent}</span>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
};

export default Confirmation;

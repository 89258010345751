import TagsLanding from "./TagsLanding";

const TagsLayout = () => {
  return (
    <div>
      <TagsLanding />
    </div>
  );
};

export default TagsLayout;

import IndexQuery from "components/chat/IndexQuery";
import { useEffect } from "react";

const ChatInterface = () => {
  useEffect(() => {
    // onLoad();
  }, []);

  return (
    <div className="app">
      <div className="content">
        <IndexQuery />
      </div>
    </div>
  );
};

export default ChatInterface;

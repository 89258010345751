import React, { ReactNode } from "react";
import "./layout.css";

type ModuleLayoutProps = {
  children: ReactNode;
  title?: string;
};

const ModuleLayout = (props: ModuleLayoutProps) => {
  return (
    <>
      <div className="app-container">
        <div className="container-fluid">
          <main className="content">
            <div className="">{props.children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ModuleLayout;

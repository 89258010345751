import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { getReleaseNotes } from "../services/releasenotes/releaseNotes.service";
import Constants from "../services/utils/Constants";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
interface ReleaseNote {
  version: string;
  date: string;
  title: string;
  features: {
    feature_type: string;
    feature: string;
  }[];
}

const BasicModal = () => {
  const navigate = useNavigate();
  const [notes, setReleaseNotes] = useState<ReleaseNote[]>([]);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    getReleaseNote();
  }, [open]);
  const getReleaseNote = async () => {
    const ReleaseNotesResponse = await getReleaseNotes();

    if (ReleaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      const temp: ReleaseNote[] = [...ReleaseNotesResponse.data];
      temp.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      setReleaseNotes([...temp]);
    }
    //
  };

  // const notes = [
  //   {
  //     version: "0.10.3",
  //     date: "2024-03-16",
  //     changes: [
  //       `Bugfix: Users can now conveniently download all files without any limitations.`,
  //       `Enhancement: Enjoy a seamless viewing experience as documents are now displayed consistently alongside other document types.`,
  //     ],
  //   },
  //   {
  //     version: "0.10.2",
  //     date: "2024-03-14",
  //     changes: [
  //       `Bug fix: Cleanup of data contamination issue.`,
  //       `Bug fix: Files referenced in history can now be opened.`,
  //     ],
  //   },
  //   {
  //     version: "0.10.1",
  //     date: "2024-03-08",
  //     changes: [
  //       `Document Download Download DOCX, PDF, and other document formats
  //     directly from chat responses for improved accessibility and user
  //     convenience.`,
  //       `Experience faster and more efficient chat responses with improved
  //       performance enhancements.`,
  //       `Now easily print summary views with the newly enabled print functionality, enhancing accessibility and usability for users.`,
  //     ],
  //   },
  // ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDisplayNavigate = () => {
    const _route = `/releasenotes/display`;
    navigate(_route);
  };
  return (
    <React.Fragment>
      <a
        href="#"
        // onClick={handleClickOpen}
        onClick={handleDisplayNavigate}
        style={{ color: "yellow", fontSize: "0.9rem" }}
      >
        {" "}
        {notes.length !== 0 ? `(v${notes[0]?.version})` : ""}
      </a>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          RelevAInce v{notes[0]?.version}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom variant="h6">
            New Features:
          </Typography>

          {notes?.map((note, index) => (
            <div key={index}>
              {/* <h3>{note.version}</h3> */}
              <div className="px-1 mb-2 border-top border-bottom">
                {note.date} ({note.version})
              </div>
              <ul>
                {note.features.map((change, i) => (
                  <li key={i}>
                    <Typography gutterBottom mb={2} sx={{ display: "flex" }}>
                      <TaskAltIcon
                        fontSize="small"
                        color="success"
                        className="mx-2"
                      />
                      {change.feature_type}: {change.feature}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

export default BasicModal;

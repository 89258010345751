import DirectionsIcon from "@mui/icons-material/Directions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import React, { useState, useRef, useEffect } from "react";
import {
  visionQuery,
  visionupload,
} from "../../services/chat/chat.image.service";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import axiosInstance from "../../services/axiosInstance";
import "./chat.css";

const ChatInterface = () => {
  const [prompt, setPrompt] = useState(null);
  const [files, setFiles] = useState([]);
  const [responses, setResponses] = useState([]);
  const [preview, setProjects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const containerRef = useRef(null);
  useEffect(() => {
    // Scroll to the bottom of the container when new content is added
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [responses]);
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);

    setProjects({
      file: URL.createObjectURL(e.target.files[0]),
    });

    uploadImage(e.target.files);
    setInputKey(Date.now());
  };

  const handlePromptChange = (e) => {
    setPrompt(e);
  };

  const handleQuery = async () => {
    setIsLoading(true);
    try {
      const response = await visionQuery(prompt);
      setResponses([...responses, { prompt, response }]);
    } catch (error) {
    } finally {
      setPrompt("");
      setIsLoading(false);
    }
  };

  const uploadImage = async (files) => {
    setIsLoading(true);
    const defaultPrompt = "Describe these images";
    try {
      const response = await visionupload(files, defaultPrompt);
      setResponses((responses) => [...responses, { defaultPrompt, response }]);
    } catch (error) {
    } finally {
      setFiles([]);
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (files.length > 0) {
      //uploadImage();
    } else {
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleQuery();
    }
  };

  return (
    <div className="container p-5">
      <div className="row p-5">
        <div className="col-6">
          <img style={{ width: "500px", height: "500px" }} src={preview.file} />
        </div>
        <div className="col-6">
          <div
            ref={containerRef}
            className="responses-container"
            style={{
              maxHeight: "700px",
              overflowY: "auto",
              position: "relative",
            }}
          >
            {responses.map((item, index) => (
              <div key={index} className="response-item mb-3">
                <p>
                  <strong>Prompt:</strong> {item.prompt}
                </p>
                <p>
                  <strong>Response:</strong> {item.response}
                </p>
              </div>
            ))}
            {isLoading && <LinearProgress />}
            <div className="end-of-chat"></div>
          </div>
        </div>
      </div>

      <div className="container chat-input col-md-10 position-fixed">
        <div className="shadow-lg p-3 bg-body rounded input-group p-0 mt-2">
          <div className="col">
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="col-1.5 ">
                <label htmlFor="choosefile" className="custom-file-upload">
                  Upload Images
                  <input
                    key={inputKey}
                    type="file"
                    id="choosefile"
                    onChange={handleFileChange}
                    className="form-control"
                    style={{ display: "none" }}
                    multiple
                  />
                </label>
              </div>

              <Divider sx={{ height: 28, m: 0.8 }} orientation="vertical" />
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Query the image"
                inputProps={{ "aria-label": "query the image" }}
                onKeyDown={(e) => (e.key === "Enter" ? handleKeyDown(e) : null)}
                onChange={(e) => handlePromptChange(e.target.value)}
                disabled={isLoading}
                value={prompt}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={handleQuery}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;

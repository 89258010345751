import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

import { useLocation } from 'react-router-dom';
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import "./releaseNotes.css";
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Typography, TextareaAutosize, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { updateReleaseNote, getReleaseNote } from '../../services/releasenotes/releaseNotes.service';
import Constants from "../../services/utils/Constants";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CircularProgress,
Box,
} from '@mui/material';
import { fi } from 'date-fns/locale';

function AddReleaseNotes() {

  const location = useLocation();
  const releaseNoteId = location.state && location.state.releaseNoteId;

  const [releaseNote, setReleaseNote] = useState();
  const id = releaseNoteId;

  const [formData, setFormData] = useState([]);

  const [open, setOpen] = useState(false);

  const [editableId, setEditableId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [formData2 , setFormData2]= useState();
  useEffect(() => {

    const getReleaseNoteFunc = async () => {
      setLoading(true);
      const releaseNotesResponse = await getReleaseNote(id);

      if (releaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        setReleaseNote(releaseNotesResponse.data);
        setFormData(releaseNotesResponse.data.features);
      }
      setLoading(false);
    };

    getReleaseNoteFunc();

  }, [])



  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFeatureChange = (index, field, value) => {
   
    const updatedFormData = formData.map((elm, idx) => (idx === index ? { ...elm, [field]: value } : elm));

    setFormData(updatedFormData);

  };

  const handleAddChange = async (field,value)=>{
       
       setFormData2({ ...formData2, [field]: value });
 
  }

   const handleAddFeature = async () => {
    const tempUpdateData = { ...formData };

    const lastIndex = Object.keys(tempUpdateData).length;
  
  
  tempUpdateData[lastIndex] = formData2;
    const releaseUpdateData = { ...releaseNote, features: Object.values(tempUpdateData) };
  


  
    setFormData(tempUpdateData);
  

    const releaseNotesResponse = await updateReleaseNote({
      formData: releaseUpdateData,
      id: id
    });

    if (releaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      setReleaseNote({ ...releaseNote, features: releaseNotesResponse?.data?.features });
      setFormData(releaseNotesResponse?.data?.features);
     
    }

 
    
  };

  const handleEditFeature = async () => {

    const tempUpdateData = { ...releaseNote, features: formData };

 
    const releaseNotesResponse = await updateReleaseNote({
      formData: tempUpdateData,
      id: id
    });

    if (releaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      setReleaseNote({ ...releaseNote, features: releaseNotesResponse?.data?.features });
      setFormData(releaseNotesResponse?.data?.features);
      setOpen(false);
    }
    // setOpen(false);

  };

  


  const handleCloseDialog = () => {
    setOpen(false);
    setEditableId(null);
    setFormData(releaseNote?.features);
  };
  const handleDelete= async (i)=>{
    setDialogOpen(true);
  }

  const handleDialogClose = async (confirmed,i) => {


    if (confirmed) {
      const filteredData = releaseNote?.features?.filter((item) => (item.id !== i));
    const tempUpdateData = { ...releaseNote, features: filteredData };

    const releaseNotesResponse = await updateReleaseNote({
      formData: tempUpdateData,
      id: id
    });
    if (releaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
  
      setReleaseNote({ ...releaseNote, features: releaseNotesResponse?.data?.features });
      setFormData(releaseNotesResponse?.data?.features);
      // setOpen(false);
    }

    }
    setDialogOpen(false);
  };
  return (

    <div className="container">

      <Dialog open={open} onClose={handleCloseDialog}  fullWidth   height="500px">
        <DialogTitle display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} gap={4}><span>Edit note</span><IconButton
          aria-label="close"
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton></DialogTitle>

        <DialogContent>
          <DialogContentText>
            <div className="px-1 mb-2 border-top">
              <form >

                <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px' }}>
                  <Grid item xs={4}>
                    <FormControl fullWidth>

                      <Select
                        value={formData?.[editableId]?.feature_type || ""}
                        onChange={(e) => handleFeatureChange(editableId, 'feature_type', e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={formData?.[editableId]?.feature_type ? {} : { color: "gray" }}
                      >
                        <MenuItem value="" disabled selected>Type</MenuItem>
                        <MenuItem value="Bug Fix">Bug Fix</MenuItem>
                        <MenuItem value="Feature Request">Feature Request</MenuItem>
                        <MenuItem value="Enhancement">Enhancement</MenuItem>
                      </Select>

                    </FormControl>

                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Description"
                      value={formData?.[editableId]?.feature}
                      onChange={(e) => handleFeatureChange(editableId, 'feature', e.target.value)}
                      fullWidth
                      multiline
                      rows={2}
                    />
                  </Grid>
                </Grid>

              </form>
            </div>


          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button
            variant="contained"
            color="primary"
            onClick={handleEditFeature}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
 
     


      {loading && (
            <Box position={"absolute"} top={"15%"} left={"50%"} transform={"translate(-50%, -15%)"}>
              <CircularProgress />
            </Box>)}
     {!loading &&  <div>
      <div className="px-1 mb-2 border-top ">
        <form >
          <Typography gutterBottom variant="h6" style={{marginTop: "20px"}}>
            Add Feature
          </Typography>


        
            <Grid container spacing={2} alignItems="center" >
              <Grid item xs={4}>
                <FormControl fullWidth>

                  <Select
                    // value={feature.feature_type}
                    onChange={(e) => handleAddChange('feature_type', e.target.value)}
                    displayEmpty
                    // label="Select"
                    inputProps={{ 'aria-label': 'Without label' }}
                    // sx={feature.feature_type ? {} : { color: "gray" }}
                  >
                    <MenuItem value="" disabled selected>Type</MenuItem>
                    <MenuItem value="Bug Fix">Bug Fix</MenuItem>
                    <MenuItem value="Feature Request">Feature Request</MenuItem>
                    <MenuItem value="Enhancement">Enhancement</MenuItem>
                  </Select>

                </FormControl>


              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  // value={feature.feature}
                  onChange={(e) => handleAddChange('feature', e.target.value)}
                  fullWidth
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            // startIcon={<AddIcon />}
             onClick={handleAddFeature}
          >
            Add
          </Button>
          </Grid>
            </Grid>
           
        </form>
      </div>
      <div style={{ marginBottom: "10%", marginTop: "3%" }}>
        <Typography variant="h6">
          Version ({releaseNote?.version})
        </Typography>
        <div className="px-1 mb-2 border-top border-bottom">
          {releaseNote?.date} ({releaseNote?.version})
        </div>

        <div style={{ marginBottom: "10%", marginTop: "1%" }} key={releaseNote}>
          {/* <h3>{note.version}</h3> */}

          <ul>
            {releaseNote?.features?.map((feature, i) => (
              <div key={i}>
                <li>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography gutterBottom mb={2} sx={{ display: "flex" }} variant="h6">
                      <TaskAltIcon
                        fontSize="small"
                        color="success"
                        className="mx-2"
                      />
                      {`${feature?.feature_type}: ${feature?.feature}`}
                    </Typography>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <IconButton
                        onClick={() => { setOpen(true); setEditableId(i) }}
                        color="primary"
                        aria-label="edit"
                        sx={{ justifySelf: "flex-end" }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                       onClick={() => handleDelete(feature.id)}
                      // color="primary"
                      // aria-label="edit"
                      // sx={{ justifySelf: "flex-end" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </li>
                <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
          <DialogTitle>  Feature will be deleted</DialogTitle>
          <DialogActions>
            <Button onClick={() => handleDialogClose(false,feature.id )} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDialogClose(true, feature.id)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
              </div>
              
            ))}
          </ul>
        </div>
      </div>

    
      </div>}
    </div>
  );
}

export default AddReleaseNotes;



import TextField from "@mui/material/TextField/TextField";
import React, { useEffect, useState } from "react";
import authService from "../services/auth.service";
import tagsServices from "../services/tags/tags.services";
import { ITags } from "../services/types/types";
import Constants from "../services/utils/Constants";
import utils from "../services/utils/utils";
import "./tags-input.css";

const TagsInput = ({ tagSource }: any) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [userTags, setUserTags] = useState<ITags[]>();

  const getTagsByFilename = async (filename: string) => {
    const tagsResponse = await tagsServices.getTagsByFilename(filename);

    if (tagsResponse.status == Constants.HTTP_STATUS.SUCCESS) {
      const data = tagsResponse.data;

      let t: string[] = [];

      data.map((d) => {
        t.push(d.tag_name);
      });
      setTags(t);
    }
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e: any) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      // Prevent duplicates
      if (!tags.includes(inputValue)) {
        setTags([...tags, inputValue.trim()]);

        const tag: ITags = {
          tag_name: inputValue,
          file_name: tagSource?.trim(),
          tag_type: "",
          modified_date: utils.getCurrentDateTime(),
          document_id: 0,
          owner_id: authService.getUserId(),
        };

        tagsServices.createTag(tag);

        setInputValue("");
      }

      setTimeout(() => {
        const data = {
          filename: tagSource?.trim(),
          tags: tags,
        };
      }, 200);
    }
  };

  const handleTagRemove = (tag: any) => {
    const updatedTags = tags.filter((t) => t !== tag);
    setTags(updatedTags);
  };

  useEffect(() => {
    getTagsByFilename(tagSource.trim());
  }, []);

  return (
    <div className="shadow mb-1 bg-body rounded tags-input w-100 px-5 py-3">
      <div className="tags w-100">
        {tags.map((tag, index) => (
          <div key={index} className="tag">
            {tag}
            <button onClick={() => handleTagRemove(tag)}>&times;</button>
          </div>
        ))}
      </div>
      <TextField
        className="mt-3"
        variant="standard"
        placeholder="Add a tag"
        fullWidth
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
    </div>
  );
};

export default TagsInput;

import React, { Fragment } from "react";
import ModuleLayout from "../../components/ModuleLayout";
import BulkUpload from "./BulkUpload";

const BulkUploadLayout = () => {
  return (
    <Fragment>
      <ModuleLayout title="">
        <BulkUpload />
      </ModuleLayout>
    </Fragment>
  );
};

export default BulkUploadLayout;

import { classNames } from "@progress/kendo-react-common";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { getName } from "../helpers/helperMethods";
import { DataModel } from "./../interfaces/FileManagerModels";

const selectedStyle = {
  backgroundColor: 'lightblue',
};
export const ListView = (props: any) => {
  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: DataModel
  ) => {
    props.onItemClick({
      dataItem: item,
      event: event,
    });
  };

  const handleDoubleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: DataModel
  ) => {
    props.onDoubleClick({
      dataItem: item,
      event: event,
    });
  };

  const handleContextMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: DataModel
  ) => {
    event.preventDefault();
    props.onContextMenu({
      dataItem: item,
      event: event,
    });
  };
  return (
    <div
      key={uuidv4()}
      className="k-listview k-selectable k-filemanager-listview"
    >
      <div className="k-listview-content k-d-flex k-flex-row k-flex-wrap">
        {props.data
          ? props.data.data.map((item: any) => {
              const name = getName(item.path);
              return (
                <>
                  <div
                    className={classNames("k-listview-item", {
                      "k-selected": item.selected,
                    })}
                    style={item.selected ? selectedStyle : {}}
                    onClick={(event) => handleClick(event, item)}
                    onDoubleClick={(event) => handleDoubleClick(event, item)}
                    onContextMenu={(event) => handleContextMenu(event, item)}
                  >
                    <span className="k-file-preview">
                      <span
                        className={classNames(
                          "k-file-icon k-icon",
                          item.icon ? item.icon.iconClass : ""
                        )}
                      />
                    </span>
                    <span className="k-file-name">{name}</span>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    </div>
  );
};

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha, styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const ButtonDropdown = ({
  data,
  handleSelection,
  selectedMatter,
  initialValue = "Matters",
}: any) => {
  const defaultLabel = "Matters";
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedProject, setSelectedProject] = useState(
    !!selectedMatter
      ? selectedMatter.project_name
      : data[0]
      ? data[0].project_name
      : defaultLabel
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    handleSelection(event);
    setSelectedProject(event.currentTarget.textContent || initialValue);
  };

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const matterIdFromUrl = urlParams.get("matterid");

    if (data) {
      if (matterIdFromUrl) {
        const project = data.find(
          (p: any) => p.id.toString() === matterIdFromUrl
        );
        //
        if (project) {
          setSelectedProject(project.project_name);
          //
        }
      } else if (selectedMatter) {
        setSelectedProject(selectedMatter.project_name);
        //
      }
    }
  }, [data, selectedMatter, location.search]);

  return (
    <div>
      <Button
        aria-controls={open ? "open" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selectedProject}
      </Button>
      <StyledMenu
        id="open"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {data?.map((menu: any) => {
          return (
            <MenuItem
              key={uuidv4()}
              onClick={handleClose}
              data-output={menu.id}
              disableRipple
            >
              {menu.project_name}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

export default ButtonDropdown;

import authService from "../auth.service";
import axiosInstance from "../axiosInstance";
import endpoints from "../configs/endpoints";
import { FolderRequest } from "../types/filing.cabinet.types";
import utils from "../utils/utils";

export type FilingCabinetData = {
  filename: string;
  originalPath?: string;
  file_type: string;
  parent_node_ids: any;
  size: number;
  matter_id: number;
  chat_id: number;
};

export const getFiles = async (matterId: string) => {
  return axiosInstance.get(
    endpoints.filecabinate.getFiles.replace("{matter_id}", matterId)
  );
};

export const getFolders = async (matterId: string) => {
  if (matterId !== "-11") {
    return axiosInstance.get(
      endpoints.filecabinate.getFolder.replace("{matter_id}", matterId)
    );
  }
};

export const deleteFolderOrFile = async (matter_id, id) => {
  try {
    const url = endpoints.filecabinate.deleteFile
      .replace("{matter_id}", matter_id)
      .replace("{file_id}", id);

    return axiosInstance.delete(url);
  } catch (error) {
    console.error(error);
  }
};

export const addToFolders = (
  matter_id,
  fileReference,
  parent_node_ids: any = [-1],
  chat_id: number = -1
) => {
  try {
    const filePath =
      parent_node_ids == -1 ? fileReference : fileReference.filename?.trim();
    const requestData = {
      path: filePath,
      original_path: fileReference.originalPath,
      file_type: fileReference.referenceType,
      parent_node_ids: parent_node_ids,
      size: 0,
      dateCreated: utils.getISODateString(),
      dateModified: utils.getISODateString(),
      matter_id: matter_id,
      chat_id: chat_id,
      owner_id: authService.getUserId(),
    };
    const url = endpoints.filecabinate.createMultiplev2;
    return axiosInstance.post(url, requestData);
  } catch (error) {
    console.error(error);
  }
};

export const addChatToFilingCabinet = (data: FilingCabinetData) => {
  try {
    const requestData = {
      path: data.filename,
      original_path: data.originalPath,
      file_type: data.file_type,
      parent_node_ids: data.parent_node_ids,
      size: data.size,
      dateCreated: utils.getISODateString(),
      dateModified: utils.getISODateString(),
      matter_id: data.matter_id,
      chat_id: data.chat_id,
      owner_id: authService.getUserId(),
    };

    const url = endpoints.filecabinate.createMultiplev2;
    return axiosInstance.post(url, requestData);
  } catch (error) {
    console.error(error);
  }
};

export const addChat = (chat: string, matter_id: number) => {
  try {
    const requestData = {
      chat: chat,
      matter_id: matter_id,
    };

    const url = endpoints.filecabinate.createChat;
    return axiosInstance.post(url, requestData);
  } catch (error) {
    console.error(error);
  }
};

export const getChat = (id: number) => {
  try {
    const url = endpoints.filecabinate.readChat.replace("{id}", id.toString());
    return axiosInstance.get(url);
  } catch (error) {
    console.error(error);
  }
};

export const getFile = (filename: string) => {
  try {
    const url = endpoints.filecabinate.readChat;
    return axiosInstance.get(url);
  } catch (error) {
    console.error(error);
  }
};

export const createRootDirectory = (data: FolderRequest) => {
  try {
    const url = endpoints.filecabinate.rootDirectory;
    return axiosInstance.post(url, data);
  } catch (error) {
    console.error(error);
  }
};

export const rename = (data: FolderRequest) => {
  try {
    const url = endpoints.filecabinate.rename;
    return axiosInstance.put(url, data);
  } catch (error) {
    console.error(error);
  }
};

export const fileupload = async (files, selectedMatterId, onUploadProgress) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i]);
  }

  const apiUrl = endpoints.filecabinate.uploadFiles
    .replace("{user_id}", authService.getUserId().toString())
    .replace("{project_id}", selectedMatterId);

  const response = await axiosInstance.post(apiUrl, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });

  return response.data;
};

import React, { useState, useEffect } from "react";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
// import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { useLocation } from "react-router-dom";
import Constants from "../../services/utils/Constants";
import {
  getFeedbackByFeedbackId,
  createComment,
  updateStatus,
} from "../../services/feedback/feedback.service";
import authService from "../../services/auth.service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AdvanceTextarea from "../../controls/AdvanceTextarea";
import "./feedbackSupport.css";
import ModuleLayout from "../../components/ModuleLayout";
import { useNavigate } from "react-router-dom";

// import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
const MessageCard = ({ msg, index }) => {
  const userCardStyle = {
    marginBottom: "16px",
    backgroundColor: "#f5f5f5",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
  };
  const containerStyle = {
    width: "60%",
    margin: "0 auto",
  };
  const senderStyle = {
    fontWeight: "bold",
    marginBottom: "5px",
  };

  return (
    <>
      <div style={containerStyle}>
        <Card key={index} style={userCardStyle}>
          <CardContent>
            {/* <DeleteOutlineIcon
              style={{ position: 'absolute', right: '40%', cursor: 'pointer' }}
              onClick={handleDelete}
            /> */}

            <p style={senderStyle}>
              <AccountCircleRoundedIcon /> {msg.user_name}
            </p>

            <Typography variant="body2">
              {" "}
              <Editor
                wrapperClassName="wrapper"
                editorClassName="editor"
                toolbarHidden
                readOnly
                defaultContentState={JSON.parse(msg?.message)}
              />
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

const FeedbackSupport = () => {
  const params = useLocation();
  const id = params.pathname?.slice(11);
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [feedbackResponse, setFeedbackResponse] = useState();
  const [value, setValue] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState("open");
  const [closeConfirmed, setCloseConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getFeedbacks = async () => {
      setLoading(true);
      const FeedbacksResponse = await getFeedbackByFeedbackId(
        Number.parseInt(id)
      );

      if (FeedbacksResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        const temp = FeedbacksResponse.data;
        setFeedbackStatus(FeedbacksResponse.data.status);

        setFeedbackResponse(temp);

        setMessages(
          temp.comments.map((dt) => ({
            id: dt?.id || 1,
            message: dt?.content || "",
            user_name: dt?.user_name,
          }))
        );
      }
      setLoading(false);
    };
    getFeedbacks();
  }, [id]);

  useEffect(() => {
    if (closeConfirmed) {
      setFeedbackStatus("closed");
    }
  }, [closeConfirmed]);

  const handleSend = async () => {
    const commentsResponse = await createComment({
      feedback_id: feedbackResponse.id,
      msg: JSON.stringify(value),
    });
    if (commentsResponse.status === Constants.HTTP_STATUS.SUCCESS) {
      const temp = [
        ...messages,
        {
          id: commentsResponse.data.id,
          message: commentsResponse.data.content,
          user_name: authService.getUserInfo().full_name,
        },
      ];

      temp.sort((a, b) => {
        return new Date(b.modified_date) - new Date(a.modified_date);
      });
      setMessages([...temp]);
      setValue(null);
    }
  };

  const handleCloseStatus = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    if (confirmed) {
      const statusResponse = await updateStatus({
        ...feedbackResponse,
        status: "Closed",
        feedback_id: Number.parseInt(id),
      });

      if (statusResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        setCloseConfirmed(true);
        setFeedbackStatus("Closed");
      }
    }
    setDialogOpen(false);
  };

  const handleBackNavigate = () => {
    const _route = `/feedbacks/`;
    navigate(_route);
  };

  return (
    <ModuleLayout>
      <div className="grid-container">
        <div className="grid-item grid-item1" style={{ marginRight: "-7%" }}>
          {loading && (
            <Box
              position={"absolute"}
              top={"15%"}
              left={"50%"}
              transform={"translate(-50%, -15%)"}
            >
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <IconButton aria-label="back" onClick={handleBackNavigate}>
                <ArrowBackIosIcon fontSize="small" />
                <Typography variant="body1">Back</Typography>
              </IconButton>
              <Typography
                variant="h4"
                align="center"
                margin="2%"

              >
                {`[Ticket #${feedbackResponse?.id}]    ${feedbackResponse?.title}`}
              </Typography>
            </div>
          )}

          {!loading &&
            messages.map(
              (msg, index) =>
                msg?.message && (
                  <MessageCard key={index} msg={msg} index={index} />
                )
            )}

          {!closeConfirmed && !loading && feedbackStatus !== "Closed" && (
            <div style={{ width: "60%", margin: "0 auto", marginTop: "16px" }}>
              <AdvanceTextarea value={value} setValue={setValue} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSend}
                style={{
                  marginTop: "8px",
                  marginLeft: "10px",
                  height: "40px",
                  fontSize: "16px",
                  marginBottom: "60px",
                }}
              >
                Send
              </Button>
            </div>
          )}
        </div>
        {!loading && (
          <div className="grid-item grid-item2">
            <Typography
              variant="h5"
              align="left"
              style={{ margin: "20px 0", fontWeight: "bold" }}
            >
              Status
            </Typography>
            <Typography variant="h5" align="left" style={{ margin: "20px 0" }}>
              {feedbackStatus}
            </Typography>
            {!closeConfirmed && feedbackStatus !== "Closed" && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseStatus}
              >
                Close Feedback
              </Button>
            )}
          </div>
        )}
        <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
          <DialogTitle> Feedback will be closed</DialogTitle>
          <DialogActions>
            <Button onClick={() => handleDialogClose(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDialogClose(true)} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ModuleLayout>
  );
};

export default FeedbackSupport;

export const SessionKeys = {
  KEY_USER_INFO: "user",
  KEY_USERS_LIST: "USER_LIST",
  USER_ROLE: "USER_ROLE",
  IS_ADMIN: "is_admin",
  TOKEN: "token",
};

export class StorageUtils {
  static setItem(
    key: string,
    value: string,
    isSessionStorage: boolean = false
  ) {
    if (isSessionStorage) {
      sessionStorage.setItem(key, value);
    } else {
      localStorage.setItem(key, value);
    }
  }

  static getItem(
    key: string,
    isSessionStorage: boolean = false
  ): string | null {
    if (isSessionStorage) {
      return sessionStorage.getItem(key);
    } else {
      return localStorage.getItem(key);
    }
  }

  static removeItem(key: string, isSessionStorage: boolean = false) {
    if (isSessionStorage) {
      sessionStorage.removeItem(key);
    } else {
      localStorage.removeItem(key);
    }
  }

  static clear(isSessionStorage: boolean = false) {
    sessionStorage.clear();
    localStorage.clear();
  }
}

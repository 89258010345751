import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Constants from "../../services/utils/Constants";
import {
  getExtractedFiles,
  indexingStatus,
  getSourceFiles,
  getSummary,
  getExtractedSummary,
} from "../../services/MatterCreation/matterCreation.services";
import { Route, Routes, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
  Grid,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMatters } from "../../context/MattersProvider";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
const formatTime = (time) => {
  if (time === null) return "N/A";
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);
  return `${hours}h ${minutes}m ${seconds}s`;
};
const MatterCreation = () => {
  const navigate = useNavigate();
  const { matter, setMatter } = useMatters();
  const [fileData, setFileData] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [extractedCount, setExtractedCount] = useState(null);
  const [totalExtractedFiles, setTotalExtractedFiles] = useState(null);
  const [indexingStatusData, setIndexingStatusData] = useState(null);
  const [summaryResponse, setSummaryResponse] = useState(null);
  const [extracedSummaryResponse, setExtractedSummaryResponse] = useState(null);
  const { project_details, indexing_status } = indexingStatusData
    ? indexingStatusData
    : { project_details: null, indexing_status: null };
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [parentId, setParentId] = useState(null);
  useEffect(() => {
    const getMattersFunc = async () => {
      setLoading((prev) => prev);

      const matter_id = id;
      const MattersResponse = await getSourceFiles(
        matter_id,
        0 // offset
      );
      if (parentId) {
        const extractedDataRes = await getExtractedFiles(matter_id, parentId);
        if (extractedDataRes.status === Constants.HTTP_STATUS.SUCCESS) {
          let extractedDataTemp = [...extractedDataRes.data.files];
          const modifiedExtractedData = extractedDataTemp.map((obj) => ({
            file_name:
              obj.file_name.charAt(0).toUpperCase() + obj.file_name.slice(1),
            file_type:
              obj.file_type.charAt(0).toUpperCase() + obj.file_type.slice(1),
            file_size: obj.formatted_file_size
              ? obj.formatted_file_size
              : "N/A",
            createdAt: new Date(obj.createdAt).toUTCString(),
            file_status: obj.file_status
              ? obj.file_status.charAt(0).toUpperCase() +
                obj.file_status.slice(1)
              : "Not available",
          }));
          setExtractedData(modifiedExtractedData);
          setTotalExtractedFiles(modifiedExtractedData);
          setExtractedCount(extractedDataRes.data.file_types);
        }
      }
      let indexingStatusResponse = await indexingStatus(matter_id);
      const extracetdSummary = await getExtractedSummary(matter_id);
      const summary = await getSummary(matter_id);
      if (summary.status === Constants.HTTP_STATUS.SUCCESS) {
        setSummaryResponse(summary.data);
      }
      if (extracetdSummary.status === Constants.HTTP_STATUS.SUCCESS) {
        setExtractedSummaryResponse(extracetdSummary.data);
      }

      if (indexingStatusResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        setIndexingStatusData(indexingStatusResponse.data);
        setMatter({
          ...indexingStatusResponse.data.project_details,
          id: indexingStatusResponse.data.project_details?.id
            ? indexingStatusResponse.data.project_details.id
            : matter_id,
        });
      }

      if (MattersResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        let temp = [...MattersResponse.data];
        const filteredTemp = temp.filter((obj) =>
          Object.values(obj).some(
            (value) => value !== null && value !== undefined
          )
        );
        const modifiedTemp = filteredTemp.map((obj) => ({
          file_name:
            obj.file_name.charAt(0).toUpperCase() + obj.file_name.slice(1),
          file_type:
            obj.file_type.charAt(0).toUpperCase() + obj.file_type.slice(1),
          file_size: obj.formatted_file_size ? obj.formatted_file_size : "N/A",
          createdAt: new Date(obj.createdAt).toUTCString(),
          file_status:
            obj.file_status.charAt(0).toUpperCase() + obj.file_status.slice(1),
          id: obj.id,
        }));
        setFileData(modifiedTemp);
      }

      setLoading(false);
    };
    getMattersFunc();

    // const intervalId = setInterval(async () => {
    //   getMattersFunc();
    // }, 30000);

    // return () => clearInterval(intervalId);
  }, [parentId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "file_name",
        header: "File name ",
      },
      {
        accessorKey: "file_type",
        header: "File Type",
      },
      {
        accessorKey: "file_size",
        header: "File Size",
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
      },
      {
        accessorKey: "file_status",
        header: "Status",
      },
    ],
    []
  );
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  const countArray = Object.entries(
    summaryResponse?.count_of_each_type || []
  ).map(([type, count]) => ({ type, count }));
  const extractedcountArray = Object.entries(
    extracedSummaryResponse?.count_of_each_type_attachments || []
  ).map(([type, count]) => ({ type, count }));

  const chunkedCountArray = chunkArray(countArray, 4);
  const extractedChunk = chunkArray(extractedcountArray, 6);

  const sourceTable = useMaterialReactTable({
    columns,
    data: fileData ?? [],
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Button
        key="view"
        onClick={() => {
          setParentId(row.original.id);
        }}
      >
        View files
      </Button>
    ),
  });

  const extractedTable = useMaterialReactTable({
    columns,
    data: extractedData ?? [],
  });
  const selectedFile = fileData
    ? fileData?.filter((obj) => obj.id === parentId)[0]?.file_name
    : "";
  return (
    <>
      {!loading && (
        <div
          style={{ textAlign: "center", marginTop: "20px" }}
          className="container"
        >
          {indexingStatusData ? (
            <Card>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  {project_details.project_name}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {project_details.project_desc}
                </Typography>

                <Box mt={2}>
                  <Typography variant="subtitle1" gutterBottom>
                    Indexing Status
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="text.secondary">
                        Email Progress
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={
                          indexing_status.email_percentage === null ||
                          indexing_status.email_percentage === false
                            ? 0
                            : (indexing_status.email_percentage / 100) * 100
                        }
                      />
                      <Typography variant="body2" color="text.secondary">
                        {indexing_status.email_percentage.toFixed(2)}% - Time
                        Estimate:{" "}
                        {indexing_status.email_time_est === null ||
                        indexing_status.email_time_est === false
                          ? "N/A"
                          : formatTime(indexing_status.email_time_est)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" color="text.secondary">
                        Attachment Progress
                      </Typography>
                      <LinearProgress
                        variant="determinate"
                        value={
                          indexing_status.attachment_percentage === null ||
                          indexing_status.attachment_percentage === false
                            ? 0
                            : (indexing_status.attachment_percentage / 100) *
                              100
                        }
                      />
                      <Typography variant="body2" color="text.secondary">
                        {indexing_status.attachment_percentage.toFixed(2)}% -
                        Time Estimate:{" "}
                        {indexing_status.attachment_time_est === null ||
                        indexing_status.attachment_time_est === false
                          ? "N/A"
                          : formatTime(indexing_status.attachment_time_est)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {indexing_status.email_ready === true &&
                indexing_status.attachment_ready === false ? (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      navigate(`/aichat`, {
                        state: {
                          project: { ...project_details },
                        },
                      })
                    }
                  >
                    Chat with Emails
                  </Button>
                ) : null}
                {indexing_status.email_ready === false &&
                indexing_status.attachment_ready === true ? (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      navigate(`/aichat`, {
                        state: {
                          project: { ...project_details },
                        },
                      })
                    }
                  >
                    Chat with Attachments
                  </Button>
                ) : null}
                {indexing_status.email_ready === true &&
                indexing_status.attachment_ready === true ? (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() =>
                      navigate(`/aichat`, {
                        state: {
                          project: { ...project_details },
                        },
                      })
                    }
                  >
                    Chat
                  </Button>
                ) : null}
              </CardContent>
            </Card>
          ) : null}
          {summaryResponse !== null ? (
            <div className="mt-4">
              <Grid container spacing={3}>
                {/* Total Files */}
                <Grid item xs={24} sm={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Total Files</Typography>
                      <Typography variant="body1">
                        {summaryResponse.total_files}
                      </Typography>
                    </CardContent>

                    <CardContent>
                      <Typography variant="h6">Total Size</Typography>
                      <Typography variant="body1">
                        {summaryResponse.total_size
                          ? summaryResponse.total_size
                          : "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Extracted Files */}
                <Grid item xs={24} sm={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Extracted Files</Typography>
                      <Typography variant="body1">
                        {summaryResponse.extracted_files}
                      </Typography>
                    </CardContent>

                    <CardContent>
                      <Typography variant="h6">Extracted Files Size</Typography>
                      <Typography variant="body1">
                        {summaryResponse.extracted_files_size
                          ? summaryResponse.extracted_files_size
                          : "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={24} sm={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Processed Files</Typography>
                      <Typography variant="body1">
                        {summaryResponse.processed_files}
                      </Typography>
                    </CardContent>

                    <CardContent>
                      <Typography variant="h6">Processed Files Size</Typography>
                      <Typography variant="body1">
                        {summaryResponse.processed_files_size
                          ? summaryResponse.processed_files_size
                          : "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>{" "}
                <Grid item xs={24} sm={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Error Files</Typography>
                      <Typography variant="body1">
                        {summaryResponse.error_files}
                      </Typography>
                    </CardContent>

                    <CardContent>
                      <Typography variant="h6">Error Files Size</Typography>
                      <Typography variant="body1">
                        {summaryResponse.error_files_size
                          ? summaryResponse.error_files_size
                          : "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={24} sm={12} md={6}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Skipped Files</Typography>
                      <Typography variant="body1">
                        {summaryResponse.skipped_files}
                      </Typography>
                    </CardContent>

                    <CardContent>
                      <Typography variant="h6">Skipped Files Size</Typography>
                      <Typography variant="body1">
                        {summaryResponse.skipped_files_size
                          ? summaryResponse.skipped_files_size
                          : "N/A"}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* Count of Each Type Table */}
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Count of Each Type</Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {chunkedCountArray.map((chunk, index) => (
                              <TableRow key={index}>
                                {chunk.map(({ type, count }) => (
                                  <TableCell key={type}>
                                    <Typography variant="body1">
                                      <strong>{type}</strong>: {count}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : null}
          {extracedSummaryResponse !== null ? (
            <div className="mt-4">
              <Grid container spacing={3}>
                {/* Count of Each Type Table */}
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">
                        Count of Extracted File Type
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {extractedChunk.map((chunk, index) => (
                              <TableRow key={index}>
                                {chunk.map(({ type, count }) => (
                                  <TableCell key={type}>
                                    <Typography variant="body1">
                                      <strong>{type}</strong>: {count}
                                    </Typography>
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : null}

          {fileData ? (
            <>
              <h2
                style={{
                  marginBottom: "20px",
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Source Files
              </h2>
              <MaterialReactTable table={sourceTable} />
            </>
          ) : null}

          {extractedData ? (
            <>
              <h2
                style={{
                  marginBottom: "20px",
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                Extracted Files for {selectedFile}
              </h2>
              <Card
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                {extractedCount
                  ? extractedCount.map((obj) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "20px",
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "black",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setExtractedData(
                            totalExtractedFiles.filter((file) => {
                              return (
                                file.file_type.toUpperCase() ===
                                obj.type.toUpperCase()
                              );
                            })
                          );
                        }}
                        onMouseEnter={(e) => {
                          e.target.style.backgroundColor = "#f5f5f5";
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.backgroundColor = "white";
                        }}
                      >
                        <span>
                          {obj.type.charAt(0).toUpperCase() + obj.type.slice(1)}{" "}
                          Files
                        </span>
                        <span>{obj.count}</span>
                        <span>{obj.size}</span>
                      </div>
                    ))
                  : null}
              </Card>

              <MaterialReactTable table={extractedTable} />
            </>
          ) : null}
        </div>
      )}{" "}
    </>
  );
};

export default MatterCreation;

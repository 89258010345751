import Box from "@mui/material/Box/Box";
import Button from "@mui/material/Button/Button";
import { Fragment, useEffect } from "react";

const UploadDocuments = ({
  nextStep,
  prevStep,
  handleFormData,
  submitProject,
  values,
}: any) => {
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Box sx={{ p: 3 }}>
        <label className="form-label fw-bold" htmlFor="choosefile">
          Please select documents to upload
        </label>
        z
        <div className="d-flex flex-row mb-3">
          <input
            type="file"
            id="choosefile"
            className="form-control"
            onChange={handleFormData("files")}
            multiple
          />

          <Button onClick={submitProject} className="mx-3" variant="contained">
            Upload
          </Button>
        </div>
      </Box>
    </Fragment>
  );
};

export default UploadDocuments;

const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  inferenceAPIUrl: process.env.REACT_APP_INFERENCE_URL,
  chatAPIUrl: process.env.REACT_APP_CHAT_URL,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  endpoint: process.env.REACT_APP_AWS_ENDPOINT,
  // baseUrl: process.env.BASE_URL
  //   ? process.env.BASE_URL
  //   : "https://shark-app-3m9ls.ondigitalocean.app",
};

export default config;

import React, { Fragment, useEffect, useState } from "react";
import "./App.css";
import { MattersProvider } from "./context/MattersProvider";
import AppRoutes from "./routes/route";

const App = () => {
  const [open, setOnClose] = useState(true);

  /* const html = <LoginPage />;
  let element = useRoutes([
    { path: "/", element: <LoginPage /> },
    { path: "/signup", element: <Signup /> },
    { path: "/file", element: <ItemLayout /> },
  ]); */

  const title = process.env.REACT_APP_TITLE;
  useEffect(() => {
    document.title = title ? title : "RelevAInce AI";
  }, []);

  return (
    <Fragment>
      <MattersProvider>
        <AppRoutes />
      </MattersProvider>
    </Fragment>
  );
};

export default App;

import * as React from "react";
import { getFolders } from "../../../services/filingcabinet/filingcabinet.service";

import {
  TreeView,
  TreeViewCheckChangeEvent,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
  handleTreeViewCheckChange,
  processTreeViewItems,
} from "@progress/kendo-react-treeview";
import { useEffect, useState } from "react";
import Constants from "../../../services/utils/Constants";
import { formatData } from "../helpers/helperMethods";
import { DataModel } from "../interfaces/FileManagerModels";

interface TreeViewDataItem {
  id: number;
  text: string;
  expanded?: boolean;
  checked?: boolean;
  selected?: boolean;
  items?: TreeViewDataItem[];
}

// const tree: TreeViewDataItem[] = [
//   {
//     id: 1,
//     text: "Client Management",
//     checked: false,
//     items: [
//       { id: 2, text: "Intake", checked: false },
//       { id: 3, text: "Conflict", checked: false },
//       { id: 4, text: "Time Track", checked: false },
//       { id: 5, text: "Billing", checked: false },
//     ],
//   }
// ];

const FoldersTree = (props: { matter_id; onSelection }) => {
  const [selectedFolders, setSelectedFolders] = useState<any>([]);
  const [selectedFolderIds, setSelectedFolderIds] = useState<any>([]);
  const [folderData, setFolderData] = useState<DataModel[]>([]);
  const [check, setCheck] = useState<any>([]);
  const [expand, setExpand] = useState({
    ids: ["Item2"],
    idField: "text",
  });
  const [select, setSelect] = useState([""]);

  const findParentNodes = (
    tree: any,
    targetId: number,
    path: string[] = []
  ): string | null => {
    for (const node of tree) {
      const currentPath = [...path, node.text];

      if (node.id === targetId) {
        return currentPath.join("/");
      }

      if (node.items) {
        const result = findParentNodes(node.items, targetId, currentPath);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  const transformToTreeViewItem = (
    originalItem: DataModel
  ): TreeViewDataItem => {
    let name = originalItem.path ?? "";
    const parts = name?.split("/");

    name = parts?.pop() as string;

    return {
      id: originalItem.id ?? 0,
      text: name,
      expanded: false,
      checked: false,
      selected: false,
      items: originalItem?.items?.map(transformToTreeViewItem),
    };
  };

  useEffect(() => {
    const getFiles = async () => {
      try {
        const getFilesDataResponse: any = await getFolders(props.matter_id);

        if (getFilesDataResponse.status == Constants.HTTP_STATUS.SUCCESS) {
          const formattedData = formatData(getFilesDataResponse.data);
          const treeData: TreeViewDataItem[] = formattedData.map(
            transformToTreeViewItem
          );
          setFolderData(treeData);
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle the error here
      }
    };

    getFiles();
  }, []);

  // Function to add a folder to the selectedFolders array
  const addOrRemoveFolder = (folder) => {
    if (folder.checked) {
      deleteFolder(folder);
    } else {
      addFolder(folder);
    }
  };
  // Function to add a folder to the selectedFolders array
  const addFolder = (folder) => {
    setSelectedFolders((prevSelectedFolders) =>
      prevSelectedFolders ? [...prevSelectedFolders, folder] : [folder]
    );

    if (!selectedFolderIds.includes(folder.id)) {
      selectedFolderIds.push(folder.id);
    }
  };

  // Function to delete a folder from the selectedFolders array
  const deleteFolder = (folderId) => {
    if (Array.isArray(selectedFolders)) {
      const updatedFolders = selectedFolders.filter(
        (folder) => folder.id !== folderId
      );
      setSelectedFolders(updatedFolders);
    }

    selectedFolderIds.map((item: any, index) => {
      if (item === folderId.id) {
        selectedFolderIds.pop(index);
      }
    });

    selectedFolderIds.filter((item) => item !== folderId.id);
  };

  const onItemClick = (event: TreeViewItemClickEvent) => {
    setSelect([event.itemHierarchicalIndex]);
  };
  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    let ids = expand.ids.slice();
    const index = ids.indexOf(event.item.text);

    index === -1 ? ids.push(event.item.text) : ids.splice(index, 1);
    setExpand({ ids, idField: "text" });
  };
  const onCheckChange = (event: TreeViewCheckChangeEvent) => {
    const settings = {
      singleMode: false,
      checkChildren: false,
      checkParents: false,
    };
    setCheck(handleTreeViewCheckChange(event, check, folderData, settings));

    const parentNode = findParentNodes(folderData, event.item);

    addOrRemoveFolder(event.item);
    props.onSelection(selectedFolderIds, selectedFolders);
  };
  return (
    <TreeView
      data={processTreeViewItems(folderData, {
        select: select,
        check: check,
        expand: expand,
      })}
      expandIcons={true}
      onExpandChange={onExpandChange}
      aria-multiselectable={true}
      onItemClick={onItemClick}
      checkboxes={true}
      onCheckChange={onCheckChange}
    />
  );
};

export default FoldersTree;

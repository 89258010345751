import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

export type IToast = {
  title: string;
  message: string;
  openState: boolean;
  onCloseToast: any;
  messageType?: "bg-primary text-white" | "bg-danger text-white";
  messageBodyType?: "bg-primary text-black" | "bg-primary text-black";
};

const ToastMessage = (props: IToast) => {
  const [show, setShow] = useState(props.openState);
  //
  useEffect(() => {
    setShow(props.openState);
    //
  }, [props.openState]);

  const onClose = (closeState: boolean) => {
    setShow(closeState);
    if (props.onCloseToast()) props.onCloseToast();
  };

  return (
    <ToastContainer
      className="p-3"
      position={"top-center"}
      style={{ zIndex: 10, marginTop: "10vh" }}
    >
      <Toast onClose={() => onClose(false)} show={show} delay={4000} autohide>
        <Toast.Header className={props.messageType}>
          <strong className="me-auto">{props.title}</strong>
          {/* <small>11 mins ago</small> */}
        </Toast.Header>
        {/* <Toast.Body>{props.message}</Toast.Body> */}
        <Toast.Body className={props.messageBodyType}>
          {props.message}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastMessage;

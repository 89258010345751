import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { getReleaseNotes } from "../../services/releasenotes/releaseNotes.service";
import Constants from "../../services/utils/Constants";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BugReportIcon from "@mui/icons-material/BugReport";
import { CircularProgress, Box, IconButton, Typography } from "@mui/material";

const DisplayNotes = () => {
  const navigate = useNavigate();
  const [notes, setReleaseNotes] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getReleaseNote = async () => {
      const ReleaseNotesResponse = await getReleaseNotes();
      setLoading(true);
      if (ReleaseNotesResponse.status === Constants.HTTP_STATUS.SUCCESS) {
        const temp = [...ReleaseNotesResponse.data];
        //
        temp.sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        });

        setReleaseNotes([...temp]);
      }
      setLoading(false);
    };
    getReleaseNote();
  }, []); //

  const handleBackNavigate = () => {
    const _route = `/file`;
    navigate(_route);
  };

  return (
    <>
      {loading && (
        <Box position={"absolute"} top={"50%"} left={"50%"}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <div
          className="contained"
          style={{
            backgroundColor: "white",
            padding: "4%",
            borderRadius: "2px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          {/* {loading && (
            <div style={{justifyContent: "center" , alignItems: "center"}}>
              <CircularProgress />
            </div>)} */}

          <div style={{ marginBottom: "2%" }}>
            <IconButton aria-label="back" onClick={handleBackNavigate}>
              <ArrowBackIosIcon fontSize="small" />
              <Typography variant="body1">Back</Typography>
            </IconButton>
          </div>
          <Typography
            variant="h5"
            style={{
              marginBottom: "5%",
              fontFamily: "inherit",
              fontWeight: "400",
              fontSize: "34px",
              color: "#333",
            }}
          >
            RelevAInce Release v{notes[0]?.version}
          </Typography>

          {notes?.map((note, index) => (
            <div key={index} style={{ marginBottom: "3%" }}>
              <div
                style={{
                  marginBottom: "10px",
                  fontFamily: "inherit",
                  fontWeight: "500",
                  lineHeight: "1.1",
                  color: "inherit",
                  fontSize: "20px",
                }}
              >
                <span>
                  <span style={{ fontSize: "smaller" }}>{note.date}</span> (
                  {note.version}) {` ${note.title}`}
                </span>
              </div>

              <ul>
                {note.features.map((change, i) => (
                  <li
                    key={i}
                    style={{
                      marginBottom: "8px",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "30px",
                      color: "#666",
                      marginLeft: "2%",
                      marginTop: "1%",
                    }}
                  >
                    <Typography
                      gutterBottom
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#6b4fbb",
                        fontWeight: "400",
                        fontSize: "18px",
                      }}
                    >
                      {change.feature_type !== "Bug Fix" && (
                        <TaskAltIcon
                          fontSize="small"
                          color="success"
                          style={{ marginRight: "8px" }}
                        />
                      )}
                      {change.feature_type === "Bug Fix" && (
                        <BugReportIcon
                          fontSize="small"
                          color="success"
                          style={{ marginRight: "8px" }}
                        />
                      )}
                      {change.feature_type}: {change.feature}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DisplayNotes;
